import React, {useEffect, useState} from "react";
import {usersChunksWithPreviewsApi} from "../../api/ChatDataApi";
import {getEnvOfStorage} from "../../Stores/Env";
import {PROFILE} from "../../../assets";

const ProfilePreviews = () => {
    const [preview, setPreview] = useState(false);
    const [chunks, setChunks] = useState([]);

    useEffect(() => {
        usersChunksWithPreviewsApi(9)
            .then((res) => {
                setChunks(res?.data);
                if (res?.data?.length === 9) {
                    setPreview(true);
                }
            })
            .catch((err) => console.error(err))
    }, []);



    return (
        <>
            <div className="d-flex flex-column align-items-center">
                {preview &&
                <div className="p-3 d-flex preview-form flex-row align-items-center justify-content-between flex-wrap">
                    {chunks.map((item) => (
                        <div className="d-flex flex-column align-items-center w-33 my-2">
                            <div style={{
                                backgroundImage: `url(${item?.thumbUrl?.src ? getEnvOfStorage()?.staticUrl+item?.thumbUrl?.src : PROFILE})`,
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover"}}
                                 className="mx-1 preview-img-64 rounded-circle shadow bg-white d-flex border border-white">
                                {}
                            </div>
                            <span className="fs-6_ text_width_constraint_100 d-flex justify-content-center text-white">{`${item?.firstName}, ${item.age}`}</span>
                        </div>
                    ))}
                </div>}
            </div>
        </>
    );
}

export default ProfilePreviews;