import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {LinearProgress, Stack} from "@mui/material";
import {getChatMessages} from "../../api/ChatApi";
import MessageItem from "./MessageItem/MessageItem";
import EmptyMessageList from "./MessageItem/EmptyMessageList";
import {
    dropChatMessageList,
    selectChatMessages, setAllMessageList, setBeforeMessageList,
    setMessageList,
} from "../../Stores/slices/ChatMessagesSlice";
import {setAlertToast} from "../../Stores/slices/ToastSlice";
import {selectCommon, setEmojiMenu} from "../../Stores/slices/CommonSlice";
import { updateMessageReadStatus} from "../../services/DataService";
import useLongPress from "../../Hooks/useLongPress";
import {selectPaidOptions} from "../../Stores/slices/PaidStatusSlice";
import {getStoredValueByKey} from "../../Stores/Env";

let page_ = 0;
let messageList_ = [];
let selectedProfile = null;
let allMessages = [];
/**
 *
 * @param stomp
 * @param {string} currentUserId
 * @param {number} chatClientHeight
 * @returns {JSX.Element}
 * @constructor
 */
function MessageView({stomp, currentUserId, chatClientHeight}) {
  const [loading, setLoading] = useState(false);
  const {chatSelectedUser} = useSelector(selectCommon);
  const {messageList, allMessageList, messageListBefore} = useSelector(selectChatMessages);
  const scrollChat = useRef(null);
  const chatBottomScroller = useRef(null);
  const dispatch = useDispatch();
  const defaultOptions = {
        shouldPreventDefault: true,
        delay: 100,
  };
  const longPressEvent = useLongPress(onLongPress, () => {}, defaultOptions);
  const {payTags} = useSelector(selectPaidOptions);
  //Актуализировать список вмех сообщений при каждм рендере
  allMessages = allMessageList;

    function onLongPress() {
        updateMessageReadStatusEx();
    }

    useEffect(() => {
        selectedProfile = chatSelectedUser;
        page_ = 0;
        loadMore(true);
    }, [chatSelectedUser]);

    useEffect(() => {
        if (messageList?.length > 5) {
            scrollToBottom();
        }
        messageList_ = messageList;
        dispatch(setAllMessageList());
    }, [messageList]);


    useEffect(() => {
        if (messageListBefore?.length > 20) {
            scrollChat?.current?.scrollTo(0, 20);
        }
    }, [messageListBefore])

    function scrollUp() {
        if ((scrollChat?.current?.scrollTop === 0) && (messageList_?.length > 8)){
            page_++;
            loadMore();
        }
    }

    useEffect(() => {
       //При достижении прокрутки чата до верхней границы контейнера
       //происходит дозагрузка прошлых сообщений
       scrollChat?.current.addEventListener("scroll", scrollUp);

       return () => {
           scrollChat?.current?.removeEventListener("scroll", scrollUp);
       }
    }, []);


    const scrollToBottom = () => {
        scrollChat?.current?.scrollTo(0,scrollChat?.current.scrollHeight);
    }


    function updateMessageReadStatusEx() {
        setTimeout(() => {
            updateMessageReadStatus(currentUserId, selectedProfile?.id, allMessages, stomp);
        }, 300);
    }

    /**
     * Запросить переписку постранично
     * @param {boolean} isDropPage
     */
    function loadMore(isDropPage = false) {
            setLoading(true);
            getChatMessages(page_, selectedProfile?.id, currentUserId, (res, err) => {
                if (!err) {
                    beforeData(res?.data);
                    defaultData(res?.data);
                    updateMessageReadStatusEx();
                } else {
                    console.error(err);
                }
                setLoading(false);
            });
    }

   /**Загружает сообщения, которые были написанны ранее при прокрутке чата вверх*/
   function beforeData(response) {
       if (response?.page > 0) {
           if (response?.data?.length > 0) {
               dispatch(setBeforeMessageList(response?.data));
           }
       }
   }
   /**
    * Получает первую страницу сообщений переписки
    * */
    function defaultData(response) {
        if (response?.page === 0) {
            //Предочистка сиска сообщений перед переключением между пользователями
            dispatch(dropChatMessageList());
            page_ = 0;
            //Обновить список сообщений для выбранного пользователя
            dispatch(setMessageList(response?.data));
        }
    }

    function stompError(err) {
        dispatch(setAlertToast({open: true, text: err, severity: 'warning'}));
    }

    function onDeleteMessage(data) {
        if (!payTags?.deleteLastMessage) return;
        if (stomp) {
            stomp.deleteChatMessages([data], currentUserId, selectedProfile?.id, stompError);
        }
    }

  return (
    <div ref={scrollChat}
         className="message-view-panel p-1" {...longPressEvent}
         onClick={() => dispatch(setEmojiMenu(false))}
         onTouchEnd={() => dispatch(setEmojiMenu(false))}>

        {loading ?
        <Stack sx={{ width: '100%', color: 'grey.500'}} spacing={2}>
            <LinearProgress color="success" />
        </Stack> : <div className="w-100" style={{height: 4}}>{}</div>}

        <div className="chatView d-flex flex-column">
            {messageList?.length > 0 && messageList.map((element) => (
            <div key={element?.id}>
                <MessageItem onDeleteMessage={onDeleteMessage} index={element?.id} selectedUser={selectedProfile} data={element} currentUserId={currentUserId}/>
            </div>))}

            {((messageList?.length === 0) || (!messageList)) && <EmptyMessageList/>}
            <div ref={chatBottomScroller}/>
        </div>
    </div>

  );
};


export default MessageView;
