import React, {useEffect, useState} from "react";
import {LABEL_ADS, SUBTITLE_COMPLAINS_ON} from "../../Constants/TextMessagesRu";
import {PersonSvg} from "../../Svg";
import {getEnvOfStorage} from "../../Stores/Env";
import AvatarItem from "../Avatars/AvatarItem";
import {ListItemButton} from "@mui/material";
import EnvelopeSvg from "../../Svg/EnvelopeSvg";
import EnvelopeOpenSvg from "../../Svg/Sunstrate/EnvelopeOpenSvg";
import SadEmojiSvg from "../../Svg/SadEmojiSvg";
import {readComplains} from "../../api/ChatDataApi";
import {useDispatch} from "react-redux";
import {setComplains} from "../../Stores/slices/ComplainsSlice";
import {appendYaAds} from "../../services/Yandex";

const ComplainsContent = ({complains, userId}) => {
    const [complain, setComplain] = useState('');
    const dispatcher = useDispatch();

    function getThumbUrl(profile) {
        return  (profile?.thumbUrl?.src?.length > 0) ? `${getEnvOfStorage()?.staticUrl}${profile?.thumbUrl?.src}` : '';
    }

    function getUserName(profile) {
        return `${profile?.firstName} ${profile?.lastName}, ${profile?.age}`
    }

    function selectedComplain(data) {
        setComplain(data);
    }

    useEffect(() => {
        appendYaAds("yandex_rtb_R-A-2450444-9", "R-A-2450444-9")
    }, []);

    useEffect(() => {
        if (complain) {
            readComplains({ids: [complain?.id]}, userId).then(res => {
                if (res?.data?.length > 0) {
                    let upComplains = JSON.parse(JSON.stringify(complains));
                    upComplains?.map(item => {
                        if (item?.id === res?.data[0]) {
                            item.addresseeRead = true;
                        }
                        return item;
                    });
                    dispatcher(setComplains(upComplains));
                }
            })
        }
    }, [complain]);

    return (
        <div className="d-block h-100 my-2 px-sm-1 px-md-2 px-lg-3 py-2 scroll-hidden-custom position-relative">
            <span className="d-flex mb-3 h3 align-items-center" >
                <SadEmojiSvg color={"#f00"}/>
                <span className="mx-2 fs-3_ mt-1">{SUBTITLE_COMPLAINS_ON}</span>
            </span>
            <div className="border border-danger p-2 rounded fs-4_">
                <p className="text-danger">Будьте вежливы друг с другом и не нарушайте правила сервиса! Иначе ваш аккаунт могут заблокировать на 3-и недели или навсегда.</p>
            </div>
            <div className="d-flex flex-column">
                <div className="d-flex align-items-center border-bottom border-1 border-dark">
                    <div className="d-flex justify-content-center mx-3 fs-6_ fw-bold p-1">{<PersonSvg />}</div>
                    <div className="d-flex justify-content-center mx-1 fs-6_ fw-bold p-1 fio-field text-uppercase">Пользователь</div>
                    <div className="d-none d-md-flex justify-content-center flex-sm-grow-1 fs-6_ fw-bold p-1 complain-body-field text-uppercase">Текст жалобы</div>
                    <div className="d-flex justify-content-center mx-2 fs-6_ fw-bold p-1 date-field text-uppercase">Дата</div>
                    <div className="d-flex justify-content-center mx-2 status-field fs-6_ fw-bold text-uppercase">Статус</div>
                </div>
                {complains?.length > 0 ?
                    complains?.map((item, index) => (
                        <ListItemButton key={item?.id}
                                        onClick={() => selectedComplain(item)}
                                        className="d-flex align-items-center border-bottom bg-white p-0 my-profile-shadow">
                                <div className="d-flex justify-content-center mx-2 fs-6_ p-1">{ <AvatarItem src={getThumbUrl(item?.fromProfile)} alt={item?.fromProfile?.firstName}/>}</div>
                                <div className="d-flex justify-content-center mx-1 fs-6_ p-1 fio-field fw-bold text-primary">{getUserName(item?.fromProfile)}</div>
                                <div className="d-none d-md-flex flex-sm-grow-1 mx-2 fs-6_ p-1 text-secondary_r fw-bold crop complain-body-field">{item?.messageBody}</div>
                                <div className="d-flex justify-content-center mx-2 fs-6_ p-1 date-field fw-bold">{item?.timestamp}</div>
                                <div className="d-flex justify-content-center mx-2 status-field fw-bold">{!item?.addresseeRead ? <EnvelopeSvg w={22} h={22} color={"red"}/> : <EnvelopeOpenSvg w={22} h={22} color={"green"}/>}</div>
                        </ListItemButton>
                    )) :
                    <div className="d-flex align-items-center justify-content-center border-bottom bg-white my-profile-shadow">
                        <div className="d-flex flex-column align-items-center p-1 text-center">{"На вас жалоб нет"}</div>
                    </div>
                }
                <div>
                    {complain &&
                    <span className="d-flex flex-column border card p-2 my-2 my-profile-shadow">
                        <h4 className="border-bottom">Состав жалобы</h4>
                        <p className="text-dark fs-5_">{complain?.messageBody}</p>
                    </span>}
                </div>
            </div>
            {<div className="d-flex align-content-center justify-content-center mt-4">
                <div className="ads-320-90" id={`yandex_rtb_R-A-2450444-${9}`}>{LABEL_ADS}</div>
            </div>}
        </div>
    );
}

export default ComplainsContent;