import React, {useState} from 'react';
import SelectSexForm from './SelectSexForm';
import {AlertToastFilled} from "../../../Components/Toasts/AlertToastFilled";
import {dropAlertToast, selectToast} from "../../../Stores/slices/ToastSlice";
import {useDispatch, useSelector} from "react-redux";
import {selectProfile} from "../../../Stores/slices/UserProfileSlices";
import AboutUserForm from "./AboutUserForm";
import ComeUpPasswordForm from "./ComeUpPasswordForm";
import {Helmet} from "react-helmet";
import {
    META_DESCRIPTIONS_REG,
    META_KEYWORDS_REG,
    META_TITLE_REG,
} from "../../../Constants/TextMessagesRu";


/**
 * Выбрать пол. 1-й этап регистрации
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function RegisterProfileSteps() {
    const {alert} = useSelector(selectToast);
    const dispatcher = useDispatch();
    const {regPageIndex} = useSelector(selectProfile);

    function onAlertClosed() {
        dispatcher(dropAlertToast());
    }

    function formByStep(pageIndex) {
        switch (pageIndex) {
            case 1:
                return <SelectSexForm/>;
            case 2:
                return <AboutUserForm />
            case 3:
                return <ComeUpPasswordForm />
            default:
                return <SelectSexForm/>
        }
    }

    return (
        <div className="sign-background">
            <Helmet>
                <title>{META_TITLE_REG}</title>
                <meta name="description" content={META_DESCRIPTIONS_REG} />
                <meta name="keywords" content={META_KEYWORDS_REG} />
            </Helmet>
            <div className=" d-flex flex-row align-items-center justify-content-center from-panel flex-wrap">
                <div className="">
                    {formByStep(regPageIndex)}

                    <AlertToastFilled open={alert.open} text={alert.text} severity={"warning"} onClose={onAlertClosed}/>
                </div>
                <div className="p-3 mx-3 d-flex flex-column description-form d-none d-md-flex bg-opacity-75 rounded bg-dark border border-white">
                    <h1 className="text-center text-white fs-2_">Чат знакомств <span className="text-secondary_">Strelka</span>. Регистрация</h1>
                    <p className="text-justify fs-5_ text-white">Регистрация в чате знакомств свободная и бесплатная для мужчин и женщин. Вам лишь нужно указать свое имя, город, год рождения, email и пароль.
                    Все остальные параметры о себе вы заполните на своей странице в разделе "Мой профиль".</p>
                </div>
            </div>

        </div>
   );
}

export default RegisterProfileSteps;
