import {Grid, ListItem} from "@mui/material";
import React, {useEffect} from "react";
import ProfileViewElement from "../Profile/ProfileViewElement";

const GridItem = ({dataMap, columns, row, style, index}) => {
    const cols = Array(columns).fill(1).map((x, y) => y);
    let colXS = 12/columns;

    return (
        <ListItem style={{...style, padding: 3}} key={index} component="div">
            <Grid container spacing={0} alignItems="center">
                {cols.map((col, i) =>
                    {
                        if (!dataMap.get(row)[col]?.value) {
                            return;
                        }

                        return (
                            <Grid key={i} item xs={colXS} className="">
                               <ProfileViewElement profile={dataMap.get(row)[col]?.value} />
                            </Grid>
                        );
                    }
                )}
            </Grid>
        </ListItem>
    );
}

export default React.memo(GridItem);