import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import commonSlice from "./slices/CommonSlice";
import userSlice from "./slices/UserSlice";
import profileSlice from "./slices/UserProfileSlices";
import searchBoxSlice from "./slices/SearchBoxSlice";
import loadFilesSlice from "./slices/LoadFilesSlice";
import guestSlice from "./slices/GuestSlice";
import searchProfilesSlice from "./slices/SearchProfilesSlice";
import chatListSlice from "./slices/ChatListSlice";
import toastSlice from "./slices/ToastSlice";
import chatMessagesSlice from "./slices/ChatMessagesSlice";
import paidStatusSlice from "./slices/PaidStatusSlice";
import complainsSlice from "./slices/ComplainsSlice";
import userAdsSlice from "./slices/UserAdSlice";


export default configureStore({
    reducer: {
        common: commonSlice,
        user: userSlice,
        profile: profileSlice,
        searchBox: searchBoxSlice,
        alertSnack: toastSlice,
        chatMsgStatus: chatMessagesSlice,
        filesChanged: loadFilesSlice,
        guest: guestSlice,
        searchProfiles: searchProfilesSlice,
        usersChats: chatListSlice,
        paidOptions: paidStatusSlice,
        complains: complainsSlice,
        userAds: userAdsSlice,
    },
    middleware: getDefaultMiddleware({serializableCheck: false}),
});