import {Box, IconButton, Modal} from "@mui/material";
import XSvg from "../../Svg/XSvg";
import React from "react";
import {CAT} from "../../../assets";
import SocialLinks from "../SocialLinks";
import {SHARE_MESSAGE} from "../../Constants/TextMessagesRu";

const ShareModal = ({open, handleClose}) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 700,
        minWidth: 220,
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 2,
        borderRadius: 2,
        p: 1
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description">
                <Box sx={{ ...style }} className="position-relative">
                    <div className="d-flex flex-row flex-grow-1 p-0 justify-content-end align-items-center">
                        <IconButton onClick={handleClose}>
                            <XSvg color={'#F00'}/>
                        </IconButton>
                    </div>
                    <div className="d-flex align-items-center w-100 position-relative">
                        <img src={CAT} alt="В ожидании посетителей" className="share-cat"/>
                    </div>
                    <span className="fs-5_ text-danger text-center fw-bold">
                        <p>{SHARE_MESSAGE}</p>
                    </span>
                    <div className="d-flex justify-content-center align-items-center">
                         <SocialLinks />
                    </div>
                </Box>
            </Modal>
        </>
    );
}

export default ShareModal;