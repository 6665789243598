import React from "react";
import {Skeleton} from "@mui/material";

const UserChatSkeleton = () => {
    return (
        <>
            <div className="d-flex align-items-center my-1 p-1">
                <Skeleton variant={"circular"} height={50} width={50}/>
                <div className="d-flex flex-column align-content-center">
                    <Skeleton variant={"rectangular"} height={10} width={200} className="my-1 mx-2"/>
                    <Skeleton variant={"rectangular"} height={10} width={100} className="my-1 mx-2"/>
                </div>
            </div>
        </>
    );
}

export default UserChatSkeleton;