import {createSlice} from "@reduxjs/toolkit";

export const userAdsSlice = createSlice({
    name: 'userAds',
    initialState: {
        page: 0,
        counter: 0,
        ads: {
           ads: [],
           total: 0,
           pageSize: 20,
        },
        isEmptyData: false,
    },
    reducers: {
        setUserAds: (state, action) => {
            state.ads = {...state, ...action.payload};
        },
        fullDropAds: (state) => {
            state.ads.ads = [];
            state.page = 0;
            state.isEmptyData = true;
        },
        setPageAds: (state, action) => {
            state.page = action.payload;
        },
        setCounter: (state) => {
            state.counter +=1;
        },
    },
});

export const selectUserAds = (state) => state.userAds;
export const {setUserAds, fullDropAds, setPageAds, setCounter} = userAdsSlice.actions;
export default userAdsSlice.reducer;