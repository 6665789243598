import React, {useEffect, useState} from "react";
import {Button} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import {
    CAPTION_REGISTRATION,
    CAPTION_SIGN,
    MSG_USER_ALREADY_EXISTS,
    SOMETHING_WENT_WRONG,
} from "../../../Constants/TextMessagesRu";

import Loader from "../../../Components/Loader/Loader";
import {networkErrStatus, USER_ID_KEY} from "../../../api/ApiCommon";
import {ROUTE_SIGNUP} from "../../../Constants/Routes";
import RoundSubstrate from "../../../Svg/Sunstrate/RoundSubstrate";
import { fullRegistrationV2} from "../../../api/AuthApi";
import {useDispatch, useSelector} from "react-redux";
import {setAlertToast} from "../../../Stores/slices/ToastSlice";
import Credentials from "../../../Components/Forms/Credentials";
import {selectProfile, setRegProfile} from "../../../Stores/slices/UserProfileSlices";
import {IMG_LOGO} from "../../../../assets";
import PersonVCardSvg from "../../../Svg/PersonVCard";
import {getEnvOfStorage} from "../../../Stores/Env";
import ConfirmCodeByEmail from "../../../Components/Recaptchas/СonfirmCodeByEmail";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function ComeUpPasswordForm() {
    const [credentials, setCredentials] = useState({login: '', password: '', valid: false});
    const [isValidCaptcha, setValidCaptcha] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const {regProfile} = useSelector(selectProfile);
    const [registrationResponse, setRegistrationResponse] = useState({
        response: {},
        status: 500,
        loading: false,
    })
    const navigate = useNavigate();
    const dispatch = useDispatch();

    function onRightCaptcha(flag) {
        setValidCaptcha(flag);
    }

    function onCredentials(data) {
        setCredentials(prevState => ({
            ...prevState,
            password: data?.password?.content,
            login: data?.login?.content,
            valid: (data?.login?.valid && data?.password?.valid && data?.confirm.valid),
        }));
        dispatch(setRegProfile({email: data?.login?.content}));
    }

    useEffect(() => {
        setDisabled(!credentials.valid || !isValidCaptcha);
    }, [credentials, isValidCaptcha])

    const handleRegistration = (response) => {
        if (response?.id?.toString().length > 0) {
            localStorage.setItem(USER_ID_KEY, response?.id);
            navigate(`/?userId=${response?.id}`);
        }
    }

    if (registrationResponse.loading)
        return (
            <div className="p-2 d-flex justify-content-center ">
                <div className="d-flex flex-column preloader-panel">
                    <Loader/>
                </div>
            </div>
        );

    const onRegisterUser = () => {
        //Зарегистрировать пользователя
        setRegistrationResponse(prevState => ({...prevState, loading: true}));

        fullRegistrationV2(
            {username: credentials.login, password: credentials.password}, regProfile,
            (res, err) => {
               if (!err) {
                   setRegistrationResponse({
                       response: res,
                       status: 200,
                       loading: false,
                   });
                   handleRegistration(res);
               } else {
                   setRegistrationResponse(prevState => ({...prevState, loading: false}));
                   setRegistrationResponse(prevState => ({...prevState, status: networkErrStatus(err)}));
                   if (networkErrStatus(err) === 409) {
                       dispatch(setAlertToast({open: true, severity: 'warning', text: MSG_USER_ALREADY_EXISTS+credentials.login}));
                       return;
                   }
                   dispatch(setAlertToast({open: true, severity: 'warning', text: SOMETHING_WENT_WRONG}));
               }
        });
    }

    return(
        <div className="p-3 d-flex justify-content-center align-content-center">
            <div className="d-flex flex-column primary-form-rel">
                <a rel={"nofollow"} href={getEnvOfStorage()?.homeSiteUrl} className="w-100 d-flex justify-content-center"><img src={IMG_LOGO} style={{height: 50}}/></a>
                <div className="d-flex flex-row align-items-center justify-content-center">
                    <RoundSubstrate color="orange" children={<PersonVCardSvg/>} />
                    <h4 className="mt-2 mx-1 fs-3_">{"Почти готово"}</h4>
                </div>



                <Credentials onCredentials={onCredentials} confirmHidden={false}/>
                {credentials?.valid && <ConfirmCodeByEmail onEqual={onRightCaptcha} email={credentials?.login}/>}

                <Button disabled={disabled} onClick={onRegisterUser} className="mt-3" variant={'contained'}>{CAPTION_REGISTRATION}</Button>

                <div className="d-flex justify-content-center align-items-center mt-2">
                    <Link to={ROUTE_SIGNUP}>
                        <span className="d-inline-block mt-1 link fs-5_">
                            {CAPTION_SIGN.toUpperCase()}
                        </span>
                    </Link>
                </div>
            </div>

        </div>

    );
}