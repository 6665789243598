import {PASS_PHRASE} from "../../Constants/TextMessagesRu";

export function encrypt(input, key) {
    if (!input || !key) return '';

    let c = '';
    while (key?.length < input?.length) {
        key += key;
    }
    for(let i=0; i<input?.length; i++) {
        let value1 = input[i].charCodeAt(0);
        let value2 = key[i].charCodeAt(0);

        let xorValue = value1 ^ value2;

        let xorValueAsHexString = xorValue.toString("16");

        if (xorValueAsHexString.length < 2) {
            xorValueAsHexString = "0" + xorValueAsHexString;
        }

        c += xorValueAsHexString;
    }
    return c;
}

