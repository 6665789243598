import React from 'react';
import MessageItemElement from '../MessageItemElement/MessageItemElement';
import {IconButton} from "@mui/material";
import {MSG_YOU} from "../../../Constants/TextMessagesRu";
import {MessageStatus, MessageStatusRound} from "../../Common";
import {useSelector} from "react-redux";
import {selectPaidOptions} from "../../../Stores/slices/PaidStatusSlice";
import TrashSvg from "../../../Svg/TrashSvg";


function MessageItem({data, currentUserId, selectedUser, onDeleteMessage, index}) {
    const {payTags} = useSelector(selectPaidOptions);

    const showTimeIfEqualsDates = (timestamp) => {
        if (!timestamp) return "";
        let timeLabel = new Date(timestamp);
        return timeLabel.getDate() === new Date().getDate() ? timeLabel.toLocaleTimeString() : timeLabel.toLocaleString();
    }

    function chatBudge(data, curUserId) {
        if (data?.userId === curUserId) {
            return (
              <div className="p-1 mt-2 d-flex">
                  <MessageItemElement data={data}>
                      <div className="d-flex flex-row justify-content-between align-content-center flex-wrap">
                          <span className="fs-5_" style={{color: '#01923b'}}>{`${selectedUser?.firstName}`}</span>
                          <div className="d-flex flex-row align-items-center justify-content-end p-0">
                              <MessageStatusRound isRead={data?.read}/>
                          </div>
                      </div>
                      <div className="d-block text-wrap-div w-100 fs-5_">{data?.message}</div>
                  </MessageItemElement>
              </div>);
        } else {
            return (
              <div className="p-1 mt-2 d-flex flex-row-reverse align-items-center" >
                  <MessageItemElement color={"#C2F78E"} data={data}>
                      <div className="d-flex flex-row justify-content-between align-content-center flex-wrap">
                        <span className="fs-5_" style={{color: '#f55f0d'}}>{MSG_YOU}</span>
                        <div className="d-flex flex-row align-items-center justify-content-end p-0">
                            <MessageStatus isRead={data?.read}/>
                        </div>
                      </div>
                      <div className="d-block text-wrap-div w-100 p-1 fs-5_">{data?.message}</div>
                  </MessageItemElement>
                  {((payTags?.deleteLastMessage) && (!data?.read)) &&
                  <IconButton onClick={() => onDeleteMessage(data)} aria-label="delete">
                      <TrashSvg color={"#FF0000"} w={20} h={20}/>
                  </IconButton>}
              </div>
            );
        }
    }

    return (
        <div className="p-0 m-0">{chatBudge(data, currentUserId, index)}</div>
    );
}


export default MessageItem;
