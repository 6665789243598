import {getHeaderBearerConfigs, TOKEN_KEY} from "./ApiCommon";
import axios from "axios";
import {getEnvOfStorage} from "../Stores/Env";

/**
 * Вопрос или жалоба техподдержке
 * @param body
 * @returns {Promise<AxiosResponse<any>>}
 */
export function createSupportMsg(body) {
    let token = localStorage.getItem(TOKEN_KEY);
    return axios.post(`${getEnvOfStorage()?.stompUrl}/api/support`, body, getHeaderBearerConfigs("application/json", token));
}