import {
    Badge, Checkbox,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import AvatarItem from "../Avatars/AvatarItem";
import {getEnvOfStorage} from "../../Stores/Env";
import {LABEL_WAS_MAN, LABEL_WAS_WOMAN} from "../../Constants/TextMessagesRu";
import {
    dropSelectedChats,
    selectUsersChats, setRemoveChatsEnabled,
    setSelectedUserChats
} from "../../Stores/slices/ChatListSlice";
import ChatLeftTextSvg from "../../Svg/ChatLeftTextSvg";


/**
 *
 * @param {Object} itemData
 * @param onSelectItem
 * @param {number} index
 * @returns {JSX.Element}
 * @constructor
 */
function ChatProfileItem({itemData, onSelectItem, index}) {
    const dispatch = useDispatch();
    const {chatSelect} = useSelector(selectUsersChats)
    let imgSrc = (itemData?.userProfile?.thumbUrl?.src?.length > 0) ? `${getEnvOfStorage()?.staticUrl}${itemData?.userProfile?.thumbUrl?.src}` : '';

    const unReadMessagesCount = itemData?.chatItems?.length > 0 ? itemData?.chatItems.map(item => (!item?.read)).length : 0;


    useEffect(() => {
       if (!chatSelect) {
           dispatch(dropSelectedChats());
       }
    }, [chatSelect]);

    function getLeftChatTime() {
        let leftDate = itemData?.userProfile?.leftChatTime;
        return itemData?.userProfile?.sex === 'MAN' ? `${LABEL_WAS_MAN} ${leftDate}` : `${LABEL_WAS_WOMAN} ${leftDate}`;
    }

    function lastMessage() {
        if (itemData?.chatItems?.length === 0) return getLeftChatTime();
        return itemData?.chatItems[itemData?.chatItems?.length-1]?.message ? itemData?.chatItems[itemData?.chatItems?.length-1]?.message : getLeftChatTime();
    }


    const onSelectedItem = (profile) => {
        onSelectItem(profile);
    }

    function onChecked(e, userItem) {
        dispatch(setSelectedUserChats({checked: e?.target?.checked, userId: userItem?.userProfile?.id}));
    }

    return (
        <div className="d-flex flex-row align-items-center">
            {chatSelect && <Checkbox className="p-0 mx-1" onChange={(e) => onChecked(e, itemData)}/>}
            <ListItem onClick={() => onSelectedItem({itemData})} disablePadding>
                <ListItemButton style={{padding: 0, margin: 0}}>
                    <ListItemAvatar className="d-flex justify-content-center">
                        <div className="p-0 mx-1 position-relative">
                            <AvatarItem src={imgSrc} alt={itemData?.userProfile?.firstName} w={50} h={50}/>
                                {itemData?.userProfile?.online &&  <div className="rounded-circle badge-online card">{}</div>}
                        </div>
                    </ListItemAvatar>
                    <ListItemText className="text-constraint d-flex flex-column fs-5_"
                                  style={{padding: 0, width: 45}}
                                  primary={`${itemData?.userProfile?.firstName} ${itemData?.userProfile?.lastName}`}
                                  secondary={
                                      <React.Fragment>
                                         <span className="fs-5_ text-primary">{lastMessage()}</span>
                                      </React.Fragment>
                                  } />
                    <ListItemIcon className="d-flex justify-content-center align-items-center">
                        <IconButton color={'primary'} className="position-relative p-1">
                            <ChatLeftTextSvg color={'#01923b'} w={20} h={20}/>
                            <span
                                className="position-absolute top-25 start-100 translate-middle badge rounded-pill fs-6_ bg-danger">
                                {unReadMessagesCount ? unReadMessagesCount : ''}
                            </span>
                        </IconButton>
                    </ListItemIcon>
                </ListItemButton>
            </ListItem>
        </div>
    );
}





export default ChatProfileItem;