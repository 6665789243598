import {Avatar, AvatarGroup, Tooltip} from "@mui/material";
import {useSelector} from "react-redux";
import {selectPaidOptions} from "../../Stores/slices/PaidStatusSlice";
import {invoiceStatuses} from "../../api/Payments";
import {LABEL_ACTIVE, LABEL_NOT_ACTIVE} from "../../Constants/TextMessagesRu";
import {useNavigate} from "react-router-dom";
import {ROUTE_OPTIONS} from "../../Constants/Routes";

const PaymentTagAvatars = () => {
    const {options} = useSelector(selectPaidOptions);
    const navigate = useNavigate();
    const activeStyleName = 'active-option';

    function isDisabled(option) {
        if (option?.payStatus !== invoiceStatuses.paid) {
            return '';
        }
        return activeStyleName;
    }

    if (options?.length === 0) {
        return (
            <></>
        );
    }

    return (
        <AvatarGroup max={3} className="mx-2 my-1">
           {options?.map((option, index) =>
               <Tooltip title={`${option?.caption} (${isDisabled(option) === activeStyleName ? LABEL_ACTIVE : LABEL_NOT_ACTIVE})`} key={index}>
                   <Avatar sx={{ width: 25, height: 25 }}
                           id={isDisabled(option)}
                           alt={option?.caption}
                           src={option?.icon}
                           onClick={() => navigate(ROUTE_OPTIONS)}
                           className="pat-tag-avatars"/>
               </Tooltip>)}
        </AvatarGroup>
    );
}

export default PaymentTagAvatars;