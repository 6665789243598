import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "@mui/material";
import {
     CAPTION_NEXT, CAPTION_SIGN, MEET_PREFERENCES_DATA,
    SUBTITLE_BRIEFLY_ABOUT_YOURSELF, SUBTITLE_MEETING_FOR, SUBTITLE_YOUR_BIRTHDAY,
    SUBTITLE_YOUR_NAME
} from "../../../Constants/TextMessagesRu";
import RoundSubstrate from "../../../Svg/Sunstrate/RoundSubstrate";
import {selectProfile, setRegPageIndex, setRegProfile} from "../../../Stores/slices/UserProfileSlices";
import {Link} from "react-router-dom";
import {ROUTE_SIGNUP} from "../../../Constants/Routes";
import {getAge} from "../../../services/DateTimeService";
import AutocompleteCityList from "../../../Components/Autocompletes/AutocompleteCityList";
import {IMG_LOGO} from "../../../../assets";
import PersonVCardSvg from "../../../Svg/PersonVCard";
import {getEnvOfStorage} from "../../../Stores/Env";

const AboutUserForm = () => {
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(true);
    const [wrong, setWrong] = useState({
        wCity: false, wMeetPrefs: false, wBirthdate: false, wFirsName: false
    });
    const {regProfile} = useSelector(selectProfile);


    useEffect(() => {
        setDisabled((
            !regProfile?.firstName ||
            !regProfile?.birthDate ||
            !regProfile?.meetPreferences ||
            !regProfile?.country ||
            !regProfile?.region ||
            !regProfile?.locality
        ));
        setWrong(prevState => ({...prevState,
            wBirthdate: !regProfile?.birthDate,
            wCity: (!regProfile?.country ||
                !regProfile?.region ||
                !regProfile?.locality),
            wFirsName: !regProfile?.firstName,
            wMeetPrefs: !regProfile?.meetPreferences
        }))
    }, [regProfile]);

    function onNext() {
        if (!disabled) {
            dispatch(setRegPageIndex(3));
        }
    }

    function onLocationSelected(value) {
        dispatch(setRegProfile({locality: value?.locality, region: value?.region, country: value?.country}));
    }

    return (
        <div className="container p-3 d-flex justify-content-center">


                <div className="d-flex flex-column primary-form-rel">
                    <a rel={"nofollow"} href={getEnvOfStorage()?.homeSiteUrl} className="w-100 d-flex justify-content-center"><img src={IMG_LOGO} style={{height: 50}}/></a>
                    <div className="d-flex flex-row align-items-center justify-content-center">
                        <RoundSubstrate color="orange" children={<PersonVCardSvg />} />
                        <h4 className="mt-2 mx-1 fs-3_">{SUBTITLE_BRIEFLY_ABOUT_YOURSELF}</h4>
                    </div>

                    <div className="d-flex justify-content-center fs-5_">
                        <AutocompleteCityList onLocationSelected={onLocationSelected}
                                              width={260}
                                              defaultLocation={{
                                                  locality: regProfile?.locality || "",
                                                  region: regProfile?.region || "",
                                                  country: regProfile?.country || ""
                                              }}/>
                    </div>

                    <label style={{color: wrong.wFirsName ? "#ff0000" : ''}} className="input-label mt-1 fs-5_">{SUBTITLE_YOUR_NAME}</label>
                    <input className="input_field fs-5_"
                           placeholder={SUBTITLE_YOUR_NAME}
                           type="text" required
                           onChange={(e) => {
                               dispatch(setRegProfile({firstName: e.target.value}));
                           }}
                    />

                    <label style={{color: wrong.wBirthdate ? "#ff0000" : ''}} className="input-label mt-1 fs-5_">{SUBTITLE_YOUR_BIRTHDAY}</label>
                    <input className="input_field fs-5_"
                           placeholder={SUBTITLE_YOUR_BIRTHDAY}
                           defaultValue="1987-05-22"
                           type="date"
                           required
                           onChange={(e) => {
                               dispatch(setRegProfile({birthDate: e.target.value, age: getAge(e.target.value)}));
                           }}
                    />

                    <label style={{color: wrong.wMeetPrefs ? "#ff0000" : ''}} className="input-label mt-1 fs-5_">{SUBTITLE_MEETING_FOR}</label>
                    <select
                        className="input_field fs-5_"
                        onChange={(e) => {
                            dispatch(setRegProfile({meetPreferences: e.target.value}));
                        }}>
                        {MEET_PREFERENCES_DATA.map((item, index) => (
                            <option key={index} value={item?.tag}>{item?.value}</option>
                        ))}
                    </select>

                    <Button disabled={disabled} onClick={onNext} className="mt-3" variant={'contained'}>{CAPTION_NEXT}</Button>

                    <div className="d-flex justify-content-center align-items-center mt-2">
                        <Link to={ROUTE_SIGNUP}>
                       <span className="d-inline-block mt-1 link fs-5_">
                         {CAPTION_SIGN.toUpperCase()}
                       </span>
                        </Link>
                    </div>
                </div>
        </div>
    );
}


export default AboutUserForm;