import {
    EmailIcon,
    EmailShareButton,
    TelegramIcon,
    TelegramShareButton, ViberIcon,
    ViberShareButton,
    VKIcon,
    VKShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import {SEO_SITE_URL, SEO_URL_DESCRIPTION} from "../Constants/TextMessagesRu";

const SocialLinks = () => {
    const iconSize = 32;
    return (
        <div className="d-flex align-items-center p-0">
            <div className="mx-1">
                <WhatsappShareButton
                    url={SEO_SITE_URL}
                    title={SEO_URL_DESCRIPTION}>
                    <WhatsappIcon size={32} round/>
                </WhatsappShareButton>
            </div>
            <div className="mx-1">
                <TelegramShareButton
                    url={SEO_SITE_URL}
                    title={SEO_URL_DESCRIPTION}>
                    <TelegramIcon size={iconSize} round/>
                </TelegramShareButton>
            </div>
            <div className="mx-1">
                <VKShareButton
                    url={SEO_SITE_URL}
                    title={SEO_URL_DESCRIPTION}>
                    <VKIcon size={iconSize} round/>
                </VKShareButton>
            </div>
            <div className="mx-1">
                <ViberShareButton
                    url={SEO_SITE_URL}
                    title={SEO_URL_DESCRIPTION}>
                    <ViberIcon size={iconSize} round/>
                </ViberShareButton>
            </div>
            <div className="mx-1">
                <EmailShareButton
                    url={SEO_SITE_URL}
                    title={SEO_URL_DESCRIPTION}>
                    <EmailIcon size={iconSize} round/>
                </EmailShareButton>
            </div>
        </div>
    );
}

export default SocialLinks;