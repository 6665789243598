import React from "react";

export default function ArrowUpSvg({onClick, w, h, color, disabled}) {
    return (
        <div onClick={onClick} className={`m-0 p-0 ${disabled ? 'disabled-tag-lock': ''}`}>
            <svg width={w || 25} height={h || 25} fill={color || 'black'}  viewBox="0 0 16 16">
                <path d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
            </svg>
        </div>
    )
}