
export function addHoursToDate(date, hours) {
    if ((!hours) || (hours <= 0)) return date.getTime();
    return date.getTime() + hours*60*60*1000;
}

export function getExpirationTime(key) {
    let expirationTime = localStorage.getItem(key);
    if (!expirationTime) return 0;
    return expirationTime;
}

export function convertUTCDateToLocalDate(date) {
    let newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

    let offset = new Date().getTimezoneOffset() / 60;
    let hours = date.getHours();

    newDate.setHours(hours - offset);
    return newDate;
}

export function getAge(dateString) {
    if (!dateString) {
        return '';
    }

    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age ?? '';
}