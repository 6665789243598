import {createSlice} from "@reduxjs/toolkit";
import uniqueBy from "@popperjs/core/lib/utils/uniqueBy";

/**
 *
 * @type {Slice<{profiles: [], page: number, isEmptyData: boolean}, {stopLoadSearchedProfiles: reducers.stopLoadSearchedProfiles, incPageSearchedProfiles: reducers.incPageSearchedProfiles, fullDropSearchedProfiles: reducers.fullDropSearchedProfiles, setSearchedProfiles: reducers.setSearchedProfiles}, string>}
 */
export const searchProfilesSlice = createSlice({
    name: 'searchProfiles',
    initialState: {
        page: 1,
        profiles: [],
        isEmptyData: false,
    },
    reducers: {
        setSearchedProfiles: (state, action) => {
            state.profiles = uniqueBy(state.profiles?.concat(action.payload), (item) => item?.id);
        },
        fullDropSearchedProfiles: (state) => {
            state.profiles = [];
            state.page = 1;
            state.isEmptyData = false;
        },
        stopLoadSearchedProfiles: state => {
            state.isEmptyData = true;
        },
        incPageSearchedProfiles: state => {
            state.page += 1;
        },
    },
});

export const selectSearchedProfiles = (state) => state.searchProfiles;
export const {setSearchedProfiles, fullDropSearchedProfiles, stopLoadSearchedProfiles, incPageSearchedProfiles} = searchProfilesSlice.actions;
export default searchProfilesSlice.reducer;