import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    Card, Divider,
    FormControl,
    Grid,
    IconButton, MenuItem, Select, Slider, Switch,
} from "@mui/material";
import Viewer from 'react-viewer';
import RoundSubstrate from '../../../Svg/Sunstrate/RoundSubstrate';
import IconSubTitle from "../../../Components/Header/IconSubTitle";
import {
    CAPTION_DELETE,
    CAPTION_FIRST_NAME,
    CAPTION_FIRST_NAME_HINT,
    CAPTION_RAISING,
    CAPTION_SECOND_NAME,
    CAPTION_SECOND_NAME_HINT,
    DESC_DELETE_PROFILE, DESC_SWITCH_EMAIL_MSG_NOTIFY,
    EMPTY_TEXT_PROFILE_FIELD,
    FAMILY_STATUS_DATA,
    KIDS_DATA, LABEL_ADS, LABEL_ERROR,
    MEET_PREFERENCES_DATA, MSG_DELETE_ACCOUNT_CONFIRM, MSG_FILE_TOO_BIG, MSG_UPLOAD_FILES_NO_MORE,
    SEX_DATA,
    SEX_ORIENTATION_DATA,
    SUBTITLE_ABOUT_ME,
    SUBTITLE_BODY,
    SUBTITLE_BRIEFLY_ABOUT_YOURSELF,
    SUBTITLE_CHILDS,
    SUBTITLE_DELETE_PROFILE, SUBTITLE_EMAIL_MSG_NOTIFY,
    SUBTITLE_FAMILY_STATUS,
    SUBTITLE_HEIGHT,
    SUBTITLE_HOBBIES,
    SUBTITLE_HOBBIES_AND_OTHER,
    SUBTITLE_MY_PHOTOS,
    SUBTITLE_SEX,
    SUBTITLE_SEX_ORIENTATION,
    SUBTITLE_WEIGHT,
    SUBTITLE_WHOM_LOOKING_FOR,
    SUBTITLE_YOUR_BIRTHDAY
} from "../../../Constants/TextMessagesRu";
import {useDispatch, useSelector} from "react-redux";
import IconFab from "../../../Components/Fabs/IconFab";
import PhotoCard from "../../../Components/Photo/PhotoCard";
import {saveFile} from "../../../api/UploadFiles";
import {selectFilesChange, setFilesChanged} from "../../../Stores/slices/LoadFilesSlice";
import {raiseUserProfile, removeUser} from "../../../api/ChatDataApi";
import {getEnvOfStorage, KEY_RAISE_PROFILE} from "../../../Stores/Env";
import OptionItem from "../../../Components/Options/OptionItem";
import {useNavigate} from "react-router-dom";
import {ROUTE_SIGNUP} from "../../../Constants/Routes";

import PreloadButtonWithTimeSate from "../../../Components/SpecButtons/PreloadButtonWithTimeSate";
import {editProfile, selectProfile} from "../../../Stores/slices/UserProfileSlices";
import {AlertToastFilled} from "../../../Components/Toasts/AlertToastFilled";
import {dropAlertToast, selectToast} from "../../../Stores/slices/ToastSlice";
import {getAge} from "../../../services/DateTimeService";
import AutocompleteCityList from "../../../Components/Autocompletes/AutocompleteCityList";
import {locationValue, maxImgFileSize} from "../../../services/DataService";
import {selectPaidOptions} from "../../../Stores/slices/PaidStatusSlice";
import Loader from "../../../Components/Loader/Loader";
import CameraSvg from "../../../Svg/CameraSvg";
import GeoSvg from "../../../Svg/GeoSvg";
import {PersonSvg} from "../../../Svg";
import MovingSvg from "../../../Svg/MovinSvg";
import EmojiSmileSvg from "../../../Svg/EmojiSmileSvg";
import HobbySvg from "../../../Svg/HobbySvg";
import GroupSvg from "../../../Svg/GroupSvg";
import GenderSvg from "../../../Svg/GenderSvg";
import FaceSvg from "../../../Svg/FaceSvg";
import ChildCareSvg from "../../../Svg/ChildCareSvg";
import FamilySvg from "../../../Svg/FamilySvg";
import ArrowUpSvg from "../../../Svg/ArrowUpSvg";
import WeightSvg from "../../../Svg/WeightSvg";
import ExclamationCircleSvg from "../../../Svg/ExclamationCircleSvg";
import EnvelopeSvg from "../../../Svg/EnvelopeSvg";
import PencilFillSvg from "../../../Svg/PencilFillSvg";
import PlusCircleSvg from "../../../Svg/PlusCircleSvg";
import MinusCircleSvg from "../../../Svg/MinusCircleSvg";
import PlusSvg from "../../../Svg/PlusSvg";
import {compressImageFile} from "../../../services/FileService";
import {appendYaAds} from "../../../services/Yandex";
//import {appendYaAds} from "../../../services/Yandex";

/**
 *
 * @param defaultValue
 * @param icon
 * @param isEdit
 * @param onPersonChanged
 * @param editField
 * @param {boolean} lockBirthdayEdit
 * @returns {JSX.Element}
 * @constructor
 */
const EditablePersonFields = ({defaultValue, icon, isEdit, onPersonChanged, editField, lockBirthdayEdit}) => {
    const [editToggle, setEditToggle] = useState(false);
    const [person, setPerson] = useState({firstName: defaultValue?.firstName || "", lastName: defaultValue?.lastName || "", birthDate: defaultValue?.birthDate || "1987-05-11", age: defaultValue?.age})

    useEffect(() => {
        setEditToggle(editField);
    }, [editField]);

    useEffect(() => {
        if (onPersonChanged) {
            onPersonChanged(person);
        }
    }, [person]);

    useEffect(() => {
        appendYaAds("yandex_rtb_R-A-2450444-4", "R-A-2450444-4")
        appendYaAds("yandex_rtb_R-A-2450444-5", "R-A-2450444-5")
    }, [])

    let spaceBetween = person?.lastName?.trim()?.length > 0 ? " ": "";
    let fio = `${person?.firstName?.trim()}${spaceBetween}${person?.lastName?.trim()}, ${getAge(person?.birthDate)}`;

    return (
        <div>
           <div className="d-flex flex-row justify-content-start align-items-center my-sm-1 my-md-2 my-lg-3">
              <RoundSubstrate children={icon} color={"orange"}/>
              <div className="mx-2 text-success">
                    {<span className="fs-3_">{fio}</span>}
              </div>
               {isEdit &&
               <IconButton onClick={() => {setEditToggle(!editToggle)}}>
                   <PencilFillSvg w={18} h={18}/>
               </IconButton>}
           </div>

            {(editToggle && isEdit) &&
            <div>
                <div className="d-flex flex-row flex-wrap align-items-center">
                    <div className="d-flex flex-column mx-sm-1 mx-md-2 mx-lg-2 my-2">
                        <label className="input-label">{CAPTION_FIRST_NAME}</label>
                        <input placeholder={CAPTION_FIRST_NAME_HINT}
                               className="input_field"
                               defaultValue={person.firstName}
                               onChange={(e) => {setPerson(prevState => ({...prevState, firstName: e?.target?.value}))}}/>
                    </div>
                    <div className="d-flex flex-column mx-sm-1 mx-md-2 mx-lg-2 my-2">
                        <label className="input-label">{CAPTION_SECOND_NAME}</label>
                        <input placeholder={CAPTION_SECOND_NAME_HINT}
                               className="input_field"
                               defaultValue={person.lastName}
                               onChange={(e) => {setPerson(prevState => ({...prevState, lastName: e?.target?.value}))}}/>
                    </div>
                </div>
                {!lockBirthdayEdit &&
                <div className="d-flex flex-row align-items-center mx-sm-1 mx-md-2 mx-lg-2">
                    <div className="d-flex flex-column justify-content-center mt-2">
                        <label className="input-label">{SUBTITLE_YOUR_BIRTHDAY}</label>
                        <input type={"date"}
                               required
                               defaultValue={person?.birthDate}
                               className="input_field"
                               onChange={(e) => {setPerson(prevState => ({...prevState, birthDate: e?.target?.value, age: getAge(e?.target?.value)}))}}/>
                    </div>
                </div>}
            </div>}
        </div>
    );
}

const EditableLocationField = ({defaultValue, icon, isEdit, onLocationChanged, editField}) => {
    const [editToggle, setEditToggle] = useState(false);
    const [location, setLocation] = useState({locality: defaultValue?.locality || "", region: defaultValue?.region || "", country: defaultValue?.country || ""});

    useEffect(() => {
        setEditToggle(editField);
    }, [editField]);

    useEffect(() => {
        if (onLocationChanged) {
            onLocationChanged(location);
        }
    }, [location]);

    function onLocationSelected(value) {
        setLocation(value);
    }


    return (
        <div className="d-flex flex-column my-2">
            <div className="d-flex flex-row justify-content-start align-items-center my-1">
                <RoundSubstrate children={icon} color={"orange"}/>
                <div className="mx-2">
                    {<span className="h5 fs-3_">{locationValue(location)}</span>}
                </div>
                {isEdit &&
                <IconButton onClick={() => {setEditToggle(!editToggle)}}>
                    <PencilFillSvg w={18} h={18}/>
                </IconButton>}
            </div>
            {(editToggle && isEdit) &&
            <AutocompleteCityList onLocationSelected={onLocationSelected}
                                  showIcon={false}
                                  width={360}
                                  defaultLocation={{
                                      locality: location?.locality || "",
                                      region: location?.region || "",
                                      country: location?.country || ""
                                  }}/>}
        </div>
    );
}

const EditableScaleField = ({defaultValue, icon, isEdit, onChangeValue, iconTitle, editField, min, max}) => {
    const [editToggle, setEditToggle] = useState(false);
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        setEditToggle(editField);
    }, [editField]);

    function onValueChanged(e) {
        setValue(e?.target?.value);
    }

    useEffect(() => {
        onChangeValue(value);
    }, [value])

    function onAdd(val) {
        if (val < max) {
            setValue(prevState => prevState+1)
        }
    }

    function onSub(val) {
        if (val > min) {
            setValue(prevState => prevState-1)
        }
    }

    return (
        <div className="d-flex flex-column my-2">
            <div className="d-flex flex-row justify-content-start align-items-center">
                <IconSubTitle text={iconTitle || ""} icon={icon}/>
                {isEdit &&
                <IconButton onClick={() => {setEditToggle(!editToggle)}}>
                    <PencilFillSvg w={18} h={18}/>
                </IconButton>}
            </div>
            {(editToggle && isEdit) &&
            <div className="d-flex flex-column">
                <div className="d-flex flex-row align-items-center w-75">
                    <div onClick={() => onSub(value)} className="mx-1">
                        <MinusCircleSvg color={"#01923b"} w={22} h={22}/>
                    </div>
                    <Slider min={min || 0} max={max || 100} aria-label="Volume" value={value || 0} onChange={onValueChanged} />
                    <div onClick={() => onAdd(value)} className="mx-1">
                        <PlusCircleSvg color={"#01923b"} w={22} h={22}/>
                    </div>
                </div>
            </div>}
            <div className="mx-2 text-success">{<span className="fs-4_">{value}</span>}</div>
        </div>
    );
}

const EditableTextAreaField = ({text, icon, iconTitle, isEdit, onChangeContent, editField}) => {
    const [editToggle, setEditToggle] = useState(false);
    const [content, setContent] = useState(text)

    useEffect(() => {
        setEditToggle(editField);
    }, [editField]);

    function onChange(e) {
        setContent(e?.target?.value);
        onChangeContent(e?.target?.value);
    }

    return(
        <div className="d-flex flex-column my-2">
            <div className="d-flex flex-row justify-content-start align-items-center">
                <IconSubTitle text={iconTitle} icon={icon}/>
                {isEdit &&
                <IconButton onClick={() => {setEditToggle(!editToggle)}}>
                    <PencilFillSvg w={18} h={18}/>
                </IconButton>}
            </div>
            {(editToggle && isEdit) &&
            <div className="d-flex flex-row align-items-center mt-1">
                 <textarea defaultValue={content} rows={6} cols={6} onChange={onChange} maxLength={200} className="multi-text-field px-2 py-1 w-100 fs-4_"/>
            </div>}
            <div className="mx-2 text-success text-wrap-div">{<span className="fs-4_">{content}</span>}</div>
        </div>
    );
}

const EditableListField = ({data, defaultValue, icon, iconTitle, isEdit, onSelectedItem, editField}) => {
    const [editToggle, setEditToggle] = useState(false);
    const [value, setValue] = useState(getSelectedValue(defaultValue));

    useEffect(() => {
        setEditToggle(editField);
    }, [editField]);

    function onSelect(e) {
        let selectedTag = e?.target?.value;
        onSelectedItem(e?.target?.value);
        setValue(getSelectedValue(selectedTag));
    }

    function getSelectedValue(selectedTag) {
        if (data?.length > 0) {
            return ((data.filter(elem => (elem?.tag === selectedTag))[0])?.value);
        }
        return '';
    }

    return(
        <div className="d-flex flex-column my-2">
            <div className="d-flex flex-row justify-content-start align-items-center">
                <IconSubTitle text={iconTitle} icon={icon}/>
                {isEdit &&
                <IconButton onClick={() => {setEditToggle(!editToggle)}}>
                    <PencilFillSvg w={18} h={18}/>
                </IconButton>}
            </div>
            {(editToggle && isEdit) &&
            <div className="d-flex flex-row align-items-center w-75 mt-1">
                    <FormControl variant="standard" fullWidth>
                        <Select
                            className="input_field px-2 py-0"
                            defaultValue={defaultValue}
                            onChange={onSelect}>
                            {data.map((elem, key) =>
                                <MenuItem key={key} value={elem?.tag}>{elem?.value}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
            </div>}
            <div className="mx-2 text-success">{<span className="fs-4_">{value}</span>}</div>
        </div>
    );
}

/**
 * ProfileDetail profile:
 * {
 * "id":"200",
 * ...
  "imgUrls": [
                {
                    "src": "/api/resource?user_id=201&file_id=kimi_ni_todoke_devushka_paren_lyubov_chuvstva_vstrecha_svidanie_29715_1280x1280.jpg",
                    "alt": "kimi_ni_todoke_devushka_paren_lyubov_chuvstva_vstrecha_svidanie_29715_1280x1280.jpg"
                }
            ],
            "thumbUrl": {
                "src": "/api/resource/thumb?user_id=201",
                "alt": "kimi_ni_todoke_devushka_paren_lyubov_chuvstva_vstrecha_svidanie_29715_1280x1280.jpg"
            }
 *
 * */
/**
 *
 * @param {Object} profile
 * @param {boolean} isEdit
 * @param {string} currentUserId
 * @returns {JSX.Element}
 * @constructor
 */
const ProfileEditableForm = ({profile, isEdit, currentUserId}) => {
    const [visible, setVisible] = useState(false);
    const [uploadFile, setUploadFile] = useState({loading: false, error: ''});
    const [specOptions, setSpecOptions] = useState({raiseLoading: false, raiseHours: 0, raiseMessage: ""});
    const [imageIndex, setImageIndex] = useState(0);
    const {editField} = useSelector(selectProfile);
    const pageDispatch = useDispatch();
    const fileInputRef = useRef();
    const {favoriteImageFileName} = useSelector(selectFilesChange);
    const navigate = useNavigate();
    const {alert} = useSelector(selectToast);
    const {payTags} = useSelector(selectPaidOptions);
    const maxPhotoCount = 2;

    /*useEffect(() => {

    }, [])*/

    const fabAddImageStyle = {
        position: 'relative',
    }

    useEffect(() => {
        pageDispatch(editProfile(profile));
        setUploadFile(prevState => ({...prevState, loading: false}));
    }, [profile])

    function getFullUrls() {
        return (profile?.imgUrls?.length > 0) ?
            profile?.imgUrls.map(elem => ({src: `${getEnvOfStorage()?.staticUrl}${elem?.src}`, alt: elem?.alt})) : [];
    }

    /**Заполнить поля Имя, Фамилия и год рождения*/
    function onPersonChanged(data) {
        pageDispatch(editProfile({firstName: data?.firstName, lastName: data?.lastName, birthDate: data?.birthDate, age: data?.age}));
    }

    function onLocationChanged(data) {
        pageDispatch(editProfile({locality: data?.locality, region: data?.region, country: data?.country}));
    }

    function onAlertClosed() {
        pageDispatch(dropAlertToast());
    }

    function onAddImage() {
        fileInputRef?.current?.click();
    }

    const onFileChange = async (e) => {
        setUploadFile(prevState => ({...prevState, loading: true, error: ''}));
        let file = e?.target?.files[0];

        file = await compressImageFile(file);

        if (file?.size / 1048576 > maxImgFileSize) {
            setUploadFile(prevState => ({
                ...prevState,
                error: `${MSG_FILE_TOO_BIG} ${maxImgFileSize} Мб`,
                loading: false
            }));
            return;
        }

        if (file) {
            let fReader = new FileReader();
            fReader.readAsDataURL(file);
            fReader.onloadend = function (event) {
                saveFile(file, currentUserId, (res, err) => {
                    if (!err) {
                        pageDispatch(setFilesChanged());
                    } else {
                        setUploadFile(prevState => ({...prevState, error: LABEL_ERROR}));
                    }
                    setUploadFile(prevState => ({...prevState, loading: false}));
                });
            }
        }
    }



    function showImagePreview(index) {
        setVisible(true);
        setImageIndex(index);
    }

    /*Удалить профиль пользователя полностью*/
    function removeUserProfile() {
        if (window.confirm(MSG_DELETE_ACCOUNT_CONFIRM)) {
            removeUser(profile?.id).then(res => {
                navigate(ROUTE_SIGNUP);
            }).catch(err => {
                console.log(err);
            });
        }
    }

    function onRaiseProfile() {
        setSpecOptions(prevState => ({...prevState, raiseLoading: true}));
        raiseUserProfile(currentUserId)
            .then(res => {
                setSpecOptions(prevState => ({...prevState, raiseHours: res?.hours, raiseMessage: res?.result}));
            })
            .catch(err => {
                console.warn("onRaiseProfile: "+err);
            })
            .finally(() => {
                setSpecOptions(prevState => ({...prevState, raiseLoading: false}))
            });
    }

    function onUploadFile(data) {
        setUploadFile(data);
    }

    return(
        <>
            <div className="d-block my-sm-0 py-md-1 py-lg-2 px-sm-2 px-md-3 px-lg-4 position-relative w-100">

                <div>
                    <Viewer
                        visible={visible}
                        onClose={() => { setVisible(false); } }
                        images={getFullUrls()}
                        noFooter={true}
                        scalable={true}
                        downloadable={true}
                        activeIndex={imageIndex}
                    />
                </div>
                <div className="d-flex flex-row justify-content-start align-items-center">
                    <input type="file" multiple={false} onChange={onFileChange} accept="image/*" ref={fileInputRef} hidden/>
                    <IconSubTitle text={SUBTITLE_MY_PHOTOS} icon={<CameraSvg />}/>
                    {((getFullUrls()?.length > 0) && (getFullUrls()?.length < maxPhotoCount) && isEdit) &&
                    <IconFab
                        fabStyle={fabAddImageStyle}
                        icon={<PlusSvg color={"#fff"}/>}
                        bgColor={"secondary"}
                        iconColor={'#FFF'}
                        size={"small"}
                        onClick={onAddImage}
                    />}

                    <span className="mx-2" style={{display: uploadFile.loading ? 'flex' : 'none'}}>
                        <Loader height={30}/>
                    </span>
                </div>
                {isEdit && <>
                <span className="d-flex align-items-center px-sm-1 px-md-2 mt-1">
                    <span  className="mx-1 px-1 text-danger fs-6_" style={{visibility: (uploadFile.error !== '') ? 'visible': 'hidden'}}>
                         {uploadFile.error}
                    </span>
                </span>
                </>}
                <div className="d-flex justify-content-start my-2 w-100">

                        <Grid container >
                            {getFullUrls()?.length > 0 &&
                             getFullUrls()?.map((item, key) => (
                                    <Grid key={key} item xs={12} sm={9} md={4} lg={4}>
                                        <Card className="card m-1 photo-card">
                                            {(item.src !== '') ?
                                                <PhotoCard
                                                    key={key}
                                                    height={300}
                                                    imgUrl={item?.src}
                                                    alt={item?.alt}
                                                    onClick={() => showImagePreview(key)}
                                                    sex={profile?.sex}
                                                    isEditable={isEdit}
                                                    userId={currentUserId}
                                                    isFavorite={favoriteImageFileName === item?.alt}
                                                />
                                                :
                                                <PhotoCard
                                                    key={key}
                                                    isAdd={true}
                                                    isEditable={isEdit}
                                                    userId={currentUserId}
                                                    sex={profile?.sex}
                                                    alt={item?.alt}
                                                />
                                            }
                                        </Card>
                                    </Grid>
                                ))}
                            {getFullUrls()?.length === 0 &&
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                <Card className="card m-1 photo-card">
                                    <PhotoCard
                                        key={1}
                                        isAdd={true}
                                        isEditable={isEdit}
                                        userId={currentUserId}
                                        sex={profile?.sex}
                                        onUploadFile={onUploadFile}
                                        thumbAlt={profile?.thumbUrl?.alt}
                                    />
                                </Card>
                            </Grid>}
                        </Grid>

                </div>

                {/*Имя, фамилия и возраст*/}
                <div className="d-flex p-0 my-sm-2 my-md-2 my-lg-0 flex-row justify-content-between flex-wrap align-items-center">

                    <div className="d-flex flex-column justify-content-center m-0">
                        <EditableLocationField
                            icon={<GeoSvg />}
                            defaultValue={profile}
                            isEdit={payTags?.changeLocation && isEdit}
                            editField={editField}
                            onLocationChanged={onLocationChanged}
                        />
                        <EditablePersonFields
                            icon={<PersonSvg w={24} h={24}/>}
                            defaultValue={profile}
                            isEdit={isEdit}
                            editField={editField}
                            onPersonChanged={onPersonChanged}
                            lockBirthdayEdit={!payTags?.changeAge}
                        />
                    </div>
                    <div className="d-flex flex-row align-items-center">
                        {(payTags?.upForm && isEdit) &&
                        <PreloadButtonWithTimeSate
                            onClick={onRaiseProfile}
                            icon={<MovingSvg color={"#fff"}/>}
                            loading={specOptions.raiseLoading}
                            caption={CAPTION_RAISING}
                            id={KEY_RAISE_PROFILE}
                            lockHours={specOptions.raiseHours}/>}
                    </div>
                </div>
                <span className="mt-sm-6 mt-md-4 fs-3_">{SUBTITLE_HOBBIES_AND_OTHER}</span>
                <Divider/>
                <Grid container className="p-2">
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <EditableTextAreaField
                            text={profile?.aboutMe || EMPTY_TEXT_PROFILE_FIELD}
                            isEdit={isEdit}
                            icon={<EmojiSmileSvg />}
                            editField={editField}
                            iconTitle={SUBTITLE_ABOUT_ME}
                            onChangeContent={(text) => {
                                //setProfile(prevState => ({...prevState, aboutMe: text}));
                                pageDispatch(editProfile({aboutMe: text}));
                            }}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <EditableTextAreaField
                            text={profile?.hobby || EMPTY_TEXT_PROFILE_FIELD}
                            isEdit={isEdit}
                            editField={editField}
                            icon={<HobbySvg />}
                            iconTitle={SUBTITLE_HOBBIES}
                            onChangeContent={(text) => {
                                //setProfile(prevState => ({...prevState, hobby: text}))
                                pageDispatch(editProfile({hobby: text}));
                            }}/>
                    </Grid>
                </Grid>
                <span className="mt-sm-6 mt-md-4 fs-3_">{SUBTITLE_BRIEFLY_ABOUT_YOURSELF}</span>
                <Divider/>
                <Grid container className="p-2">
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <EditableListField
                            iconTitle={SUBTITLE_WHOM_LOOKING_FOR}
                            icon={<GroupSvg />}
                            isEdit={isEdit}
                            editField={editField}
                            onSelectedItem={(value) => {
                                pageDispatch(editProfile({meetPreferences: value}));
                            }}
                            defaultValue={profile?.meetPreferences}
                            data={MEET_PREFERENCES_DATA}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <EditableListField
                            iconTitle={SUBTITLE_SEX_ORIENTATION}
                            icon={<GenderSvg w={24} h={24}/>}
                            isEdit={isEdit}
                            editField={editField}
                            onSelectedItem={(value) => {
                                //setProfile(prevState => ({...prevState, sexOrientation: value}))
                                pageDispatch(editProfile({sexOrientation: value}));
                            }}
                            defaultValue={profile?.sexOrientation}
                            data={SEX_ORIENTATION_DATA}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <EditableListField
                            iconTitle={SUBTITLE_SEX}
                            icon={<FaceSvg />}
                            isEdit={false}
                            editField={false}
                            onSelectedItem={(value) => {
                                //setProfile(prevState => ({...prevState, sex: value}))
                                pageDispatch(editProfile({sex: value}));
                            }}
                            defaultValue={profile?.sex}
                            data={SEX_DATA}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <EditableListField
                            iconTitle={SUBTITLE_CHILDS}
                            icon={<ChildCareSvg />}
                            isEdit={isEdit}
                            editField={editField}
                            onSelectedItem={(value) => {
                                pageDispatch(editProfile({kids: value}));
                            }}
                            defaultValue={profile?.kids}
                            data={KIDS_DATA}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <EditableListField
                            iconTitle={SUBTITLE_FAMILY_STATUS}
                            icon={<FamilySvg />}
                            isEdit={isEdit}
                            editField={editField}
                            onSelectedItem={(value) => {
                                //setProfile(prevState => ({...prevState, familyStatus: value}))
                                pageDispatch(editProfile({familyStatus: value}));
                            }}
                            defaultValue={profile?.familyStatus}
                            data={profile?.sex === 'MAN' ? FAMILY_STATUS_DATA.man : FAMILY_STATUS_DATA.woman}
                        />
                    </Grid>
                </Grid>
                <div className="d-flex align-content-center justify-content-center my-3">
                    <div className="ads-320-90" id="yandex_rtb_R-A-2450444-5">{LABEL_ADS}</div>
                </div>
                <span className="mt-sm-6 mt-md-4 fs-3_">{SUBTITLE_BODY}</span>
                <Divider/>
                <Grid container className="p-2">
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <EditableScaleField
                            defaultValue={profile?.height}
                            isEdit={isEdit}
                            iconTitle={SUBTITLE_HEIGHT}
                            min={130}
                            max={250}
                            onChangeValue={value => {
                                //setProfile(prevState => ({...prevState, height: value}))
                                pageDispatch(editProfile({height: value}));
                            }}
                            icon={<ArrowUpSvg/>}
                            editField={editField}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <EditableScaleField
                            defaultValue={profile?.weight}
                            isEdit={isEdit}
                            iconTitle={SUBTITLE_WEIGHT}
                            min={45}
                            max={150}
                            onChangeValue={value => {
                                //setProfile(prevState => ({...prevState, weight: value}))
                                pageDispatch(editProfile({weight: value}));
                            }}
                            icon={<WeightSvg/>}
                            editField={editField}/>
                    </Grid>
                </Grid>

                <div className="d-flex align-content-center justify-content-center mt-3">
                    <div className="ads-320-90" id="yandex_rtb_R-A-2450444-4">{LABEL_ADS}</div>
                </div>


                {isEdit &&
                <div className="mt-5">
                    <OptionItem
                        hiddenTitle={false}
                        description={DESC_SWITCH_EMAIL_MSG_NOTIFY}
                        title={SUBTITLE_EMAIL_MSG_NOTIFY}
                        icon={<EnvelopeSvg color={"#01923b"}/>}
                        component={
                            <div className="d-flex flex-column justify-content-center">
                                <Switch defaultChecked={profile?.userSettings?.emailedMessageEvents}
                                        onChange={(e) => {
                                            pageDispatch(editProfile({userSettings: {emailedMessageEvents: e?.target?.checked, notifyCommonEvents: profile?.userSettings?.notifyCommonEvents}}));
                                        }}
                                />
                                {/*profile?.userSettings?.emailedMessageEvents ? "Включена" : "Отключена"*/}
                            </div>
                        }
                    />
                </div>}

                {isEdit &&
                  <div className="mt-sm-4 mt-md-5">
                      <OptionItem
                          hiddenTitle={false}
                          description={DESC_DELETE_PROFILE}
                          title={SUBTITLE_DELETE_PROFILE}
                          icon={<ExclamationCircleSvg color={'red'}/>}
                          component={
                              <Button endIcon={<MinusCircleSvg color={"#fff"} w={16} h={16}/>} variant={"contained"} onClick={removeUserProfile} sx={{backgroundColor: 'red'}}>
                                  <span className="fs-6_">{CAPTION_DELETE}</span>
                              </Button>
                          }
                      />
                  </div>
                }



                <div className="p-4">{}</div>
            </div>


            <AlertToastFilled open={alert.open} text={alert.text} severity={alert?.severity} onClose={onAlertClosed}/>
        </>
    )
};


export default ProfileEditableForm;