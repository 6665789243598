import {createSlice} from "@reduxjs/toolkit";
import uniqueBy from "@popperjs/core/lib/utils/uniqueBy";

/**
 *
 * @type {Slice<{guests: [], page: number, isEmptyData: boolean}, {setGuests: reducers.setGuests, fullDropGuests: reducers.fullDropGuests, incPageGuests: reducers.incPageGuests, partDropGuests: reducers.partDropGuests}, string>}
 */
export const guestSlice = createSlice({
    name: 'guest',
    initialState: {
        page: 1,
        guests: [],
        isEmptyData: false,
    },
    reducers: {
        setGuests: (state, action) => {
            state.guests = uniqueBy(state.guests.concat(action.payload), (item) => item?.id);
        },
        fullDropGuests: (state) => {
            state.guests = [];
            state.page = 1;
            state.isEmptyData = false;
        },
        stopLoadGuests: state => {
            state.isEmptyData = true;
        },
        incPageGuests: state => {
            state.page += 1;
        },
    },
});

export const selectGuests = (state) => state.guest;
export const {setGuests, fullDropGuests, stopLoadGuests, incPageGuests} = guestSlice.actions;
export default guestSlice.reducer;