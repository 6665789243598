import React from "react";

function Wallet({w, h, color}) {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width={w || 20} height={h || 20} fill={color || '#000'}
                 className="bi bi-check-circle" viewBox="0 0 16 16">
                <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z"/>
            </svg>
        </>
    )
}

function ExclamationCircle({w, h, color}) {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width={w || 20} height={h || 20} fill={color || '#000'}
                 className="bi bi-check-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
            </svg>
        </>
    )
}

function CheckCircle({w, h, color}) {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width={w || 20} height={h || 20} fill={color || '#000'}
                 className="bi bi-check-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
            </svg>
        </>
    )
}

export const iconChipStat = {
    checked: 'CHECKED',
    warn: 'WARN',
};

const IconChip = ({caption, showIcon, chipStat, textColor}) => {

    if (chipStat === iconChipStat.checked) {
        return (
            <div className="rounded-pill bg-white px-2 py-1 border-4 border border-success d-flex justify-content-center align-items-center mx-2 my-1">
                {showIcon && <CheckCircle color={"#178e0e"}/>}
                <div className="mx-1 fw-bold">{caption || 'Нет текста'}</div>
            </div>
        );
    }

    if (chipStat === iconChipStat.warn) {
        return (
           <div className="rounded-pill bg-white px-2 py-1 border-4 border border-warning d-flex justify-content-center align-items-center mx-2 my-1">
               {showIcon && <ExclamationCircle color={"#ef6a11"}/>}
               <div className="mx-1 fw-bold">{caption || 'Нет текста'}</div>
           </div>
        );
    }

    return (
         <div className="rounded-pill bg-white px-3 py-1 border-2 border border-primary d-flex justify-content-center align-items-center mx-2 my-1">
             {showIcon && <Wallet color={"primary"}/>}
             <div className="mx-1 fw-bold">{caption || 'Нет текста'}</div>
         </div>
    );
}

export default IconChip;