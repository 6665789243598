import {getHeaderBearerConfigs, TOKEN_KEY} from "./ApiCommon";
import axios from "axios";
import {getEnvOfStorage} from "../Stores/Env";
import {extractPaymentStatusOption} from "../services/DataService";

/**
 * Получить список опций со статусами
 * @param {string} userId
 * @returns {Promise<AxiosResponse<any>>}
 */
function getPaidOptions(userId) {
    let token = localStorage.getItem(TOKEN_KEY);
    return axios.get(`${getEnvOfStorage()?.payUrl}/api/options/category/group/options/${userId}`, getHeaderBearerConfigs("application/json", token));
}

export function setPaidOptionItems(userId, callback, finalization) {
    if (!userId) return;
    getPaidOptions(userId)
        .then(res => {
            let optItems = [];
            if (res?.data?.length > 0) {
                res?.data?.forEach(item => {
                    let optItem = {
                        icon: item?.thumb_url,
                        optionTags: item?.options_value.split(";"),
                        payStatusOption: extractPaymentStatusOption(item?.options),
                        caption: item?.name,
                        descriptions: item?.options?.length > 0 ? item?.options[0]?.description?.split(";"): [],
                        switchable: item?.switchable,
                        options: item?.options,
                        payStatus: extractPaymentStatusOption(item?.options)?.payStatus,
                    }
                    optItems.push(optItem);
                });
                if (callback) {
                    callback(optItems);
                }
            }
        })
        .catch(err => console.log(err))
        .finally(() => {if (finalization) finalization()});
}

export const invoiceStatuses = {
    paid: 'PAID',
    processing: 'PROCESSING',
    wait: 'WAITING',
    expired: 'EXPIRED',
    rejected: 'REJECTED',
}


export const payOptionsArray = {
    visibility: {
        invisibleGuest: 'visibility-like-guest',
    },
    chatsControl: {
        deleteChats: 'delete-chats',
        deleteLastMessage: 'delete-chat-messages',
    },
    accountForm: {
        upForm: 'up-form',
        changeAge: 'change-age',
        extendedSearch: 'extended-search',
        changeLocation: 'change-city-search',
    }
}

export const switchIfPaidOptions = (options, payOptionName) => {
    if ((!options) || (options?.length === 0) || (!payOptionName)) return false;

    let tags = options.filter(option => option?.payStatus === invoiceStatuses.paid)
        .map(item => item?.optionTags)
        .reduce((a, item) => [...a, ...item], [])
        .filter(item => item !== '');

    return (tags?.filter(item => item === payOptionName)?.length > 0);
}

export function disableLock(payParam) {
    if (!payParam) {
        return 'disabled-tag-lock';
    }
    return '';
}

export function unpaidStamp(payParam) {
    if (!payParam) {
        return 'unpaid-stamp';
    }
    return '';
}
