import React from "react";
import {Skeleton} from "@mui/material";

const UserAdItemSkeleton = () => {
    return (
        <>
                <div className="d-sm-flex d-md-none d-lg-none d-xl-none d-xxl-none flex-column align-content-center">
                   <div className="d-flex align-items-center justify-content-between my-1">
                      <div className="d-flex align-items-center">
                          <Skeleton className="mx-1" variant={"circular"} height={40} width={40}/>
                          <Skeleton variant={"rectangular"} height={15} width={90}/>
                      </div>
                       <div className="d-flex align-items-center my-1">
                           <Skeleton variant={"circular"} height={20} width={20}/>
                           <Skeleton variant={"circular"} height={20} width={20}/>
                       </div>
                   </div>
                   <Skeleton variant={"rectangular"} width={"100%"} height={80}/>
                   <div className="d-flex align-items-center justify-content-between my-1">
                       <Skeleton variant={"rectangular"} height={15} width={80}/>
                       <Skeleton variant={"rectangular"} height={15} width={80}/>
                   </div>
                </div>
                <div className="d-none d-sm-none flex-column d-md-flex d-lg-flex d-xl-flex d-xxl-flex">
                    <div className="d-flex align-items-center justify-content-between">
                        <Skeleton variant={"rectangular"} height={20} width={80}/>
                        <div className="d-flex align-items-center my-1">
                            <Skeleton variant={"circular"} height={20} width={20}/>
                            <Skeleton variant={"circular"} height={20} width={20}/>
                        </div>
                    </div>
                    <div className="d-flex align-items-center my-1">
                        <Skeleton variant={"circular"} height={95} width={95}/>
                        <div className="d-flex flex-grow-1 mx-2"><Skeleton variant={"rectangular"} width={"100%"} height={80}/></div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        <Skeleton variant={"rectangular"} height={15} width={80}/>
                        <Skeleton variant={"rectangular"} height={15} width={80}/>
                    </div>
                </div>
        </>
    );
}

export default UserAdItemSkeleton;