import {Avatar} from "@mui/material";
import React from "react";

const AvatarItem = ({src, alt, hasBorder, w, h}) => {
    const altVal = alt ?? '';

    return (
        <>
            {src !== '' ?
                <Avatar sx={{backgroundColor: '#ef8b63', ...hasBorder, width: w || 48, height: h || 48}} alt={alt} src={src}/> :
                <Avatar sx={{backgroundColor: '#ef8b63', ...hasBorder, width: w || 48, height: w || 48}} alt={alt}>{altVal[0] ?? null}</Avatar>}
        </>
    );
};


export default AvatarItem;