import React, {useEffect, useState} from "react";
import IconSubTitle from "../Header/IconSubTitle";
import {Checkbox, FormControl, Grid, MenuItem, Select} from "@mui/material";
import {disableLock} from "../../api/Payments";
import {CAPTION_NEVER_MIND} from "../../Constants/TextMessagesRu";


const ListField = ({data, defaultValue, icon, iconTitle, onSelectedItem, onChecked, isChecked, locked}) => {
    const [check, setCheck] = useState(isChecked);
    const [lastValue, setLastValue] = useState(defaultValue);

    function onSelect(e) {
        if (locked) return;

        onSelectedItem(e?.target?.value);
        if (check) {
            setLastValue(e?.target?.value);
        }
    }

    useEffect(() => {
        if (locked) return;

        if (!check) {
            onSelectedItem(null);
        }
        onChecked(check);
    }, [check]);

    return(
        <div className="d-flex flex-row justify-content-start align-items-center">
            {(!locked) &&
            <Checkbox checked={isChecked} onChange={(e) => {
                if (locked) return;

                setCheck(e?.target?.checked);
                if (!check) {
                    onSelectedItem(lastValue);
                }
            }}/>}
            <div className="d-flex flex-row justify-content-start align-items-center">
                <IconSubTitle text={iconTitle} icon={icon} font={'h6'}/>
            </div>

            {check ?
            <Grid container>
                <Grid item md={4} lg={4} sm={12} xs={12}>
                    <select className="input_field" onChange={onSelect} defaultValue={lastValue || ''}>
                        {data.map((elem, index) =>
                            <option key={index} value={elem?.tag}>{elem?.value}</option>
                        )}
                    </select>
                </Grid>
            </Grid> : <span className="mx-1 fs-6_ fw-bold text-success">{CAPTION_NEVER_MIND.toUpperCase()}</span>}
        </div>
    );
}

export default React.memo(ListField);