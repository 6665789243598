import React from "react";
import StandardHeader from "../Components/Header/StandardHeader";
import {useNavigate} from "react-router-dom";
import {ROUTE_HOME} from "../Constants/Routes";
import {Container} from "@mui/material";
import PaidOptions from "./PaidContent/PaidOptions";


const OptionsPage = () => {
    const navigate = useNavigate();

    function onBack() {
        navigate(ROUTE_HOME);
    }

    return (
        <div className="d-block flex-column m-2 scroll-none main-background">
            <Container className="my-profile my-profile-shadow d-flex flex-column">
                <StandardHeader onClick={onBack} hideOptions={true}/>
                <PaidOptions/>
            </Container>
        </div>
    );
}

export default OptionsPage;