import './App.css';
import {Routes, Route} from 'react-router-dom';
import SignIn from "./app/Pages/Forms/SignIn/SignIn";
import RegisterProfileSteps from './app/Pages/Forms/RegistrationForms/RegisterProfileSteps';
import ChatPage from "./app/Pages/Home/ChatPage";
import {
    ROUTE_COMPLAINS,
    ROUTE_HOME, ROUTE_OPTIONS,
    ROUTE_PROFILE, ROUTE_PROFILE_VIEW,
    ROUTE_REGISTRATION,
    ROUTE_RESET_PASSWORD,
    ROUTE_SIGNUP
} from "./app/Constants/Routes";
import MyProfile from "./app/Pages/ProfileDetails/MyProfile";
import {getStoredValueByKey, initEnvironment, KEY_INIT_SEARCH, storeValue} from "./app/Stores/Env";
import {createTheme, ThemeProvider} from "@mui/material";
import ResetPassword from "./app/Pages/Forms/ResetPassword";
import RedirectPage from "./app/Pages/RedirectPage/RedirectPage";
import OptionsPage from "./app/Pages/OptionsPage";
import {WebSocketClient} from "./app/api/Websocker/ws";
import {useEffect, useState} from "react";
import {USER_ID_KEY} from "./app/api/ApiCommon";
import {setPaidOptionItems} from "./app/api/Payments";
import {selectPaidOptions, setPaidOptions, stopLoading} from "./app/Stores/slices/PaidStatusSlice";
import {useDispatch, useSelector} from "react-redux";
import ComplainsPage from "./app/Pages/ComplainsPage";
import ProfileDetailView from "./app/Pages/ProfileDetails/ProfileDetailView";

export let wsClient = new WebSocketClient();

const theme = createTheme({
    palette: {
        primary: {
            main: '#f55f0d',
            light: '#ee9363',
            contrastText: '#FFFFFF',
            alert: '#f00',
        },
        secondary: {
            main: '#01923b',
            light: '#74cb96',
            contrastText: '#0e0e0e',
            alert: '#f00',
        },
    },
});

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function App() {
    const dispatch = useDispatch();
    const {updateOptionsCount} = useSelector(selectPaidOptions);
    const [loading, setLoading] = useState(true)
    //Инициализировать все переменные стреды
    initEnvironment();

    const fakeRequest = () => {
        return new Promise(resolve => setTimeout(() => resolve(), 2500));
    };

    useEffect(() => {
        let userId = getStoredValueByKey(USER_ID_KEY);
        //Проверить платные услуги
        setPaidOptionItems(userId, updatePaidOptions, finalizeUpdateOptions);



    }, [updateOptionsCount]);

    useEffect(() => {
        fakeRequest().then(() => {
            const el = document.querySelector(".loader-container");
            if (el) {
                el.remove();  // removing the spinner element
                setLoading(false); // showing the app
            }
        });

        storeValue(KEY_INIT_SEARCH, 0);

        return () => {
            wsClient = null;
        }
    }, [])

    if (loading) {
        return null;
    }

    function finalizeUpdateOptions() {
        dispatch(stopLoading())
    }

    function updatePaidOptions(optItems) {
        dispatch(setPaidOptions(optItems))
    }

  return (
      <ThemeProvider theme={theme}>
          <div>
              <Routes>
                  <Route path={ROUTE_HOME} element={<ChatPage/>}/>
                  <Route path={ROUTE_SIGNUP} element={<SignIn/>}/>
                  <Route path={ROUTE_REGISTRATION} element={<RegisterProfileSteps/>}/>
                  <Route path={ROUTE_PROFILE} element={<MyProfile/>}/>
                  <Route path={ROUTE_PROFILE_VIEW} element={<ProfileDetailView />}/>
                  <Route path={ROUTE_RESET_PASSWORD} element={<ResetPassword />}/>
                  <Route path={ROUTE_OPTIONS} element={<OptionsPage/>}/>
                  <Route path={ROUTE_COMPLAINS} element={<ComplainsPage />}/>
                  <Route path="/*" element={<RedirectPage/>} />
              </Routes>
          </div>
      </ThemeProvider>
  );
}

export default App;
