import {
    BASE_AUTH_URL,
    BASE_DATA_URL,
    BASE_HOME_SITE_URL,
    BASE_PAY_URL,
    BASE_STATIC_URL, TOKEN_KEY, USER_ID_KEY,
    WS_STOMP_URL
} from "../api/ApiCommon";
import {Cookies} from "react-cookie";

const ENV_PARAMS = 'env_params';
const GUEST_PAGE = 'g_page';
const CHAT_SELECTED_USER = 'chat_selected_user';
const KEY_RAISE_PROFILE = "6770";
const GUEST_OFFSET = 'gs_offset';
const SEARCH_PROFILE_OFFSET = 'sp_offset';
const KEY_THUMB_URL = 'profile_thumb';
const KEY_INIT_SEARCH = "init_search";


export function initEnvironment() {
    const environment = process.env;
    const envData = {
        env: !(environment?.REACT_APP_ENV) ? 'dev': environment?.REACT_APP_ENV,
        authUrl: !(environment?.REACT_APP_BASE_AUTH_URL) ? BASE_AUTH_URL : environment?.REACT_APP_BASE_AUTH_URL,
        dataUrl: !(environment?.REACT_APP_BASE_DATA_URL) ? BASE_DATA_URL : environment?.REACT_APP_BASE_DATA_URL,
        staticUrl: !(environment?.REACT_APP_BASE_STATIC_URL) ? BASE_STATIC_URL : environment?.REACT_APP_BASE_STATIC_URL,
        stompUrl: !(environment?.REACT_APP_BASE_WS_URL) ? WS_STOMP_URL : environment?.REACT_APP_BASE_WS_URL,
        payUrl: !(environment?.REACT_APP_BASE_PAY_URL) ? BASE_PAY_URL : environment?.REACT_APP_BASE_PAY_URL,
        homeSiteUrl: !(environment?.REACT_APP_BASE_HOME_SITE_URL) ? BASE_HOME_SITE_URL : environment?.REACT_APP_BASE_HOME_SITE_URL,
    };

    setEnvToStorage(envData);
}

/**
 * Сохранить параметры окружения
 * @param params
 */
export function setEnvToStorage(params) {
    localStorage.setItem(ENV_PARAMS, JSON.stringify(params));
}

/**
 * Получить параметры окружения
 * @returns {string}
 */
export function getEnvOfStorage() {
    return localStorage.getItem(ENV_PARAMS) ? JSON.parse(localStorage.getItem(ENV_PARAMS)) : null;
}

/**
 *
 * @param {string} userId
 * @param {string} token
 */
export function setCookies(userId, token) {
    let cookies = new Cookies();
    let domain = process?.env?.REACT_APP_BASE_HOME_SITE_URL;
    if (domain) {
        domain = domain.replace('http://', '').replace('https://', '');
        cookies.set(TOKEN_KEY, token, {domain: domain, path: "/", maxAge: 3600*240})
        cookies.set(USER_ID_KEY, userId, {domain: domain, path: '/', maxAge: 3600*240})
    }
}

/**
 * Сохранить любое значение в локальное хранилище
 * @param {string} key
 * @param {any} value
 */
function storeValue(key, value) {
    localStorage.setItem(key, value);
}

/**
 * Получить значение из локального хранилища по ключу
 * @param {string} key
 * @returns {string}
 */
function getStoredValueByKey(key) {
    return localStorage.getItem(key);
}

export {
    GUEST_PAGE,
    CHAT_SELECTED_USER,
    storeValue,
    getStoredValueByKey,
    KEY_RAISE_PROFILE,
    GUEST_OFFSET,
    SEARCH_PROFILE_OFFSET,
    KEY_THUMB_URL,
    KEY_INIT_SEARCH,
}