
export class ChatItem {
    /*id = null;
    userId = 0;
    fromUserId = 0;
    message = '';
    group = 0;
    emailed = false;
    read = false;
    timestamp = "";*/

    read_(value) {
        this.read = value;
    }

    /*read_() {
       return this.read;
    }*/

    /**
     *
     * @param {string} id
     * @param {string} userId
     * @param {string} fromUserId
     * @param {string} message
     * @param {number} group
     * @param {boolean} isEmailed
     * @param {boolean} isRead
     * @param {string} timestamp
     */
    constructor(id, userId, fromUserId, message, group, isEmailed, isRead, timestamp) {
        this.fromUserId = fromUserId;
        this.id = id;
        this.group = group;
        this.read = isRead;
        this.userId = userId;
        this.message = message;
        this.emailed = isEmailed;
        this.timestamp = timestamp;
    }


}