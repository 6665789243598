import React, {useEffect, useState} from "react";

const Timer = ({initialMinute = 0,initialSeconds = 0, onFinished}) => {
    const [ minutes, setMinutes ] = useState(initialMinute);
    const [seconds, setSeconds ] =  useState(initialSeconds);

    useEffect(()=>{
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000)

        return ()=> {
            clearInterval(myInterval);
        };
    });

    useEffect(() => {
        if ((minutes === 0) && (seconds === 0)) {
            onFinished();
        }
    }, [minutes, seconds])

    return (
        <div>
            { minutes === 0 && seconds === 0
                ? null
                : <label className="text-primary mt-1 text-center"> {minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</label>
            }
        </div>
    )
}

export default Timer;