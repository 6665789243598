import React, {useEffect, useState} from "react";
import {getUserVisitors} from "../../api/VisitorApi";
import { PROFILE_GUESTS_PAGE_SIZE} from "../../api/ApiCommon";
import VirtualGridScrollLoader from "../../Components/VirtualLists/ListGrids/VirtualGridScrollLoader";
import {useDispatch, useSelector} from "react-redux";
import {incPageGuests, selectGuests, setGuests, stopLoadGuests} from "../../Stores/slices/GuestSlice";
import {Chip} from "@mui/material";
import {CAPTION_EMPTY_GUESTS} from "../../Constants/TextMessagesRu";
import UserProfilesSkeletons from "../../Components/Skeletons/UserProfilesSkeletons";
import {GUEST_OFFSET} from "../../Stores/Env";

let stopLoading = false;

const GuestsView = ({userId}) => {
    const [loading, setLoading] = useState(false);
    const {guests, isEmptyData, page} = useSelector(selectGuests);
    const dispatch = useDispatch();

    useEffect(() => {
        stopLoading = isEmptyData;
    }, [isEmptyData]);

    useEffect(() => {
        if (!isEmptyData) {
            loadNextPage(0);
        }
    }, []);


    /**
     * Запросить у api посетителей постранично
     * @param {number} page
     */
    function loadNextPage(page) {
        if (!stopLoading) {
            setLoading(true);
            getUserVisitors(userId, page, PROFILE_GUESTS_PAGE_SIZE, ((data, err) => {
                if (!err) {
                    if ((data?.data) && (data?.data?.length > 0)) {
                        dispatch(setGuests(data?.data));
                    } else {
                        dispatch(stopLoadGuests());
                    }
                } else {
                    dispatch(stopLoadGuests());
                }
                setLoading(false);
            }));
        }
    }

    function incPage() {
        dispatch(incPageGuests());
    }

    return (
       <div className="d-block h-100 position-relative p-sm-0 p-md-1 p-lg-1">
           {((loading) && (guests?.length < PROFILE_GUESTS_PAGE_SIZE)) && <UserProfilesSkeletons count={PROFILE_GUESTS_PAGE_SIZE} />}
           {guests?.length !== 0 ?
               <VirtualGridScrollLoader
                   onNextPage={loadNextPage}
                   content={guests}
                   isEmptyData={isEmptyData}
                   page={page}
                   incPage={incPage}
                   loading={loading}
                   keyScrollOffset={GUEST_OFFSET}
               /> :
               <div className="d-flex justify-content-center align-items-center flex-row h-100">
                   <Chip label={CAPTION_EMPTY_GUESTS.toUpperCase()} sx={{color: "black", bgcolor: 'white'}} className="fw-bold" variant={"outlined"}/>
               </div>}
       </div>

    );
}

export default GuestsView;