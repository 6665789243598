import React, {useState} from "react";
import AvatarItem from "../Avatars/AvatarItem";
import PencilFillSvg from "../../Svg/PencilFillSvg";
import TrashSvg from "../../Svg/TrashSvg";
import {getAge} from "../../services/DateTimeService";
import {getEnvOfStorage} from "../../Stores/Env";
import {capitalizeFirstLetter, getRandomDigitString} from "../../services/DataService";
import {deleteUserAd} from "../../api/UserAds";
import {IconButton} from "@mui/material";
import {MSG_CANT_REMOVE_USER_AD, MSG_REMOVE_USER_AD_FROM, MSG_USER_AD_REMOVED} from "../../Constants/TextMessagesRu";
import {setAlertToast} from "../../Stores/slices/ToastSlice";
import {useDispatch} from "react-redux";
import {setCounter} from "../../Stores/slices/UserAdSlice";

const UserAdItem = ({userAd, profile, editHandler}) => {
    const dispatch = useDispatch();

    let fio = (profile?.firstName ?? '') +' '+ (profile?.lastName ?? '');
    let age = getAge(profile?.birthDate) ? ', '+getAge(profile?.birthDate) : '';
    let location = (userAd?.locality ?? '')+(userAd?.country ? ', '+userAd?.country : '');
    let src = profile?.thumbUrl?.src ? getEnvOfStorage()?.staticUrl+profile?.thumbUrl?.src+'?'+getRandomDigitString() : '';

    /**
     * Удалить объявление
     * */
    function deleteItem(userAd) {
        if (window.confirm(MSG_REMOVE_USER_AD_FROM+userAd?.created)) {
            deleteUserAd([userAd?.id], profile?.id)
                .then(res => {
                    dispatch(setAlertToast({open: true, severity: 'warning', text: MSG_USER_AD_REMOVED}));
                    dispatch(setCounter());
                })
                .catch(err => {
                    dispatch(setAlertToast({open: true, severity: 'error', text: MSG_CANT_REMOVE_USER_AD}));
                    console.error(err)
                })
        }
    }

    return (
        <>
            {userAd && profile ?
                <>
                    <div className="d-none d-sm-none flex-column d-md-flex d-lg-flex d-xl-flex d-xxl-flex mx-0 px-1 py-0 card shadow border-primary border-2 my-2">
                        <div className="d-flex justify-content-between align-items-center flex-grow-0 px-1">
                            <div>{userAd?.created}</div>
                            <div className="d-flex justify-content-center px-1">
                                <IconButton onClick={() => editHandler(userAd)} className="mx-2 my-0 p-0"><PencilFillSvg h={19} w={19}/></IconButton>
                                <IconButton onClick={() => deleteItem(userAd)} className="mx-2 my-0 p-0"><TrashSvg h={19} w={19} color={"#F00"}/></IconButton>
                            </div>
                        </div>
                        <div className="d-flex mt-1">
                            <div className="mx-2"><AvatarItem w={95} h={95} src={src}/></div>
                            <div className="flex-grow-1 d-flex flex-column">
                                <div className="d-flex flex-grow-1 card rounded p-1 border-3 text-wrap-div text-success">{capitalizeFirstLetter(userAd?.body)}</div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mx-1 my-1">
                            <div className="text-wrap d-flex fw-bold">{`${fio} ${age}`}</div>
                            <div className="fw-bold">{location}</div>
                        </div>
                    </div>
                    <div className="d-sm-flex d-md-none d-lg-none d-xl-none d-xxl-none flex-column align-content-center mx-0 px-1 py-0 card shadow border-2 border-primary my-2">
                        <div className="d-flex justify-content-between align-items-center flex-grow-0">
                            <div className="d-flex align-items-center justify-content-center my-1">
                                <div className="mx-2"><AvatarItem w={40} h={40} src={src}/></div>
                                <div className="text-wrap d-flex fw-bold">{`${fio} ${age}`}</div>
                            </div>
                            <div className="d-flex justify-content-center px-1">
                                <IconButton onClick={() => editHandler(userAd)} className="mx-2 my-0 p-0"><PencilFillSvg h={19} w={19}/></IconButton>
                                <IconButton onClick={() => deleteItem(userAd)}  className="mx-2 my-0 p-0"><TrashSvg h={19} w={19} color={"#F00"}/></IconButton>
                            </div>
                        </div>
                        <div className="d-flex flex-grow-1 card rounded p-1 border-3 m-1 text-wrap-div text-success">{capitalizeFirstLetter(userAd?.body)}</div>
                        <div className="d-flex justify-content-between align-items-center mx-1 my-1">
                            <div>{userAd?.created}</div>
                            <div className="fw-bold">{location}</div>
                        </div>
                    </div>
                </> : <></>
            }
        </>
    );
}

export default React.memo(UserAdItem);