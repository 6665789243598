import React from "react";
import CheckSvg from "../../Svg/CheckSvg";
import CheckAllSvg from "../../Svg/CheckAllSvg";

/**
 * Иконка галочки о прочитанном сообщении
 * @param {boolean} isRead
 * @returns {JSX.Element}
 * @constructor
 */
const MessageStatus = ({isRead}) => {
    return (
        <div className="d-flex justify-content-end flex-row align-items-center">
            {isRead ?
                <CheckAllSvg color={'blue'} w={28} р={28}/>
                :
                <CheckSvg color={'gray'} w={28} р={28}/>
            }
        </div>
    );
}

export default MessageStatus;