import React, {useEffect, useMemo, useState} from "react";
import { Tab, Tabs} from "@mui/material";
import TabItem from "../../Components/Tabs/TabItem";
import ChatView from "../ChatView";
import {
    CAPTION_ADS,
    CAPTION_CHATS,
    CAPTION_GUESTS,
    CAPTION_SEARCH,
} from "../../Constants/TextMessagesRu";
import {TOKEN_KEY, USER_ID_KEY} from "../../api/ApiCommon";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AlertToast} from "../../Components/Toasts/AlertToast";
import {deleteUserAccountAsync} from "../../Stores/slices/UserSlice";
import {GuestsView} from "../Guests";
import ResponsiveAppBar from "../../AppBar/ResponsitiveAppBar";
import SearchProfiles from "../SearchProfiles/SearchProfiles";
import {ROUTE_SIGNUP} from "../../Constants/Routes";
import {
    dropChatSelectedUser,
    selectCommon,
    setMyProfile,
    setTabIndex
} from "../../Stores/slices/CommonSlice";
import ProfileDetail from "../ProfileDetails/ProfileDetail";
import {getComplains, userProfile} from "../../api/ChatDataApi";
import {getStoredValueByKey, KEY_INIT_SEARCH, storeValue} from "../../Stores/Env";
import ChatToast from "../../Components/Toasts/ChatToast";
import {
    deleteMessageListItems,
    selectChatMessages, setMessageBody, setMessageList, updateBeforeMessageList, updateMessageList,
} from "../../Stores/slices/ChatMessagesSlice";
import {AlertToastFilled} from "../../Components/Toasts/AlertToastFilled";
import {dropAlertToast, selectToast} from "../../Stores/slices/ToastSlice";
import {wsClient} from "../../../App";
import {MessageTypes} from "../../api/Websocker/ws";
import {deleteUsersChats, updateReadMessagesCount, updateUnreadMessagesCount} from "../../Stores/slices/ChatListSlice";
import ChatLeftTextSvg from "../../Svg/ChatLeftTextSvg";
import EyeSvg from "../../Svg/EyeSvg";
import {SearchSvg} from "../../Svg";
import {setComplainLoading, setComplains} from "../../Stores/slices/ComplainsSlice";
import UserAds from "../UserAds/UserAds";
import CardTextSvg from "../../Svg/CardTextSvg";



function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

let chatProfile = null;

const ChatPage = (props) => {
    const query = useQuery();
    const [showError, setShowError] = useState(false);
    const [isChatNotification, openChatNotification] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [profileData, setProfileData] = useState({user: null, status: 0});
    const {pageIndex, tabIndex, chatSelectedUser} = useSelector(selectCommon);
    const {messageBody} = useSelector(selectChatMessages);
    const {alert} = useSelector(selectToast);
    const navigate = useNavigate();
    const dispatcher = useDispatch();

    //Получить userId из параметра запроса или из локального хранилища.
    const currentUserId = !(query.get(USER_ID_KEY)) ? getStoredValueByKey(USER_ID_KEY) : query.get(USER_ID_KEY);

    const handleChange = (event, newIndex) => {
        dispatcher(setTabIndex(newIndex))
    }


    useEffect(() => {
        openChatNotification(prevState => prevState = true)
    }, [messageBody]);

    function onCloseChatToast() {
        openChatNotification(false);
    }

    function onAlertClosed() {
        dispatcher(dropAlertToast());
    }

    useEffect(() => {
        chatProfile = chatSelectedUser;
    }, [chatSelectedUser])


    useEffect(() => {
        dispatcher(setComplainLoading(true))
        getComplains(currentUserId, 0, 10)
            .then(res => {
                dispatcher(setComplains(res?.data))
            })
            .catch(e => {
                console.error(e);
            })
            .finally(() => dispatcher(setComplainLoading(false)));
    }, [])

    /**
     * Обработчик ошибок при попытки получить профиль пользователя
     * @param {boolean} isDelete
     */
    function errorProfileHandler(isDelete= true) {
        //Удалить аккаунт пользователя только из сервиса авторизации
        if (isDelete) {
            dispatcher(deleteUserAccountAsync({
                userId: currentUserId,
                isAccountOnly: true
            }));
        }
        navigate(ROUTE_SIGNUP);
    }

    function initSearch(userProfile) {
        storeValue(KEY_INIT_SEARCH, 1)
    }

    //Реагирует однократно для userId
    useEffect(() => {
        if (currentUserId) {
            //Запросить данные профиля пользователя по userId
            userProfile(currentUserId)
                .then((res) => {
                    setProfileData({user: res?.data, status: res?.status});
                    storeValue('profile', JSON.stringify(res?.data));
                    let userProfile = res?.data;

                    dispatcher(setMyProfile(userProfile));
                    initSearch(userProfile);

                    if ((res?.status !== 200) && (res?.status !== 403)) {
                        errorProfileHandler(true);
                    } else if (+res?.status === 403) {
                        errorProfileHandler(false);
                    }
                })
                .catch((err) => {
                    errorProfileHandler(false);
                })
        } else {
            navigate(ROUTE_SIGNUP);
        }


        if (wsClient) {
            let token = localStorage.getItem(TOKEN_KEY);
            wsClient?.tryStompConnect(token, wsClient, currentUserId, (error) => {
                setErrMsg(error);
                setShowError(true);
                setTimeout(() => {setShowError(false)}, 5000);
            })
            //Получить подтверждение, что сообщение отправлено мной же или получено от другого пользователя
            wsClient.onMessageReceived = onReceiveMessage;
        }

        //dispatcher(setTabIndex(pageIndexQuery));

        return () => {
            wsClient?.disconnect();
            wsClient.onMessageReceived = null;
        }

    }, []);


    const onReceiveMessage = (data) => {
        let body = JSON.parse(data?.body);
        if (body?.type === MessageTypes.chat) { //Приход нового сообщения от пользователя
            //chatProfile - это выбранный пользователь
            if ((body) && (body?.content) && ((chatProfile?.id === body?.content?.fromUserId) || (chatProfile?.id === body?.content?.userId))) {
                //Если пользователь уже в чате с пользователем, то обновить сообщения чата
                dispatcher(setMessageList([body?.content]));
            } else if ((body) && (body?.content)) {
                //Если вы НЕ в чате с пользователем
                dispatcher(setMessageBody(body));
            }
            //Обновить историю чата согласно пришедшему сообщению
            dispatcher(updateUnreadMessagesCount({chatMessage: body, currentUserId: currentUserId}));
        } else if (body?.type === MessageTypes.delete) { //Сообщения об удалениях в переписке
            //Обновить выбранный чат после удаления сообщений (обновление состояния)
            dispatcher(deleteMessageListItems(body?.chatItems));
            //dispatcher(deleteBeforeMessageListItems(body?.chatItems))
        } else if (body?.type === MessageTypes.edit) { //Сообщения об изменениях в переписке (смена статуса сообщений или их содержимого)
            //Обновить сообщения выбранного чата (обновление состояния)
            //console.log("MessageTypes.edit: "+JSON.stringify(body?.chatItems))
            dispatcher(updateMessageList(body?.chatItems));
            dispatcher(updateBeforeMessageList(body?.chatItems));
            dispatcher(updateReadMessagesCount(body?.chatItems));
        } else if (body?.type === MessageTypes.rmChats) { //Сообщение об удалении историй переписки/чатов
            //Обновить список историй чатов после удаления
            dispatcher(deleteUsersChats(body?.chatUserIds));
            //После удаления выбранных чатов нужно сбросить последний выбранный чат.
            dispatcher(dropChatSelectedUser());
        }
    }


    return (
        <div className="main-background">
            {useMemo(() => <ResponsiveAppBar user={profileData?.user}/>, [profileData?.user])}

            {/*Чат и поиск профиля*/}
            {((pageIndex === 0) || (pageIndex < 0)) &&
              <div className="container main-panel mt-sm-0 mt-md-1 mt-lg-2 px-0">
                  <div className="justify-content-center flex-row m-0 py-0 px-2 border-bottom border-1 bg-white d-none d-sm-none d-md-flex d-lg-flex d-xl-flex d-xxl-flex">
                      <Tabs className="align-items-center p-0 m-0"
                            value={tabIndex}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons={false}
                            style={{height: "25px"}}
                      >
                          <Tab icon={<div className="mx-1"><ChatLeftTextSvg w={18} h={18}/></div>}
                               iconPosition={"start"}
                               sx={{fontWeight: 'bold'}}
                               label={<span className="fs-6_">{CAPTION_CHATS}</span>}
                               {...a11yProps(0)} />
                          <Tab icon={<div className="mx-1"><EyeSvg w={18} h={18}/></div>}
                               iconPosition={"start"}
                               sx={{fontWeight: 'bold'}}
                               label={<span className="fs-6_">{CAPTION_GUESTS}</span>}
                               {...a11yProps(1)} />
                          <Tab icon={<div className="mx-1"><SearchSvg w={18} h={18}/></div>}
                               iconPosition={"start"}
                               sx={{fontWeight: 'bold'}}
                               label={<span className="fs-6_">{CAPTION_SEARCH}</span>}
                               {...a11yProps(2)} />
                          <Tab icon={<div className="mx-1"><CardTextSvg w={18} h={18}/></div>}
                               iconPosition={"start"}
                               sx={{fontWeight: 'bold'}}
                               label={<span className="fs-6_">{CAPTION_ADS}</span>}
                               {...a11yProps(3)} />
                      </Tabs>
                  </div>

                  <TabItem value={tabIndex} index={0}>
                      <ChatView stomp={wsClient} userId={currentUserId} />
                  </TabItem>
                  <TabItem value={tabIndex} index={1} >
                      <GuestsView userId={currentUserId}/>
                  </TabItem>
                  <TabItem value={tabIndex} index={2} >
                      <SearchProfiles userId={currentUserId}/>
                  </TabItem>
                  <TabItem value={tabIndex} index={3} >
                      <UserAds profile={profileData?.user ?? JSON.parse(getStoredValueByKey('profile'))}/>
                  </TabItem>

                  <div className="justify-content-center  flex-row m-1 p-1 border-bottom border-1 bg-white d-sm-flex d-md-none d-lg-none d-xl-none d-xxl-none">
                    <Tabs className="d-flex align-items-center flex-row p-0"
                          value={tabIndex}
                          onChange={handleChange}
                          style={{height: "28px"}}
                    >
                        <Tab icon={<div className="m-1"><ChatLeftTextSvg w={18} h={18}/></div>}
                             iconPosition={"top"}
                             sx={{fontWeight: 'bold'}}
                             className="mx-2"
                             label={<span className="fs-6_">{CAPTION_CHATS}</span>}
                             {...a11yProps(0)} />
                        <Tab icon={<div className="m-1"><EyeSvg w={18} h={18}/></div>}
                             iconPosition={"top"}
                             sx={{fontWeight: 'bold'}}
                             label={<span className="fs-6_">{CAPTION_GUESTS}</span>}
                             {...a11yProps(1)} />
                        <Tab icon={<div className="m-1"><SearchSvg w={18} h={18}/></div>}
                             iconPosition={"top"}
                             sx={{fontWeight: 'bold'}}
                             label={<span className="fs-6_">{CAPTION_SEARCH}</span>}
                             {...a11yProps(2)} />
                        <Tab icon={<div className="m-1"><CardTextSvg w={18} h={18}/></div>}
                             iconPosition={"top"}
                             sx={{fontWeight: 'bold'}}
                             label={<span className="fs-6_">{CAPTION_ADS}</span>}
                             {...a11yProps(3)} />
                    </Tabs>
                  </div>
              </div>}

            {/*Детали профиля*/}
            {pageIndex === 1 &&
              <div className="container main-panel mt-2">
                 <ProfileDetail profile={profileData?.user} currentUserId={currentUserId}/>
              </div>}

            {errMsg !== '' && <AlertToast text={errMsg} open={showError} success={false}/>}

            <ChatToast open={isChatNotification} messageBody={messageBody} onClose={onCloseChatToast}/>
            <AlertToastFilled open={alert.open} text={alert.text} severity={alert.severity} onClose={onAlertClosed}/>
        </div>
    );
}


export default ChatPage;