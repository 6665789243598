import React from "react";

export default function BellFillSvg({onClick, w, h, color}) {
    return (
        <div onClick={onClick} className="m-0 p-0">
            <svg width={w || 25} height={h || 25} fill={color || 'black'}  viewBox="0 0 16 16">
                <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"/>
            </svg>
        </div>
    )
}