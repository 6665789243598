import axios from "axios";
import {EMAIL_KEY, getHeaderBearerConfigs, TOKEN_KEY} from "./ApiCommon";
import {getEnvOfStorage} from "../Stores/Env";


/**
 * Сохранить/изменить профиль пользователя
 * @param {Object} profile
 * @param {function(res: Object, err: any)} callback
 */
export function saveUserProfile(profile, callback) {
    let p = Object.assign({}, profile);
    p.email = localStorage.getItem(EMAIL_KEY)
    let token = localStorage.getItem(TOKEN_KEY);
    let promise = axios.post(`${getEnvOfStorage()?.dataUrl}/api/user/profile`, p, getHeaderBearerConfigs("application/json", token));
    promise.then((data) => callback(data, null)).catch(err => callback(null, err));
}



/**
 * Удалить профиль пользователя из чат-сервиса
 * @param {string} userId
 * @param {string} token
 * @returns {Promise<AxiosResponse<any>>}
 */
export function removeUserProfile(userId, token) {
    return axios.delete(`${getEnvOfStorage()?.dataUrl}/api/user/profile/${userId}`, getHeaderBearerConfigs("application/json", token))
}

/**
 * Удалить профиль пользователя полностью
 * @param {string} userId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function removeUser(userId) {
    let token = localStorage.getItem(TOKEN_KEY);
    return axios.delete(`${getEnvOfStorage()?.authUrl}/api/user?user_id=${userId}&account_only=false`, getHeaderBearerConfigs("application/json", token))
}

/**
 * Получить профиль пользователя по userId
 * @param {string} userId
 * @param {string} token
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getUserProfile(userId, token) {
    return axios.get(`${getEnvOfStorage()?.dataUrl}/api/user/profile/${userId}`, getHeaderBearerConfigs("application/json", token));
}

/**
 * Получить профиль пользователя по userId
 * @param {string} userId
 * @param {string} token
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getUserProfileView(userId, token) {
    return axios.get(`${getEnvOfStorage()?.dataUrl}/api/user/profile/view/${userId}`, getHeaderBearerConfigs("application/json", token));
}

/**
 * Получить профиль пользователя по userId
 * @param {string} userId
 * @returns {Promise<AxiosResponse<*>>}
 */
export async function userProfile(userId)  {
    let token = localStorage.getItem(TOKEN_KEY);
    return await getUserProfile(userId, token);
}

/**
 * Получить профиль пользователя по userId
 * @param {string} userId
 * @returns {Promise<AxiosResponse<*>>}
 */
export async function userProfileView(userId)  {
    let token = localStorage.getItem(TOKEN_KEY);
    return await getUserProfileView(userId, token);
}

/**
 * Запрос получения списка профилей постранично и по параметрам searchBody
 * @param {string} userId
 * @param {number} page
 * @param {number} size
 * @param {Object} searchBody
 * @returns {Promise<AxiosResponse<any>>}
 */
function searchUserProfilesRequest(userId, page, size, searchBody) {
    let token = localStorage.getItem(TOKEN_KEY);
    return axios.post(`${getEnvOfStorage()?.dataUrl}/api/user/profiles/${page}/${size}/${userId}`, searchBody, getHeaderBearerConfigs("application/json", token));
}

/**
 * Получить список жалоб на пользователя
 * @param {string} userId
 * @param {number} page
 * @param {number} size
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getComplains(userId, page, size) {
    let token = localStorage.getItem(TOKEN_KEY);
    return axios.get(`${getEnvOfStorage()?.dataUrl}/api/complains?page=${page}&size=${size}&user_id=${userId}`, getHeaderBearerConfigs("application/json", token));
}

/**
 * Обновить стататус прочтения жалобы
 * @param {any} data
 * @param {string} userId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function readComplains(data, userId) {
    let token = localStorage.getItem(TOKEN_KEY);
    return axios.post(`${getEnvOfStorage()?.dataUrl}/api/complains?user_id=${userId}`, data, getHeaderBearerConfigs("application/json", token));
}

/**
 * Получить список профилей постранично и по параметрам searchBody
 * @param {string} userId
 * @param {number} page
 * @param {number} size
 * @param {Object} searchBody
 * @param {function(data: any, err: any)} callback
 */
const searchUserProfiles = (userId, page, size, searchBody, callback) => {
    if ((userId) && (searchBody)) {
        searchUserProfilesRequest(userId, page | 0, size, searchBody)
            .then(data => callback(data, null))
            .catch(err => {callback(null, err); console.log("searchUserProfilesRequest Error: "+err)});
    }
}

/**
 * Поднятие анткеты пользователя
 * @param {string} userId
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function raiseUserProfile(userId) {
    let token = localStorage.getItem(TOKEN_KEY);
    let response = await axios.post(`${getEnvOfStorage()?.dataUrl}/api/user/profile/raise/${userId}`, {}, getHeaderBearerConfigs("application/json", token));
    return await response?.data;
}

/**
 * Получить блок зарегистрированных пользователей с иконками
 */
function getUsersChunksWithPreviews(size) {
    let url = getEnvOfStorage()?.dataUrl;
    if (!url) {
        return Promise.reject("Пустой url!")
    }
    return axios.get(`${url}/api/user/chunk/previews?size=${size}`);
}

export async function usersChunksWithPreviewsApi(size) {
    return await getUsersChunksWithPreviews(size);
}

export {
    searchUserProfiles,
}
