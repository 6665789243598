import React, {useEffect, useState} from "react";
import {Box, CircularProgress, Fab} from "@mui/material";
import {addHoursToDate, getExpirationTime} from "../../services/DateTimeService";

const PreloadButtonWithTimeSate = ({icon, loading, onClick, caption, lockHours, id}) => {
    const [disable, setDisable] = useState(false);

    const buttonSx = {
        ...(loading ? {
            bgColor: "secondary",
            '&:hover': {
                bgColor: "secondary.light",
            },
        } :
        {
            bgColor: 'primary',
                '&:hover': {
                    bgColor: "primary.light",
            }
        }
        )};

    function checkLockStatus() {
        setDisable((getExpirationTime(id) - new Date().getTime()) > 0);
    }

    function setLockTime(hours) {
        if ((getExpirationTime(id) - new Date().getTime()) <= 0) {
            localStorage.setItem(id, addHoursToDate(new Date(), hours));
        }
    }

    useEffect(() => {
        checkLockStatus();
    }, []);

    useEffect(() => {
        setLockTime(lockHours);
    }, [lockHours])

    function onHandle() {
        onClick();
        setDisable(true);
    }

    return (
        <div className="p-1 d-flex flex-row align-items-center">
            <Box sx={{ m: 1, position: 'relative' }}>
                <Fab
                    disabled={disable}
                    size="small"
                    aria-label="raise-profile"
                    color="primary"
                    sx={buttonSx}
                    onClick={onHandle}
                >
                    {icon}
                </Fab>
                {loading && (
                    <CircularProgress
                        size={48}
                        sx={{
                            color: '#6c34ef',
                            position: 'absolute',
                            top: -4,
                            left: -4,
                            zIndex: 1,
                        }}
                    />
                )}
            </Box>
            {caption && <span style={{color: "primary"}}>{caption}</span>}
        </div>
    );
}

export default PreloadButtonWithTimeSate;