import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getEnvOfStorage} from "../../Stores/Env";
import {invoiceStatuses} from "../../api/Payments";
import Loader from "../../Components/Loader/Loader";
import {Button, Chip, Divider} from "@mui/material";
import {
    CAPTION_ADD,
    CAPTION_EMPTY_OPTIONS, LABEL_ADS, LABEL_DAYS, LABEL_RUB,
    SUBTITLE_PAID_OPTIONS
} from "../../Constants/TextMessagesRu";
import {selectPaidOptions, updatePaidOptions} from "../../Stores/slices/PaidStatusSlice";
import IconChip, {iconChipStat} from "../../Components/Chips/IconChip";
import CheckCircleSvg from "../../Svg/CheckCircleSvg";
import ExclamationCircleSvg from "../../Svg/ExclamationCircleSvg";
import {KIWI, MASTER, MIR, VISA} from "../../../assets";
//import {appendYaAds} from "../../services/Yandex";

const PaidOptions = () => {
    const dispatch = useDispatch();
    const {options, loading} = useSelector(selectPaidOptions);

    useEffect(() => {
        dispatch(updatePaidOptions());
    }, []);

   /* useEffect(() => {
       if (!loading) {
           appendYaAds("yandex_rtb_R-A-2450444-6", "R-A-2450444-6");
           appendYaAds("yandex_rtb_R-A-2450444-7", "R-A-2450444-7");
           appendYaAds("yandex_rtb_R-A-2450444-8", "R-A-2450444-8");
       }
    }, [loading]);*/

    function onPaymentNavigate() {
        //alert("Платежи временно не принимаются!")
        window.location.href = `${getEnvOfStorage()?.payUrl}/pay/options`;
    }

    function getPayStampStatus(status) {
        if (!status) return '';
        switch (status) {
            case invoiceStatuses.paid:
                return 'paid-stamp';
            case invoiceStatuses.processing:
                return 'processing-stamp';
            case invoiceStatuses.wait:
                return 'waiting-stamp';
            default:
                return '';
        }
    }

    function getChipStatus(status) {
        if (!status) return '';
        switch (status) {
            case invoiceStatuses.paid:
                return iconChipStat.checked;
            case invoiceStatuses.processing:
                return iconChipStat.warn;
            case invoiceStatuses.wait:
                return iconChipStat.warn;
            default:
                return '';
        }
    }

    function getExpireDateOption(optItem) {
        let option = optItem?.payStatusOption;
        let status = option?.payStatus;
        if (!status) return '';
        if (invoiceStatuses.paid === status) {
            return `Истекает: ${option?.expirationDateTime}`;
        }
        return '';
    }

    if (loading) {
        return (
            <div className="d-flex flex-row justify-content-center align-items-center h-100">
                <Loader height={40}/>
            </div>
        );
    }

    return (
        <div className="d-block h-100 my-2 px-sm-1 px-md-2 px-lg-3 py-2 scroll-y position-relative">
            <span className="d-flex justify-content-center mb-3 h3" >{SUBTITLE_PAID_OPTIONS}</span>
            {options.map((optItem, index) =>
                <div key={index} className="mt-4 bg-white card p-2 border-dark">
                    <span className="fs-4_ text-primary d-flex justify-content-center border-bottom">{`${optItem?.caption}`}</span>
                    <div className="d-flex flex-row justify-content-around align-items-center flex-wrap mt-1 p-1">
                        <div style={{height: 245}} className={`position-relative paid-card ${getPayStampStatus(optItem?.payStatusOption?.payStatus)} d-flex flex-column justify-content-between mx-2 p-1`}>
                            <a href="#" className="py-2 px-1"><img src={optItem?.icon}/></a>
                            <Button disabled={optItem?.payStatusOption?.payStatus === invoiceStatuses.paid}
                                    variant={'contained'} className="mt-1"
                                    onClick={onPaymentNavigate}>{CAPTION_ADD}
                            </Button>
                        </div>
                        <div className="d-flex flex-column justify-content-center flex-grow-1 my-2 mx-sm-1 mx-md-2 mx-lg-2 w-75">
                            {optItem?.descriptions.map((descItem, index) =>
                                <span className="fs-5_ d-block my-2" key={index}>
                                   <div className="d-flex align-items-center">
                                        <CheckCircleSvg /><span className="px-2 fs-5_">{descItem}</span>
                                   </div>

                                </span>)}
                        </div>

                    </div>
                    <div className="d-flex align-items-center justify-content-between my-1 flex-wrap">

                        <div className="d-flex flex-row justify-content-end align-items-center my-2 flex-wrap">
                            <img className="pay-icons" src={VISA} alt="visa"/>
                            <img className="pay-icons" src={MIR} alt="mir"/>
                            <img className="pay-icons" src={MASTER} alt="master"/>
                            <img className="pay-icons" src={KIWI} alt="kiwi"/>
                        </div>

                        <div className="d-flex align-items-center justify-content-end my-1 flex-wrap">
                                {optItem?.options.map((option, index) =>
                                    <span key={index}>
                                       <IconChip caption={`${option?.price} ${LABEL_RUB} (${option?.expire_days} ${LABEL_DAYS})`}
                                          showIcon={true}
                                          chipStat={getChipStatus(option?.payStatus)}/>
                                    </span>)}
                        </div>
                    </div>
                    {/*<div className="d-flex align-content-center justify-content-center my-2">
                        <div className="ads-320-90" id={`yandex_rtb_R-A-2450444-${index+6}`}>{LABEL_ADS}</div>
                    </div>*/}
                    <Divider/>
                </div>
            )}
            {((options?.length === 0) && !loading) &&
            <div className="d-flex justify-content-center flex-row mt-2">
                <Chip icon={<div ><ExclamationCircleSvg color={"#f00"}/></div>}
                      label={CAPTION_EMPTY_OPTIONS.toUpperCase()}
                      sx={{color: "red", bgcolor: 'white'}} className="fw-bold"
                      variant={"outlined"}/>
            </div>}
            <div className="d-flex justify-content-center text-white w-100 fs-5_ bg-dark opacity-75 rounded my-2">
                <span className="mx-1 p-1 w-100 d-flex justify-content-end">
                    {'Контакты'.toUpperCase()}
                </span>
                <span className="mx-1 p-1 d-flex flex-column w-100 border-start border-dark">
                       <span className="px-1">ФИО: Матвеев Роман Александрович</span>
                       <span className="px-1">ИНН: 272507800520</span>
                    <span className="px-1">EMAIL: <a href="mailto:romanm87@mail.ru">romanm87@mail.ru</a></span>
                       <span className="px-1">ТЕЛ.: +7(999)0845364</span>
                </span>
            </div>
            {/*<div className="d-flex align-content-center justify-content-center my-2">
                <div className="ads-320-90" id={`yandex_rtb_R-A-2450444-${9}`}>{LABEL_ADS}</div>
            </div>*/}
        </div>
    );
}


export default PaidOptions;