const ROUTE_REGISTRATION = '/chat/registration';
const ROUTE_SIGNUP = '/chat/signup';
const ROUTE_OPTIONS = '/chat/options';
const ROUTE_HOME = '/chat';
const ROUTE_PROFILE = '/chat/profile';
const ROUTE_PROFILE_VIEW = ROUTE_PROFILE+'/view';
const ROUTE_RESET_PASSWORD = '/chat/reset/password';
const ROUTE_COMPLAINS = '/chat/complains';

export {
    ROUTE_HOME,
    ROUTE_REGISTRATION,
    ROUTE_SIGNUP,
    ROUTE_PROFILE,
    ROUTE_RESET_PASSWORD,
    ROUTE_OPTIONS,
    ROUTE_COMPLAINS,
    ROUTE_PROFILE_VIEW,
}