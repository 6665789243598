import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import {Provider} from "react-redux";
import store from "./app/Stores/store";
import {BrowserRouter} from "react-router-dom";
import App from "./App";

ReactDOM.render(
      <>
          <Provider store={store}>
                  <BrowserRouter>
                      <App/>
                  </BrowserRouter>
          </Provider>
      </>,
  document.getElementById('root')
);


reportWebVitals();
