import React from "react";
import {Divider} from "@mui/material";

/**
 * Компонент-виджет с описанием слева
 * @param {string} title
 * @param {string} subTitle
 * @param {boolean} hiddenTitle
 * @param {string} description
 * @param component
 * @param icon
 * @returns {JSX.Element}
 * @constructor
 */
const OptionItem = ({title, subTitle,  hiddenTitle, description, component, icon}) => {
    return (
            <div className="d-flex flex-column justify-content-center px-2 my-2">
                {!hiddenTitle &&
                <div>
                    <div className="d-flex flex-row align-content-center">
                        {icon}
                        <span className="fs-5_ fw-bold mx-2">{title}</span>
                    </div>
                    <Divider/>
                </div>}

                <div className="d-flex flex-row justify-content-between align-items-center mt-2 flex-wrap">
                    <div className="d-flex flex-column align-items-start flex-grow-1 justify-content-center">
                        <p style={{color: "#323131"}} className="fs-5_ text-wrap">
                            {description}
                        </p>
                        {subTitle}
                    </div>
                    <div className="d-flex flex-row align-items-center  justify-content-center">
                        {component}
                    </div>
                </div>
            </div>
    );
}

export default OptionItem;