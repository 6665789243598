import {Avatar} from "@mui/material";
import React from "react";
import {getEnvOfStorage, KEY_THUMB_URL} from "../../Stores/Env";
import {getRandomDigitString} from "../../services/DataService";

const AvatarItemV2 = ({profile, hasBorder}) => {
    const altVal =`${profile?.firstName} ${profile?.lastName}`;
    let url = profile?.thumbUrl?.src;
    if (url) {
        localStorage.setItem(KEY_THUMB_URL, getEnvOfStorage()?.staticUrl+url+`?${getRandomDigitString()}`)
    }

    return (
        <>
            {(localStorage.getItem(KEY_THUMB_URL) || '') ?
                <Avatar sx={{backgroundColor: '#25a304', ...hasBorder}} alt={altVal} src={localStorage.getItem(KEY_THUMB_URL)}/> :
                <Avatar sx={{backgroundColor: '#25a304', ...hasBorder}} alt={altVal}>{altVal[0] ?? null}</Avatar>}
        </>
    );
};


export default AvatarItemV2;