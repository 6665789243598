import {
    CAPTION_CONFIRM_YOUR_PASSWORD,
    CAPTION_YOUR_LOGIN,
    CAPTION_YOUR_PASSWORD,
    MSG_EMPTY_PASSWORD,
    MSG_INVALID_LOGIN_FORMAT,
    MSG_NOT_EQUALS_PASSWORD,
    SUBTITLE_YOUR_LOGIN,
    SUBTITLE_YOUR_PASSWORD
} from "../../Constants/TextMessagesRu";
import React, {useEffect, useState} from "react";
import {validateEmailFormat} from "../../services/Validation";


const Credentials = ({onCredentials, confirmHidden}) => {
    const [credentials, setCredentials] = useState({
        login: {valid: true, content: ''},
        password: {valid: true, content: ''},
        confirm: {valid: false, content: ''},
        confirmHidden: confirmHidden,
    });


    useEffect(() => {
        onCredentials(credentials);
    }, [credentials]);

    return (
        <div className="mt-1 d-flex flex-column">
            <label hidden={credentials.login.valid}
                   className="input-label mt-1 text-danger fs-5_">{MSG_INVALID_LOGIN_FORMAT}</label>
            <label className="input-label fs-5_">{SUBTITLE_YOUR_LOGIN}</label>
            <input className="input_field fs-5_" placeholder={CAPTION_YOUR_LOGIN}
                   type="email" required
                   onChange={(e) => {
                       setCredentials(prevState => ({...prevState, login: {content: e.target.value, valid: validateEmailFormat(e.target.value)}}));
                   }}/>


            <label hidden={credentials.password.valid}
                   className="input-label mt-1 text-danger fs-5_">{MSG_EMPTY_PASSWORD}</label>
            <label className="input-label mt-1 fs-5_">{SUBTITLE_YOUR_PASSWORD}</label>
            <input className="input_field fs-5_" placeholder={CAPTION_YOUR_PASSWORD}
                   type="password" required
                   onChange={(e) => {
                       setCredentials(prevState => ({...prevState, password: {content: e.target.value, valid: (e.target.value)?.length > 0}}))

                       if (e.target.value !== credentials.confirm.content) {
                           setCredentials(prevState => ({...prevState, confirm: {valid: false, content: credentials.confirm.content}}))
                       }
                   }}/>

            {!credentials.confirmHidden &&
            <>
                <label hidden={credentials.confirm.valid}
                       className="input-label mt-1 text-danger fs-5_">{MSG_NOT_EQUALS_PASSWORD}</label>
                <label className="input-label mt-1 fs-5_">{CAPTION_CONFIRM_YOUR_PASSWORD}</label>
                <input className="input_field fs-5_" placeholder={CAPTION_CONFIRM_YOUR_PASSWORD}
                       type="password" required
                       onChange={(e) => {
                           setCredentials(prevState => ({...prevState, confirm: {content: e.target.value, valid: (e.target.value) === credentials.password.content}}))
                       }}/>
            </>}

        </div>
    );
}

export default Credentials;