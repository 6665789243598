import React, {useCallback, useEffect, useRef, useState} from "react";
import {
    Divider,
} from "@mui/material";
import {FixedSizeList} from "react-window";
import useWindowDimensions from "../../Hooks/useWindowDimension";
import ChatProfileItem from "../ListItems/ChatProfileItem";
import {arrayEquals} from "../../services/DataService";

/**
 * Компонент список с автоподгрузкой на базе виртуального списка (react-window)
 * @param {Object[]} content
 * @param onNextPage
 * @param onSelected
 * @param {boolean} isEmptyData
 * @param {number} page
 * @param incPage
 * @returns {JSX.Element}
 * @constructor
 */
function VirtualList ({content, onNextPage, onSelected, isEmptyData, page, incPage}) {
    const boxRef = useRef(null);
    const ITEM_SIZE = 62;
    const [listHeight, setListHeight] = useState(0);
    const listRef = useRef();
    const {width, height} = useWindowDimensions();

    useEffect(() => {
        setListHeight(boxRef.current?.clientHeight);
    }, [height, width]);


    useEffect(() => {
        setListHeight(boxRef.current?.clientHeight);
    }, []);

    function onScroll(e) {
        if ((listRef?.current?.scrollTop + listRef?.current?.clientHeight) >= listRef?.current?.scrollHeight) {
            if (!isEmptyData) {
                incPage();
            }
        }
    }

    useEffect(() => {
        if (page > 0) {
            onNextPage(page);
        }
    }, [page])

    return (
        <div className="h-100" ref={boxRef}>
            <FixedSizeList
                onScroll={onScroll}
                outerRef={listRef}
                height={listHeight}
                width={'100%'}
                itemSize={ITEM_SIZE}
                itemCount={content.length}
                overscanCount={5}>
                {useCallback((props) => {
                    const { index, style } = props;
                    return (
                        <div key={index}
                             style={{...style, display: 'flex', flexDirection: 'column'}}>
                             <ChatProfileItem itemData={content[index]} onSelectItem={onSelected} index={index}/>
                             <Divider style={{padding: 0}}/>
                        </div>
                    );
                }, [content])}
            </FixedSizeList>
        </div>
    );
}


export default VirtualList;
