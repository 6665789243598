import React, {useState} from 'react';
import MessageSendView from '../../Chat/MessageSendView/MessageSendView';
import MessageView from '../../Chat/MessageView/MessageView';
import TopHeaderView from '../../Chat/TopHeaderView/TopHeaderView';
import UserList from "../../Chat/Users/UserList/UserList";
import {Grid} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {
    fullDropUsersChats,
    selectUsersChats,
    setUsersChats,
    updateUnreadMessagesCount
} from "../../Stores/slices/ChatListSlice";
import {selectCommon} from "../../Stores/slices/CommonSlice";
import {getFakeMessageBody} from "../../services/DataService";
const HIDE_BLOCK = { xs: 'none', sm: 'none', md: 'block' };
const HIDE_MD_BLOCK = { xs: 'flex', sm: 'flex', md: 'none' };
const SHOW_BLOCK = { xs: 'block', sm: 'block', md: 'block' };

let initialDisplayState = {
    chats: {
        value: SHOW_BLOCK
    },
    chatView: {
        value: HIDE_BLOCK
    }
};

function ChatView ({userId, stomp}) {
    //Переключение между списком пользователей и чатом с ними
    const [display, setDisplay] = useState(initialDisplayState);
    const dispatch = useDispatch()
    //const {chatSelectedUser} = useSelector(selectCommon);
    const {chats} = useSelector(selectUsersChats);


    //Показать чат, скрыть список профилей чатов (история чатов)
    const onSelectedUser = (userId) => {
        setDisplay(prevState => ({...prevState, chatView: {value: SHOW_BLOCK}, chats: {value: HIDE_BLOCK}}));
    }

    //Показать список профилей (исорию чатов), скрыть чат
    const onBackToChats = (e) => {
        //dispatch(setUpChats());
        setDisplay(prevState => ({...prevState, chatView: {value: HIDE_BLOCK}, chats: {value: SHOW_BLOCK}}));
        //Принудительно обновление состояние chats
        //dispatch(updateUnreadMessagesCount({chatMessage: getFakeMessageBody(chatSelectedUser, userId), currentUserId: userId}));
        const buffChats = chats;
        dispatch(fullDropUsersChats());
        setTimeout(() => dispatch(setUsersChats(buffChats)))
    }

    return (
      <div className="d-flex flex-row justify-content-center h-100 mx-0">
              <Grid container spacing={0} className="p-0">
                  <Grid item xs={12} sm={12} md={4} sx={{ display:  display.chats.value}} className="h-100">
                      <div className="users h-100 p-0">
                           <UserList stomp={stomp} currentUserId={userId} onSelected={onSelectedUser}/>
                      </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} sx={{ display:  display.chatView.value}} className="h-100">
                      <div  className="d-flex flex-column chat p-sm-1 p-md-0 p-lg-2 h-100">
                          <TopHeaderView onBack={onBackToChats} navButtonSx={HIDE_MD_BLOCK}/>
                          <MessageView stomp={stomp} currentUserId={userId} />
                          <MessageSendView stomp={stomp} currentUserId={userId}/>
                      </div>
                  </Grid>
              </Grid>
      </div>
    );
}

export default React.memo(ChatView);
