import React, {useEffect, useState} from "react";
import {EMAIL_KEY, USER_ID_KEY} from "../../api/ApiCommon";
import {useLocation, useNavigate} from "react-router-dom";
import ProfileEditableForm from "../Forms/Profile/ProfileEditableForm";
import {Box, Button, Container} from "@mui/material";
import StandardHeader from "../../Components/Header/StandardHeader";
import {ROUTE_HOME, ROUTE_SIGNUP} from "../../Constants/Routes";
import {Helmet} from "react-helmet";
import {saveUserProfile, userProfile} from "../../api/ChatDataApi";
import {useDispatch, useSelector} from "react-redux";
import {selectFilesChange} from "../../Stores/slices/LoadFilesSlice";
import useWindowDimensions, {D_LG, D_MD, D_SM, D_XL, D_XS} from "../../Hooks/useWindowDimension";
import {selectProfile, setEditField} from "../../Stores/slices/UserProfileSlices";
import {setAlertToast} from "../../Stores/slices/ToastSlice";
import {MSG_PARAMS_SAVED} from "../../Constants/TextMessagesRu";
import Loader from "../../Components/Loader/Loader";
import SaveSvg from "../../Svg/SaveSvg";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


/**
 * Страница главного профиля
 * @param viewOnly
 * @returns {JSX.Element}
 * @constructor
 */
const MyProfile = () => {
    const query = useQuery();
    const navigate = useNavigate();
    const [profileData, setProfileData] = useState({user: null, status: 0});
    const [loading, setLoading] = useState(false);
    const {fileListChanged} = useSelector(selectFilesChange);
    const {dimType} = useWindowDimensions();
    const [saveProcess, setSaveProcess] = useState(false);
    const {editedProfile} = useSelector(selectProfile);
    const dispatch = useDispatch();

    //Получить userId из параметра запроса или из локального хранилища.
    const currentUserId = !(query.get(USER_ID_KEY)) ? localStorage.getItem(USER_ID_KEY) : query.get(USER_ID_KEY);

    useEffect(() => {
        if ((fileListChanged) && (fileListChanged > 0)) {
            getUserProfile(currentUserId);
        }
    }, [fileListChanged]);


    useEffect(() => {
        if (currentUserId) {
            getUserProfile(currentUserId);
        } else {
            navigate(ROUTE_SIGNUP);
        }
    }, []);

    function onBack() {
        navigate(ROUTE_HOME);
    }

    function getUserProfile(userId) {
        setLoading(true);
        //Запросить данные профиля пользователя по userId
        userProfile(userId)
            .then((res) => {
                setProfileData({user: res?.data, status: res?.status});
                if (res?.status !== 200) {
                    navigate(ROUTE_SIGNUP);
                }
            })
            .catch((err) => {
                navigate(ROUTE_SIGNUP);
            }).finally(() => { setLoading(false)});
    }

    function onProfileSave() {
        setSaveProcess(true);
        saveUserProfile(editedProfile, (data, err) => {
            if (!err) {
                dispatch(setEditField(true));
                dispatch(setEditField(false));
                dispatch(setAlertToast({open: true, severity: 'success', text: MSG_PARAMS_SAVED}));
            }
            setSaveProcess(false);
        });
    }

    return(
        <div className="main-background">
            <Helmet>
                <title>Мой профиль</title>
            </Helmet>
            <div className="d-flex flex-column m-2 scroll-none">
                <Container className="my-profile my-profile-shadow d-flex flex-column">
                    <StandardHeader onClick={onBack} hideOptions={false}/>
                    {loading &&
                    <div className="d-flex flex-grow-1 flex-row justify-content-center align-items-center">
                        <Loader height={40}/>
                    </div>}
                    {!loading &&
                    <>
                        <div className="d-flex scroll-y my-2">
                            {((profileData?.user) && !loading) &&
                            <ProfileEditableForm
                                profile={profileData?.user}
                                currentUserId={currentUserId}
                                isEdit={currentUserId !== null}/>}
                        </div>
                        {((dimType === D_LG) || (dimType === D_XL) || (dimType === D_MD)) &&
                        <Box className="p-2 d-flex flex-row justify-content-end align-items-center"  >
                            <Button disabled={saveProcess} onClick={onProfileSave} className="py-0 px-3" variant={"contained"} endIcon={<SaveSvg color={"#fff"} w={20} h={20} />}>{"Сохранить"}</Button>
                        </Box>}
                        {((dimType === D_SM) || (dimType === D_XS)) &&
                        <Box className="p-2 d-flex flex-row justify-content-end align-items-center"  >
                            <Button disabled={saveProcess} onClick={onProfileSave} className="flex-grow-1 p-0" variant={"contained"} endIcon={<SaveSvg color={"#fff"} w={20} h={20}/>}>{"Сохранить"}</Button>
                        </Box>}
                    </>}

                </Container>
            </div>
        </div>
    );
}

export default MyProfile;