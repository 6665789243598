export class ChatMessage {
    type = '';
    /**
     * @type {ChatItem}
     */
    content = null;
    routerKey = '';
    sender = '';
    dateTime = null;
    /**
     * @type {UserProfile}
     */
    fromUser = null;

    /**
     *
     * @param type
     * @param sender
     * @param {ChatItem} content
     * @param routerKey
     * @param {UserProfile} fromUser
     */
    constructor(type, sender, content, routerKey, fromUser) {
      this.type = type;
      this.content = content;
      this.sender = sender;
      this.routerKey = routerKey;
      this.dateTime = new Date();
      this.fromUser = fromUser;
    }
}