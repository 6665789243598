import React, {useEffect, useRef} from "react";

let substrateWidth = 24;

/**
 * Компонент-подложка (круглая) для иконки
 * @param width
 * @param color
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const RoundSubstrate = ({color, children}) => {
    const substrateRef = useRef();

    return (
        <div ref={substrateRef} style={{ backgroundColor: color}}
             className="flex-row p-1 justify-content-center rounded-circle align-items-center">
            {children}
        </div>
    );
}

export default RoundSubstrate;