import {createSlice} from "@reduxjs/toolkit";
import {LOCATION_TYPES} from "../../Constants/TextMessagesRu";

export const searchBoxSlice = createSlice({
    name: 'searchBox',
    initialState: {
        heightCheck: false,
        weightCheck: false,
        ageCheck: true,
        kidsCheck: false,
        sexOrientationCheck: false,
        sexCheck: false,
        familyCheck: false,
        searchedData: [],
        onlineCheck: false,
        defLocationType: LOCATION_TYPES[0]?.value,
        searchParams: {
            firstName: "",
            lastName: "",
            heightFrom: null,
            heightTo: null,
            weightFrom: null,
            weightTo: null,
            hobby: null,
            online: null,
            kids: null,
            ageFrom: 16,
            ageTo: 100,
            sexOrientation: null,
            meetPreferences: null,
            sex: null,
            familyStatus: null,
            country: "",
            region: "",
            locality: "",
            disabled: false,
        }
    },
    reducers: {
        setAgeCheck: (state, action) => {
            state.ageCheck = action.payload;
        },
        setKidsCheck: (state, action) => {
            state.kidsCheck = action.payload;
        },
        setSexOrientationCheck: (state, action) => {
            state.sexOrientationCheck = action.payload;
        },
        setSexCheck: (state, action) => {
            state.sexCheck = action.payload;
        },
        setDefLocationType: (state, action) => {
            state.defLocationType = action.payload;
        },
        setFamilyCheck: (state, action) => {
            state.familyCheck = action.payload;
        },
        setSearchedData: (state, action) => {
            state.searchedData = action.payload;
        },
        setSearchParams: (state, action) => {
            state.searchParams = {...state.searchParams, ...action.payload};
        },
        setHeightCheck: (state, action) => {
            state.heightCheck = action.payload;
        },
        setWeightCheck: (state, action) => {
            state.weightCheck = action.payload;
        },
        setOnlineCheck: (state, action) => {
            state.onlineCheck = action.payload;
        },
    },
})

export const selectSearchBox = (state) => state.searchBox;
export const {setAgeCheck, setKidsCheck, setDefLocationType, setSexOrientationCheck, setSexCheck, setFamilyCheck, setSearchedData, setSearchParams, setHeightCheck, setWeightCheck, setOnlineCheck} = searchBoxSlice.actions;
export default searchBoxSlice.reducer;