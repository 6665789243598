import {createSlice} from "@reduxjs/toolkit";
import {payOptionsArray, switchIfPaidOptions} from "../../api/Payments";

export const paidStatusSlice = createSlice({
    name: 'paidOptions',
    initialState: {
        options: [],
        updateOptionsCount: 0,
        loading: true,
        payTags: {
            invisibleGuest: false,
            deleteChats: false,
            deleteLastMessage: false,
            upForm: false,
            changeAge: false,
            extendedSearch: false,
            changeLocation: false,
        }
    },
    reducers: {
        setPaidOptions: (state, action) => {
            state.options = action.payload;
            state.loading = false;

            state.payTags.invisibleGuest = switchIfPaidOptions(state.options, payOptionsArray.visibility.invisibleGuest);
            state.payTags.deleteChats = switchIfPaidOptions(state.options, payOptionsArray.chatsControl.deleteChats);
            state.payTags.deleteLastMessage = switchIfPaidOptions(state.options, payOptionsArray.chatsControl.deleteLastMessage);
            state.payTags.upForm = switchIfPaidOptions(state.options, payOptionsArray.accountForm.upForm);
            state.payTags.changeAge = switchIfPaidOptions(state.options, payOptionsArray.accountForm.changeAge);
            state.payTags.extendedSearch = switchIfPaidOptions(state.options, payOptionsArray.accountForm.extendedSearch);
            state.payTags.changeLocation = switchIfPaidOptions(state.options, payOptionsArray.accountForm.changeLocation);
        },
        updatePaidOptions: state => {
            state.loading = true;
            state.updateOptionsCount += 1;
        },
        stopLoading: (state) => {
            state.loading = false;
        },
    },
});

export const selectPaidOptions = (state) => state.paidOptions;
export const {setPaidOptions, updatePaidOptions, stopLoading} = paidStatusSlice.actions;
export default paidStatusSlice.reducer;