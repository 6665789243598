import React, {useEffect, useState} from "react";
import {Alert, Snackbar} from "@mui/material";

export function AlertToastFilled({open, text, severity, onClose}) {
    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
        setOpen(open);
    }, [open]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        onClose();
        setOpen(false);
    }

    return(
        <div>
            <Snackbar open={isOpen} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} autoHideDuration={1500} onClose={handleClose}>
                <Alert onClose={handleClose}  severity={severity} sx={{ width: '100%' }}>
                    {text}
                </Alert>
            </Snackbar>
        </div>
    );
}