import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "@mui/material";
import {
    CAPTION_FORGET_PASSWORD,
    CAPTION_REGISTRATION,
    CAPTION_SIGN_IN,
    META_DESCRIPTIONS_SIGN, META_KEYWORDS_SIGN,
    META_TITLE_SIGN,
    MSG_INVALID_CREDENTIALS,
    MSG_USER_NOT_FOUND,
    SOMETHING_WENT_WRONG,
} from "../../../Constants/TextMessagesRu";
import Loader from "../../../Components/Loader/Loader";
import {EMAIL_KEY, networkErrStatus, TOKEN_KEY, USER_ID_KEY} from "../../../api/ApiCommon";
import {ROUTE_REGISTRATION, ROUTE_RESET_PASSWORD} from "../../../Constants/Routes";
import {authenticateUser} from "../../../api/AuthApi";
import RoundSubstrate from "../../../Svg/Sunstrate/RoundSubstrate";
import SimpleCaptcha from "../../../Components/Recaptchas/SimpleCaptcha";
import Credentials from "../../../Components/Forms/Credentials";
import {useDispatch, useSelector} from "react-redux";
import {dropAlertToast, selectToast, setAlertToast} from "../../../Stores/slices/ToastSlice";
import {AlertToastFilled} from "../../../Components/Toasts/AlertToastFilled";
import {IMG_LOGO} from "../../../../assets";
import {getEnvOfStorage, setCookies, storeValue} from "../../../Stores/Env";
import LockFillSvg from "../../../Svg/LockFillSvg";
import {Helmet} from "react-helmet";
import ProfilePreviews from "../../../Components/Presentations/ProfilesPreviews";


export default function SignIn()  {
    const [credentials, setCredentials] = useState({login: '', password: '', valid: false});
    const [isValidCaptcha, setValidCaptcha] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const dispatch = useDispatch();
    const {alert} = useSelector(selectToast);

    const [auth, setAuth] = useState({
        response: null,
        status: 404,
        loading: false,
    })
    const navigator = useNavigate();

    function onRightCaptcha(flag) {
        setValidCaptcha(flag);
    }

    function onCredentials(data) {
        setCredentials(prevState => ({
            ...prevState,
            password: data?.password?.content,
            login: data?.login?.content,
            valid: (data?.login?.valid && data?.password?.valid)
        }));
    }

    useEffect(() => {
        setDisabled(!credentials.valid || !isValidCaptcha);
    }, [credentials, isValidCaptcha])

    /**
     * Сохранение JWT токена, переход на домашнюю страницу при успехе
     * @param {any} response
     */
    const handleAuth = (response) => {
        if ((+response?.status === 200) && (response?.data?.token)) {
            localStorage.setItem(USER_ID_KEY, response?.data?.userId);
            //Обновить токен авторизации
            localStorage.setItem(TOKEN_KEY, response?.data?.token);
            //Запомнить email
            localStorage.setItem(EMAIL_KEY, credentials.login);

            setCookies(response?.data?.userId, response?.data?.token)
            //Перейти на главную страницу
            navigator(`/?userId=${response?.data?.userId}`);
        }
    }

    function onAlertClosed() {
        dispatch(dropAlertToast());
    }

    function SignInClick() {
        //Попытка войти в приложение
        setAuth(prevState => ({...prevState, loading: true}));
        authenticateUser({
            username: credentials.login,
            password: credentials.password
        }).then((res) => {
            setAuth({
                response: res?.data,
                loading: false,
                status: res?.status
            });
            handleAuth(res);
        }).catch(err => {
            setAuth(prevState => ({...prevState, status: networkErrStatus(err)}));
            setAuth(prevState => ({...prevState, loading: false}));

            if (networkErrStatus(err) === 404) {
                navigator(ROUTE_REGISTRATION);
                dispatch(setAlertToast({open: true, severity: 'warning', text: MSG_USER_NOT_FOUND}));
                return;
            }

            if (networkErrStatus(err) === 403) {
                dispatch(setAlertToast({open: true, severity: 'warning', text: MSG_INVALID_CREDENTIALS}));
                return;
            }
            dispatch(setAlertToast({open: true, severity: 'warning', text: SOMETHING_WENT_WRONG}));
        });
    }

    if (auth.loading)
        return (
        <div className="p-2 d-flex justify-content-center ">
            <div className="d-flex flex-column preloader-panel">
               <Loader height={40}/>
            </div>
        </div>
    );

    return (
        <div className="sign-background">
            <Helmet>
                <title>{META_TITLE_SIGN}</title>
                <meta name="description" content={META_DESCRIPTIONS_SIGN} />
                <meta name="keywords" content={META_KEYWORDS_SIGN} />
            </Helmet>
            <div className="p-2 d-flex flex-row align-items-center justify-content-center from-panel flex-wrap">
                <div className="d-flex flex-column primary-form-rel mx-3 ">
                    <a href={getEnvOfStorage()?.homeSiteUrl} className="w-100 d-flex justify-content-center">
                        <img src={IMG_LOGO} style={{height: 50}}/>
                    </a>
                    <div className="d-flex flex-row align-items-center justify-content-center">
                        <RoundSubstrate color="orange" children={<LockFillSvg w={25} h={25}/>} />
                        <h1 className="mt-2 mx-1 fs-2_">{CAPTION_SIGN_IN}</h1>
                    </div>
                    <Credentials onCredentials={onCredentials} confirmHidden={true}/>
                    <SimpleCaptcha onEqual={onRightCaptcha}/>
                    <Button disabled={disabled} variant={'contained'} className="mt-3" onClick={SignInClick}>Войти</Button>

                    <div className="mt-3 d-flex justify-content-center fs-5_"><a className="link" href={ROUTE_RESET_PASSWORD}>{CAPTION_FORGET_PASSWORD.toUpperCase()}</a></div>
                    <div className="mt-3 d-flex justify-content-center fs-5_"><a className="link" href={ROUTE_REGISTRATION}>{CAPTION_REGISTRATION.toUpperCase()}</a></div>

                </div>

                 <div className="p-3 mx-3 my-3 d-flex flex-column description-form d-none d-md-flex bg-opacity-75 rounded bg-dark border border-white">
                        <h1 className="text-center text-white fs-2_">Чат знакомств <span className="text-secondary_">Strelka</span>. Вход</h1>
                        <p className="text-justify fs-6 text-white">Введите свой почтовый ящик и пароль для входа в чат знакомств Strelka. Если вы еще не зарегистрировались на сайте, то
                            пройдите процедуру регистрации.</p>
                     <div>
                         <h1 className="text-center text-white fs-2_">Знакомьтесь у нас!</h1>
                         <ProfilePreviews />
                     </div>
                 </div>


            </div>
            <AlertToastFilled open={alert.open} text={alert.text} severity={alert.severity} onClose={onAlertClosed}/>
        </div>

    );
}