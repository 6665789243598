


export class EditMessage {
    /** @type {string} */
    type = "";
    /** @type {ChatItem[]} */
    chatItems = [];
    /** @type {string} */
    fromUserId = "";
    /** @type {string} */
    toUserId = "";
    /** @type {string} */
    dateTime = "";

    /**
     *
     * @param {string} type
     * @param {ChatItem[]} chatItems
     * @param {string} fromUserId
     * @param {string} toUserId
     */
    constructor(type, chatItems, fromUserId, toUserId) {
        this.type = type;
        this.chatItems = chatItems;
        this.dateTime = new Date().toISOString();
        this.fromUserId = fromUserId;
        this.toUserId = toUserId;
    }
}