import {createSlice} from "@reduxjs/toolkit";
import {CHAT_SELECTED_USER, getStoredValueByKey, storeValue} from "../Env";

export const commonSlice = createSlice({
    name: 'common',
    initialState: {
        value: 10,
        selectedUser: null,
        pageIndex: 0,
        chatSelectedUser: null,
        myProfile: null,
        tabIndex: 0,
        emojiMenu: false,
    },
    reducers: {
        increment: (state) => {
            state.value += 1
        },
        setDefaultValue: (state, action) => {
            state.value = action.payload;
        },
        defineSelectedUser: (state, action) => {
            state.selectedUser = action.payload;
           // storeValue(CHAT_SELECTED_USER, action.payload);
        },
        setPageIndex: (state, action) => {
            state.pageIndex = action.payload;
        },
        setChatSelectedUser: (state, action) => {
            //storeValue(CHAT_SELECTED_USER, action.payload);
            state.chatSelectedUser = action.payload;
        },
        setMyProfile: (state, action) => {
            state.myProfile = action.payload;
        },
        dropChatSelectedUser: state => {
            state.chatSelectedUser = null;
           // storeValue(CHAT_SELECTED_USER, null);
        },
        dropCommonStates: state => {
            state.selectedUser = null;
            storeValue(CHAT_SELECTED_USER, null);
            state.pageIndex = 0;
            state.selectedUser = {};
            state.myProfile = null;
            state.chatSelectedUser = null;
        },
        setTabIndex: (state, action) => {
            state.tabIndex = action.payload;
        },
        setEmojiMenu: (state, action) => {
            state.emojiMenu = action.payload;
        }
    },
});

export const selectCommon = (state) => state.common;
export const {
    setEmojiMenu,
    dropChatSelectedUser,
    dropCommonStates,
    increment,
    setDefaultValue,
    defineSelectedUser,
    setPageIndex,
    setChatSelectedUser,
    setTabIndex,
    setMyProfile} = commonSlice.actions;
export default commonSlice.reducer;