import React, {useEffect, useState} from "react";
import StandardHeader from "../../Components/Header/StandardHeader";
import {useDispatch, useSelector} from "react-redux";
import {selectCommon, setChatSelectedUser, setPageIndex, setTabIndex} from "../../Stores/slices/CommonSlice";
import ProfileEditableForm from "../Forms/Profile/ProfileEditableForm";
import {Box, Button} from "@mui/material";
import useWindowDimensions, {D_LG, D_MD, D_SM, D_XL, D_XS} from "../../Hooks/useWindowDimension";
import {addChatMessageAsync} from "../../api/ChatApi";
import {
    CAPTION_COMPLAIN,
    CAPTION_WRITE, MSG_PARAMS_SAVED,
    supportForms
} from "../../Constants/TextMessagesRu";
import {updateUserVisitor} from "../../api/VisitorApi";
import {selectPaidOptions} from "../../Stores/slices/PaidStatusSlice";
import SupportFormModal from "../../Components/Forms/SupportFormModal";
import Send from "../../Svg/Send";
import SadEmojiSvg from "../../Svg/SadEmojiSvg";
import {setAlertToast} from "../../Stores/slices/ToastSlice";


const ProfileDetail = ({currentUserId}) => {
    const commonDispatch = useDispatch();
    const {selectedUser} = useSelector(selectCommon);
    const {dimType} = useWindowDimensions();
    const dispatch = useDispatch();
    const {payTags} = useSelector(selectPaidOptions);
    const [complain, setComplain] = useState(false);

    function onBack() {
        commonDispatch(setPageIndex(0));
    }

    useEffect(() => {
        if (payTags?.invisibleGuest) return;

        let tm = null;
        if (selectedUser?.id && currentUserId) {
            tm = setTimeout(() => {
                updateUserVisitor(selectedUser?.id, currentUserId, (data, err) => {})
            }, 3000);
        }

        return () => {
            if (tm) {
                clearTimeout(tm);
            }
        }
    }, [])


    function onCreateChat() {
        //Отправить пустое сообщение, чтобы добавить новую историю чата для текущего пользователя
        addChatMessageAsync({
            userId: selectedUser?.id,
            fromUserId: currentUserId,
            message: "",
        }).then(res => {
            dispatch(setChatSelectedUser(selectedUser));
            dispatch(setTabIndex(0));
            dispatch(setPageIndex(0));
        }).catch(err => console.error(err));
    }

    function onComplain() {
        setComplain(true)
    }
     function closeComplain(data) {
        setComplain(false);
        if (!data?.error) {
            dispatch(setAlertToast({open: true, severity: 'success', text: data?.message}));
        }
        dispatch(setAlertToast({open: true, severity: 'warning', text: data?.error}));
     }

    return (
        <div className="d-flex flex-column h-100 my-profile">
            <StandardHeader onClick={onBack} hideOptions={false}/>
            <div className="d-flex flex-grow-3 scroll-y my-2">
                <ProfileEditableForm profile={selectedUser} currentUserId={currentUserId} isEdit={false}/>
            </div>
            {((dimType === D_LG) || (dimType === D_XL) || (dimType === D_MD)) &&
            <Box className="p-2 d-flex flex-row justify-content-end align-items-center">
                <Button onClick={onCreateChat} className="mx-1 px-3 py-0" variant={"contained"} endIcon={<Send color={"#fff"} w={18} h={18}/>}>{CAPTION_WRITE}</Button>
                <Button onClick={onComplain} className="mx-1 px-3 py-0" variant={"contained"} endIcon={<SadEmojiSvg color={"#fff"} w={18} h={18}/>} color="error">{CAPTION_COMPLAIN}</Button>
            </Box>}
            {((dimType === D_SM) || (dimType === D_XS)) &&
            <Box className="p-2 d-flex flex-column justify-content-center"  >
                <Button onClick={onCreateChat} className="flex-grow-1 my-1 p-0" variant={"contained"} endIcon={<Send color={"#fff"} w={18} h={18}/>}>{CAPTION_WRITE}</Button>
                <Button onClick={onComplain} className="flex-grow-1 my-1 py-0" variant={"contained"} endIcon={<SadEmojiSvg color={"#fff"} w={18} h={18}/>} color="error">{CAPTION_COMPLAIN}</Button>
            </Box>}
            <SupportFormModal open={complain}
                              handleClose={closeComplain}
                              data={supportForms.complain}
                              userExchanges={{fromUserId: currentUserId, onUserId: selectedUser?.id}}/>
        </div>

    );
}

export default ProfileDetail;