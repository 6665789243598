import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, styled} from "@mui/material";
import {
    CAPTION_CHARS_OF,
    CAPTION_DESCRIBE_PROBLEM, CAPTION_PLACE_LOCATION,
    CAPTION_SAVE, CAPTION_USER_AD_TEXT, CAPTION_USER_AD_TITLE,
    LABEL_CLOSE,
    SUBTITLE_CREATE_USER_AD, SUBTITLE_EDIT_USER_AD
} from "../../Constants/TextMessagesRu";
import AutocompleteCityList from "../Autocompletes/AutocompleteCityList";


const CreateEditUserAdModal = ({open, handleClose, profile, isEdit, userAd_}) => {
    const [location, setLocation] = useState({locality: profile?.locality || "", region: profile?.region || "", country: profile?.country || ""});
    const maxLen = 800;
    const [text, setText] = useState("");
    const [userAd, setUserAd] = useState({id: null, body: "", locality: userAd_?.locality ?? '', region: userAd_?.region ?? '', country: userAd_?.country ?? '', userId: profile?.id})

    function onTextAreaChange(e) {
        setText(e?.target?.value);
        setUserAd(state => ({...state, body: e?.target?.value}));
    }

    function onLocationSelected(value) {
        setLocation(value);
        setUserAd(state => ({...state, locality: value?.locality, region: value?.region, country: value?.country}));
    }

    useEffect(() => {
        if (userAd_) {
            setUserAd(userAd_);
            setLocation(prevState => ({...prevState, country: userAd_?.country, region: userAd_?.region, locality: userAd_?.locality}))
        }
    }, [userAd_])

    return (
        <>
            <Dialog
                maxWidth={"sm"}
                className="overflow-hidden"
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}>
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    <span className="fs-3 fw-bold">{isEdit ? <>{SUBTITLE_EDIT_USER_AD}</>: <>{SUBTITLE_CREATE_USER_AD}</>}</span>
                </DialogTitle>
                <DialogContent dividers>
                    <div className="d-flex flex-column">
                        <span className="fs-4">{CAPTION_PLACE_LOCATION}</span>
                        <AutocompleteCityList onLocationSelected={onLocationSelected}
                                              showIcon={false}
                                              width={300}
                                              defaultLocation={{
                                                  locality: location?.locality || "",
                                                  region: location?.region || "",
                                                  country: location?.country || ""
                                              }}/>
                        <span className="mt-1 fs-4">{CAPTION_USER_AD_TITLE}</span>
                        <textarea rows={6}
                                  cols={9}
                                  onChange={onTextAreaChange}
                                  defaultValue={userAd?.body}
                                  maxLength={maxLen}
                                  placeholder={CAPTION_USER_AD_TEXT}
                                  className="mx-1 px-2 py-1 w-100 fs-5_ text-success"/>
                        <label className="fs-5_ fw-bold mt-1">{`${CAPTION_CHARS_OF} ${text?.length}/${maxLen}` }</label>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"} className="text-danger border-danger m-x1" onClick={() => handleClose(null, isEdit)}>{LABEL_CLOSE}</Button>
                    <Button variant={"outlined"} className="text-success border-success mx-1" onClick={() => handleClose(userAd, isEdit)}>{CAPTION_SAVE}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default React.memo(CreateEditUserAdModal)