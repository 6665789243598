import React from "react";
import {
     Card, CardMedia, IconButton, Tooltip,
} from "@mui/material";
import {USER_ID_KEY} from "../../api/ApiCommon";
import {useDispatch, useSelector} from "react-redux";
import {
    defineSelectedUser,
    selectCommon,
    setChatSelectedUser,
    setPageIndex,
    setTabIndex
} from "../../Stores/slices/CommonSlice";
import {NO_PHOTO_PNG} from "../../../assets";
import {getEnvOfStorage, getStoredValueByKey} from "../../Stores/Env";
import {addChatMessageAsync} from "../../api/ChatApi";
import {
    LABEL_ONLINE,
    LABEL_USER_NOTIFICATIONS_OFF, LABEL_USER_NOTIFICATIONS_ON,
    LABEL_WAS_MAN,
    LABEL_WAS_WOMAN
} from "../../Constants/TextMessagesRu";
import ChatLeftTextSvg from "../../Svg/ChatLeftTextSvg";
import {updateUnreadMessagesCount} from "../../Stores/slices/ChatListSlice";
import BellFillSvg from "../../Svg/BellFillSvg";
import BellSlashFillSvg from "../../Svg/BellSlashFillSvg";
import {getAge} from "../../services/DateTimeService";


const ProfileViewElement = ({profile}) => {
    const dispatch = useDispatch();
    const IMG_HEIGHT = 200;
    const currentUserId = getStoredValueByKey(USER_ID_KEY);
    const imgUrl = profile?.thumbUrl?.src;
    const {myProfile} = useSelector(selectCommon)

    function onClick() {
        dispatch(setPageIndex(1));
        dispatch(defineSelectedUser(profile));
    }

    function getLeftChatTime() {
        let leftDate = profile?.leftChatTime;
        return profile?.sex === 'MAN' ? `${LABEL_WAS_MAN} ${leftDate}` : `${LABEL_WAS_WOMAN} ${leftDate}`;
    }

    function getLocality() {
        return profile?.locality || "";
    }

    function onWriteMsgClick() {
        if (!currentUserId) {
            return;
        }

        //Отправить пустое сообщение, чтобы добавить новую историю чата для текущего пользователя
        addChatMessageAsync({
            userId: profile?.id,
            fromUserId: currentUserId,
            message: "",
        }).then(res => {
            let content = res?.data;
            content.userId = currentUserId;
            content.fromUserId = profile?.id;

            let body = {
                type: 'chat',
                content: content,
                sender: myProfile?.firstName,
                routerKey: "",
                dateTime: new Date().toISOString(),
                group: 0,
                fromUser: profile
            }
            dispatch(updateUnreadMessagesCount({chatMessage: body, currentUserId: currentUserId}));
            dispatch(defineSelectedUser(profile));
            dispatch(setChatSelectedUser(profile));
            dispatch(setTabIndex(0));
        }).catch(err => console.error(err));
    }

    return(
        <Card className="card m-1 photo-card">
            <div className="position-relative">
                {(imgUrl !== '') ?
                    <CardMedia component="img"
                               height={IMG_HEIGHT}
                               image={`${getEnvOfStorage()?.staticUrl}${imgUrl}`}
                               alt={profile?.firstName}
                               className="p-sm-0 p-md-1"
                               onClick={onClick}
                    />
                    :
                    <CardMedia component="img"
                               height={IMG_HEIGHT}
                               image={NO_PHOTO_PNG}
                               alt={profile?.firstName}
                               className="p-sm-0 p-md-1"
                               onClick={onClick}
                    />
                }
                <div className="d-flex flex-row justify-content-start align-items-center px-2">
                      <span onClick={onWriteMsgClick} className="position-relative p-0 mb-1 mt-0">
                         <ChatLeftTextSvg color={'#01923b'} w={18} h={18}/>
                         <div style={{display: profile?.online ? 'block': 'none'}} className="position-absolute fs-6_ top-0 start-100 translate-middle p-1 bg-success border border-light rounded-circle">{}</div>
                      </span>
                    <span className="mx-2 text-black fs-6_">{`${profile?.firstName} ${profile?.lastName}, ${getAge(profile?.birthDate)}`}</span>
                 </div>

                <span className="left-top-abs-label fs-6_">{!profile?.online ? getLeftChatTime() : LABEL_ONLINE}</span>
                <span className="left-bottom-abs-label fs-6_">{getLocality()}</span>
                <span className="right-top-abs-icon">{
                    profile?.userSettings?.emailedMessageEvents ?
                        <Tooltip title={LABEL_USER_NOTIFICATIONS_ON} arrow><IconButton className="py-0 px-1 m-0 shadow" size="small"><BellFillSvg w={18} h={18} color={'#01923b'}/></IconButton></Tooltip> :
                        <Tooltip title={LABEL_USER_NOTIFICATIONS_OFF} arrow><IconButton className="py-0 px-1 m-0 shadow" size="small"><BellSlashFillSvg w={18} h={18} color={'red'}/></IconButton></Tooltip>
                }</span>
            </div>
        </Card>
    );
}

export default React.memo(ProfileViewElement);