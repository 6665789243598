import React from "react";
import RoundSubstrate from "../../Svg/Sunstrate/RoundSubstrate";

const IconSubTitle = ({icon, text, dataText, textColor}) => {
    return(
        <div className="d-flex flex-row justify-content-start align-items-center">
            <RoundSubstrate color="orange" children={icon} />
            <span style={{color: textColor || 'black'}}  className="mx-1 fs-4_ d-none d-md-flex">{(dataText) ? `${text} ${dataText}`: `${text}`}</span>
            <span style={{color: textColor || 'black'}}  className="mx-1 fs-4_ d-md-none">{(dataText) ? `${text} ${dataText}`: `${text}`}</span>
        </div>
    );
}

export default IconSubTitle;