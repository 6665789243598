import React from 'react';


const MessageItemElement = (props) => {
    const showTimeIfEqualsDates = (timestamp) => {
        if (!timestamp) return "";
        let timeLabel = new Date(timestamp);
        return timeLabel.getDate() === new Date().getDate() ? timeLabel.toLocaleTimeString() : timeLabel.toLocaleString();
    }

   return  (
     <div className="d-flex flex-column message-item">
       <span className="date-message-badge d-flex justify-content-end text-primary mx-1 fs-5_ ">{showTimeIfEqualsDates(props?.data?.timestamp)}</span>
       <div style={{backgroundColor: props.color || 'white'}} className="MessageItemElement">
           {props.children}
       </div>
     </div>
   )
};

export default MessageItemElement;
