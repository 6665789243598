import React, {useEffect, useState} from "react";
import {Checkbox, FormControl, Grid, Slider} from "@mui/material";
import IconSubTitle from "../Header/IconSubTitle";
import PlusCircleSvg from "../../Svg/PlusCircleSvg";
import MinusCircleSvg from "../../Svg/MinusCircleSvg";
import {CAPTION_NEVER_MIND} from "../../Constants/TextMessagesRu";


function valuetext(value) {
    return `${value}`;
}

/**
 * Компанент числового диапазона от и до
 * @param defaultValue
 * @param icon
 * @param iconTitle
 * @param onChangeRange
 * @param {number} minValue
 * @param {number} maxValue
 * @param {boolean} locked
 * @returns {JSX.Element}
 * @constructor
 */
const RangeField = ({defaultValue, icon, iconTitle, onChangeRange, onChecked, isChecked, minValue, maxValue, locked}) => {
    const [value, setValue] = useState(defaultValue);
    const [check, setCheck] = useState(isChecked);

     useEffect(() => {
        if (locked) return;
        if (!check) {
            onChangeRange([null, null]);
        }
        onChecked(check);
    }, [check]);

    const handleChange = (event, newValue) => {
        if (locked) return;
        setValue(newValue);
    };

    const onKeyUp = (event, val) => {
        if (locked) return;
        onChangeRange(val);
    }

    return(
        <div className="d-none d-md-flex flex-row justify-content-start align-items-center">
            {(!locked) &&
            <Checkbox checked={isChecked} onChange={(e) => {
                setCheck(e?.target?.checked);
                if (!check) {
                    onChangeRange(value)
                }}
            }/>}

            <div className="d-flex flex-row justify-content-start align-items-center">
                <IconSubTitle text={iconTitle} icon={icon}  dataText={`(от ${value[0]} до ${value[1]})`}/>
            </div>

            <Grid container>
                <Grid item md={8} lg={8} sm={12} xs={12}>
                    <FormControl variant="standard" fullWidth>
                            {check ?
                                <div className="d-flex flex-row align-items-center w-100">
                                    <div className="mx-1"><MinusCircleSvg color={"#01923b"} h={22} w={22}/></div>
                                    <Slider
                                      getAriaLabel={() => 'Value range'}
                                      value={value}
                                      min={minValue || 18}
                                      max={maxValue || 100}
                                      onChange={handleChange}
                                      onMouseUp={(event => onKeyUp(event, value))}
                                      onKeyUp={(event => onKeyUp(event, value))}
                                      onTouchCancel={(event => onKeyUp(event, value))}
                                      valueLabelDisplay="auto"
                                      getAriaValueText={valuetext}
                                    />
                                    <div className="mx-1"><PlusCircleSvg color={"#01923b"} h={22} w={22}/></div>
                                </div> :
                                <span className="fs-6_ fw-bold text-success">{CAPTION_NEVER_MIND.toUpperCase()}</span>}
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
}

export default RangeField;