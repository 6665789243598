import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import useWindowDimensions from "../../../Hooks/useWindowDimension";
import {FixedSizeList} from "react-window";
import {convertMap} from "../../../services/DataService";
import GridItem from "../../GridItems/GridItem";
import {LinearProgress, Stack} from "@mui/material";
import {getStoredValueByKey, SEARCH_PROFILE_OFFSET, storeValue} from "../../../Stores/Env";


/**
 * Компонент галерея с автоподгрузкой на базе виртуального списка (react-window)
 * @param {Object[]} content
 * @param onNextPage
 * @param {boolean} isEmptyData
 * @param {number} page
 * @param incPage
 * @param {number} rowHeight
 * @param {boolean} loading
 * @param {string} keyScrollOffset
 * @returns {JSX.Element}
 * @constructor
 */
function VirtualGridScrollLoader ({content, onNextPage, isEmptyData, page, incPage, rowHeight, loading, keyScrollOffset}) {
    const GRID_COLS = 'gridCols';
    const COLS = +getStoredValueByKey(GRID_COLS) || 5;
    const boxRef = useRef(null);
    const gridRef = useRef();

    const ROW_HEIGHT = rowHeight || 258;
    const [listHeight, setListHeight] = useState(0);
    const {width, height} = useWindowDimensions();
    const [numCols, setNumCols] = useState(COLS);
    const [data, setData] = useState(convertMap(content, COLS));

    useEffect(() => {
        gridRef?.current?.scrollTo(0, getStoredValueByKey(keyScrollOffset) || 0);
    }, [])

    useEffect(() => {
        setListHeight(boxRef.current?.clientHeight);

        if (width < 576) {
            setNumCols(2);
            storeValue(GRID_COLS, 2);
        } else if ((width > 576) && (width < 768)) {
            setNumCols(2);
            storeValue(GRID_COLS, 2);
        } else if ((width > 768) && (width < 992)) {
            setNumCols(3);
            storeValue(GRID_COLS, 3);
        } else if ((width > 992) && (width < 1200)) {
            setNumCols(4);
            storeValue(GRID_COLS, 4);
        } else if (width > 1200) {
            setNumCols(5);
            storeValue(GRID_COLS, 5);
        }

        setData(convertMap(content, numCols));
    }, [width, height]);


    useEffect(() => {
        setData(convertMap(content, numCols));
    }, [content]);


    function onScroll(e) {
        if (e?.scrollOffset > 0) {
            storeValue(keyScrollOffset, e?.scrollOffset)
        }

        if ((gridRef?.current?.scrollTop + gridRef?.current?.clientHeight+1) > gridRef?.current?.scrollHeight) {
            if (!isEmptyData) {
                incPage();
                onNextPage(page);
            }
        }
    }

    return (
        <div className="h-100" ref={boxRef}>
            <FixedSizeList
                outerRef={gridRef}
                onScroll={onScroll}
                height={listHeight}
                width={'100%'}
                itemSize={ROW_HEIGHT}
                itemCount={data.size}
                overscanCount={5}>
                {useCallback((props) => {
                    const { index, style } = props;
                    return (
                        <GridItem style={style}
                                  key={index}
                                  dataMap={data}
                                  columns={numCols}
                                  row={index}/>
                    );
                }, [data.size, numCols])}
            </FixedSizeList>
            {loading ?
                <Stack sx={{ width: '100%', color: 'grey.500', height: 8}} spacing={2}>
                    <LinearProgress color="secondary" />
                </Stack> : <div className="w-100" style={{height: 4}}></div>}
        </div>
    );
}

export default React.memo(VirtualGridScrollLoader);