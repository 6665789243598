import React from "react";
import {Button, Divider} from "@mui/material";
import {CAPTION_BACK} from "../../Constants/TextMessagesRu";
import {LeftChevronSvg} from "../../Svg";
import SocialLinks from "../SocialLinks";

const ActionBack = ({onClick}) => {
    return (
        <>
            <div className="d-flex flex-row align-items-center my-1">
                <Button variant={"none"} sx={{color: 'secondary.contrastText'}} startIcon={<LeftChevronSvg w={20} h={20}/>} className="mx-1 p-0" onClick={onClick}>
                    <span className="fs-6_ mt-1 p-0 fw-bold text-success">{CAPTION_BACK}</span>
                </Button>
            </div>
        </>
    );
}

const StandardHeader = ({onClick, hideOptions}) => {

    return (
        <div className="d-flex flex-column">
            <div className="d-flex flex-row justify-content-between align-items-center">
                <ActionBack onClick={onClick}/>
                <SocialLinks />
            </div>
            <Divider />
        </div>
    );
}

export default StandardHeader;