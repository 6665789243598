import {CAPTION_REPEAT_TEXT_CAPTCHA, SUBTITLE_CAPTCHA} from "../../Constants/TextMessagesRu";
import React, { useEffect,  useState} from "react";
import {generateCaptcha} from "../../services/captcha/captchav1";
const { createCanvas } = require("canvas");

const SimpleCaptcha = ({onEqual}) => {
    const width = 360;
    const height = 60;
    const [captcha, setCaptcha] = useState({
        expected: '',
        real: '',
    });
    const [imageCaptcha, setImageCaptcha] = useState('');
    const [isEqual, setEqual] = useState(false);


    function getImageCaptcha(captchaText) {
        const canvas = createCanvas(width, height);
        const context = canvas.getContext("2d");
        context.fillStyle = "#fce3b2";
        context.fillRect(0, 0, width, height);
        context.font = "40px Arial";
        context.textAlign = "center";
        context.fillStyle = "#ab003c";
        context.fillText(captchaText, width / 1.9, height / 1.2);
        context.fillText("_______________", width / 2, height / 1.9);
        const buffer = canvas.toDataURL("image/png");
        return buffer;
    }

    useEffect(() => {
        let captchaText = generateCaptcha();
        //console.log("png: "+getImageCaptcha(captchaText))
        setImageCaptcha(getImageCaptcha(captchaText));
        setCaptcha(prevState => ({...prevState, expected: captchaText}));
    }, []);

    useEffect(() => {
        setEqual(captcha.expected === captcha.real);
    }, [captcha]);

    useEffect(() => {
        if (onEqual) {
            onEqual(isEqual);
        }

    }, [isEqual]);

    const onCaptchaChanged = (e) => {
        setCaptcha(prevState => ({...prevState, real: e?.target?.value}));
    }

    return (
        <div className="mt-1 d-flex flex-column">
            <label className="input-label mt-1 fs-5_">{SUBTITLE_CAPTCHA}</label>
            {!imageCaptcha && <span id="generated-captcha">{captcha.expected}</span>}
            {imageCaptcha && <img style={{height: 40}} src={imageCaptcha} alt="captcha"/>}
            <input value={captcha.real} style={{color: isEqual ? 'green': 'red'}} className="mt-1 text-center fs-5_" onChange={onCaptchaChanged} type="text"
                   required id="entered-captcha" placeholder={CAPTION_REPEAT_TEXT_CAPTCHA}/>
        </div>
    );
}


export default SimpleCaptcha;