import React, {useEffect, useRef, useState} from "react";
import {Checkbox, Grid} from "@mui/material";
import IconSubTitle from "../Header/IconSubTitle";
import {CAPTION_NEVER_MIND, LABEL_FROM, LABEL_TO} from "../../Constants/TextMessagesRu";
import MinusCircleSvg from "../../Svg/MinusCircleSvg";
import PlusCircleSvg from "../../Svg/PlusCircleSvg";

const RangeNumField = ({defaultValue, icon, iconTitle, onChangeRange, onChecked, isChecked, minValue, maxValue, locked}) => {
    const [value, setValue] = useState(defaultValue);
    const [from, setFrom] = useState(defaultValue[0])
    const [to, setTo] = useState(defaultValue[1])
    const [check, setCheck] = useState(isChecked);
    const refFrom = useRef();
    const refTo = useRef();

    useEffect(() => {
        if (locked) return;
        if (!check) {
            onChangeRange([null, null]);
        }
        onChecked(check);
    }, [check]);


    useEffect(() => {
        if (from <= to) {
            onChangeRange([from, to]);
            setValue([from, to])
        }
    }, [from, to])


    function onAddFrom(val) {
        console.log("onAddFrom: "+val)
        if (val < maxValue) {
            setFrom(prevState => prevState+1)
        }
    }

    function onSubFrom(val) {
        if (val > minValue) {
            setFrom(prevState => prevState-1)
        }
    }

    function onAddTo(val) {
        if (val < maxValue) {
            setTo(prevState => prevState+1)
        }
    }

    function onSubTo(val) {
        if (val > minValue) {
            setTo(prevState => prevState-1)
        }
    }

    return(
        <div className="d-md-none d-flex flex-row justify-content-start align-items-center">
            {(!locked) &&
            <Checkbox checked={isChecked} onChange={(e) => {
                setCheck(e?.target?.checked);
                if (!check) {
                    onChangeRange(value)
                }}
            }/>}

            <div className="d-flex flex-row justify-content-start align-items-center">
                <IconSubTitle text={iconTitle} icon={icon} dataText={`(от ${value[0]} до ${value[1]})`}/>
            </div>

            <Grid container>
                <Grid item md={8} lg={8} sm={12} xs={12}>
                    {check ?
                        <div className={` d-flex flex-row align-items-center align-items-center w-100 flex-wrap`}>
                            <div className="d-flex align-items-center mx-1">
                                <span className="fs-5_ mx-1">{LABEL_FROM}</span>
                                <div onClick={() => onSubFrom(value[0])} className="mx-1" sx={{color: "secondary.main"}}><MinusCircleSvg color={"#01923b"} w={22} h={22}/></div>
                                <input min={minValue || 18}
                                       max={maxValue || 100}
                                       ref={refFrom}
                                       size={6}
                                       value={value[0]}
                                       readOnly
                                       className="mx-sm-1 mx-md-2 my-1 input_field"
                                       type="number"/>
                                <div onClick={() => onAddFrom(value[0])} className="mx-1" sx={{color: "secondary.main"}}><PlusCircleSvg color={"#01923b"} w={22} h={22}/></div>
                            </div>
                            <div className="d-flex align-items-center mx-1">
                                <span className="fs-5_ mx-1">{LABEL_TO}</span>
                                <div onClick={() => onSubTo(value[1])} className="mx-1" sx={{color: "secondary.main"}}><MinusCircleSvg color={"#01923b"} w={22} h={22}/></div>
                                <input min={minValue || 18}
                                       max={maxValue || 100}
                                       ref={refTo}
                                       size={6}
                                       readOnly
                                       value={value[1]}
                                       className="mx-sm-1 mx-md-2 my-1 input_field"
                                       type="number"/>
                                <div onClick={() => onAddTo(value[1])} className="mx-1" sx={{color: "secondary.main"}}><PlusCircleSvg color={"#01923b"} w={22} h={22}/></div>
                            </div>
                        </div> :  <span className="fs-5_ fw-bold text-success">{CAPTION_NEVER_MIND.toUpperCase()}</span>}
                </Grid>
            </Grid>
        </div>
    );
}

export default RangeNumField;