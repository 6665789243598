import {createSlice} from "@reduxjs/toolkit";
import {getStoredValueByKey} from "../Env";
import {EMAIL_KEY} from "../../api/ApiCommon";


export const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        regPageIndex: 1,
        editField: false,
        editedProfile: {
            id : '',
            firstName : '',
            lastName : '',
            birthDate : '',
            age : 18,
            height : 176,
            weight : 65,
            aboutMe : '',
            hobby : '',
            kids : 0,
            familyStatus : 'SINGLE',
            rank : 1000,
            sexOrientation : 'HETERO',
            meetPreferences : 'ALL',
            sex : 'MAN',
            imgUrls : [],
            thumbUrl : {src: "", alt: ""},
            online : false,
            leftChatTime : '',
            country : '',
            region : '',
            locality : '',
            created : '',
            disabled: false,
            userSettings: {
                emailedMessageEvents: true,
                notifyCommonEvents: false,
            },
            email: getStoredValueByKey(EMAIL_KEY),
        },
        regProfile: {
            id : '',
            firstName : '',
            lastName : '',
            birthDate : '',
            age : 18,
            height : 176,
            weight : 65,
            aboutMe : '',
            hobby : '',
            kids : 0,
            familyStatus : 'SINGLE',
            rank : 1000,
            sexOrientation : 'HETERO',
            meetPreferences : 'ALL',
            sex : 'MAN',
            imgUrls : [],
            thumbUrl : {src: "", alt: ""},
            online : false,
            leftChatTime : '',
            country : '',
            region : '',
            locality : '',
            created : '',
            disabled: false,
            userSettings: {
                emailedMessageEvents: true,
                notifyCommonEvents: false,
            },
            email: '',
        },
    },
    reducers: {
        editProfile: (state, action) => {
            state.editedProfile = {...state.editedProfile, ...action.payload};
        },
        setRegProfile: (state, action) => {
            state.regProfile = {...state.regProfile, ...action.payload};
        },
        setEditField: (state, action) => {
            state.editField = action.payload;
        },
        dropUserProfileStates: state => {
            state.regPageIndex = 1;
            state.editField = false;
            state.editedProfile = {
                id: '',
                firstName: '',
                lastName: '',
                birthDate: '',
                age: 18,
                height: 176,
                weight: 65,
                aboutMe: '',
                hobby: '',
                kids: 0,
                familyStatus: 'SINGLE',
                rank: 1000,
                sexOrientation: 'HETERO',
                meetPreferences: 'ALL',
                sex: 'MAN',
                imgUrls: [],
                thumbUrl: {src: "", alt: ""},
                online: false,
                leftChatTime: '',
                country: '',
                region: '',
                locality: '',
                created: '',
                disabled: false,
                userSettings: {
                    emailedMessageEvents: true,
                    notifyCommonEvents: false,
                },
                email: '',
            };
            state.regProfile = state.editedProfile;
        },
        setRegPageIndex: (state, action) => {
            state.regPageIndex = action.payload;
        }
    }
});

export const selectProfile = (state) => state.profile;
export const {editProfile, setEditField, setRegProfile, dropUserProfileStates, setRegPageIndex} = profileSlice.actions;
export default profileSlice.reducer;