import React from "react";
import {Fab} from "@mui/material";


/**
 *
 * @param onClick
 * @param {string} bgColor
 * @param {string} iconColor
 * @param {string} ariaLabel
 * @param {JSX.Element} icon
 * @param {string} size
 * @param {boolean} disable
 * @returns {JSX.Element}
 * @constructor
 */
const IconFab = ({onClick, bgColor, iconColor, ariaLabel, icon, fabStyle, size, disable}) => {
    const buttonSx = {
     ...({
         bgColor: 'primary',
         '&:hover': {
             bgColor: "primary.light",
         }
     })
    }

    return (
       <>
           <Fab
               disabled={disable || false}
               aria-label={ariaLabel || ''}
               sx={{...fabStyle, ...buttonSx, color: iconColor || '#000000'}}
               onClick={onClick}
               color={ bgColor || '#FFFFFF'}
               size={size || 'large'}
           >
               {icon}
           </Fab>
       </>
    );
}

export default IconFab;