import React, {useEffect, useRef, useState} from "react";
import {NO_PHOTO_PNG} from "../../../assets";
import {CardMedia} from "@mui/material";
import IconFab from "../Fabs/IconFab";
import FloatIcon from "../Fabs/FloatIcon";
import {LABEL_ERROR, MSG_FILE_TOO_BIG, SEX_DATA} from "../../Constants/TextMessagesRu";
import {deleteFile, saveFile, saveThumbFile} from "../../api/UploadFiles";
import {useDispatch} from "react-redux";
import {setFavoriteImgFileName, setFilesChanged} from "../../Stores/slices/LoadFilesSlice";
import {maxImgFileSize} from "../../services/DataService";
import TrashSvg from "../../Svg/TrashSvg";
import PlusSvg from "../../Svg/PlusSvg";
import StarFillSvg from "../../Svg/StarFillSvg";
import HeartFillSvg from "../../Svg/HeartFillSvg";
import imageCompression from "browser-image-compression";
import {compressImageFile} from "../../services/FileService";

/**
 *
 * @param {string} imgUrl
 * @param {string} alt
 * @param {number} height
 * @param {any} onClick
 * @param {boolean} isAdd
 * @param {string} sex
 * @param {boolean} isEditable
 * @param {string} userId
 * @param onAddImage
 * @param onUploadFile
 * @returns {JSX.Element}
 * @constructor
 */
const PhotoCard = ({imgUrl, alt, height, onClick, isAdd, sex, isEditable, userId, isFavorite, onUploadFile}) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef();
    const [photo, setPhoto] = useState(imgUrl);
    const dispatchFiles = useDispatch();
    const [uploadFile, setUploadFile] = useState({loading: false, error: ''});

    const fabStyle = {
        position: 'absolute',
        top: 15,
        right: 15,
        zIndex: 999
   };

    useEffect(() => {
        setSelectedFile(alt);
        setUploadFile(prevState => ({...prevState, loading: false}));
    }, []);

    useEffect(() => {
        if (onUploadFile) {
            onUploadFile(uploadFile);
        }
    }, [uploadFile])

    const iconFabStyle = {
        position: 'absolute',
        bottom: 15,
        left: 15,
        zIndex: 1000,
        boxShadow: 'gray'
    };

    function onAddImage() {
        fileInputRef?.current?.click();
    }

    function onRemoveImage() {
        deleteFile(alt || selectedFile, userId, (res, err) => {
            if (!err) {
                dispatchFiles(setFilesChanged());
            } else {
                console.log(err);
            }
        });

    }

    const onFileChange = async (e) => {
        setUploadFile(prevState => ({...prevState, loading: true, error: ''}));
        let file = e?.target?.files[0];

        file = await compressImageFile(file);

        if (file?.size/1048576 > maxImgFileSize) {
            setUploadFile(prevState => ({...prevState, error: `${MSG_FILE_TOO_BIG} ${maxImgFileSize} Мб`, loading: false}));
            return;
        }

        if (file) {
            setSelectedFile(file?.name);
            let fReader = new FileReader();
            fReader.readAsDataURL(file);
            fReader.onloadend = function (event) {
                setPhoto(event?.target?.result);
                saveFile(file, userId, (res, err) => {
                    if (!err) {
                        dispatchFiles(setFilesChanged());
                    } else {
                        setUploadFile(prevState => ({...prevState, error: LABEL_ERROR}));
                    }
                    setUploadFile(prevState => ({...prevState, loading: false}));
                });
            }
        }
    }

    const onFavorite = () => {
        saveThumbFile(userId, alt, (data, err) => {
            if (!err) {
                dispatchFiles(setFavoriteImgFileName(data?.data?.fileName));
            }
        })
    }

   return(
       <>
           {(photo) && (isEditable) &&
           <IconFab
               fabStyle={fabStyle}
               icon={<TrashSvg color={"#fff"}/>}
               bgColor={"primary"}
               iconColor={"#fff"}
               size={"small"}
               onClick={onRemoveImage}
           />}

           {(!(photo) && (isAdd) && (isEditable)) &&
           <IconFab
               fabStyle={fabStyle}
               icon={<PlusSvg color={"#fff"}/>}
               bgColor={"secondary"}
               iconColor={"#fff"}
               size={"small"}
               onClick={onAddImage}
           />}

           {(isFavorite && (isEditable)) &&
           <FloatIcon
               icon={(sex === SEX_DATA[0]?.tag) ?
                   <div className="p-1"><StarFillSvg color={"#FF0000"}/></div> :
                   <div className="p-1"><HeartFillSvg color={"#FF00D0"}/></div>}
               fabStyle={iconFabStyle}
               caption={""}
               color={(sex === SEX_DATA[0]?.tag) ? "#FF0000": "#ff00DD"}
               onClick={onFavorite}
           />}
           {(!isFavorite && (isEditable) && (imgUrl)) &&
           <FloatIcon
               icon={(sex === SEX_DATA[0]?.tag) ?
                   <div className="p-1"><StarFillSvg color={"#aaaaaa"}/></div>  :
                   <div className="p-1"><HeartFillSvg color={"#aaa"}/></div>}
               fabStyle={iconFabStyle}
               caption={""}
               color={'#AAAAAA'}
               onClick={onFavorite}
           />}

           <input type="file" multiple={false} onChange={onFileChange} accept="image/*" ref={fileInputRef} hidden/>

           <CardMedia
               component="img"
               height={height}
               image={imgUrl || photo || NO_PHOTO_PNG}
               alt={alt || 'no photo'}
               sx = {{padding: 1}}
               onClick={onClick}
           />
       </>
   );
}

export default PhotoCard;