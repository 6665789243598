import SockJS from "sockjs-client";
import Stomp from 'stompjs';
import { ChatMessage } from "./dto/ChatMessage";
import {getEnvOfStorage} from "../../Stores/Env";
import {EditMessage} from "./dto/EditMessage";
import {ChatHistory} from "./dto/ChatHistory";
import {
    ERR_CHAT_SERVICE,
    MSG_CANT_REMOVE_CHAT_ITEM,
    MSG_CANT_REMOVE_CHAT_MESSAGE
} from "../../Constants/TextMessagesRu";

export const MessageTypes = {
    edit: 'edit',
    delete: 'delete',
    chat: 'chat',
    rmChats: 'rm_chats',
}

export class WebSocketClient {
    connected = false;
    attemts = null;
    topic = "";
    subscription = null;
   
    constructor() {
        this.WS_ENDPOOINT_APP = getEnvOfStorage()?.stompUrl+"/wsApp"
        this.stompClient = null;
        this.client = null;
    }

    destructor() {
        this.stompClient = null;
    }
    

    connect(userId, token) {
        if (!token) return;

        this.client = new SockJS(`${this.WS_ENDPOOINT_APP}?token=${token}`);
        this.stompClient = Stomp.over(this.client);
        this.stompClient.debug = null
        this.stompClient.connect(
            {},
            (data) => this.connectionSuccess(userId),
            (error) => this.connectionError(error)
        );
        this.client.onclose = () => {
            this.connected = false;
            //Переподключиться к ws серверу
            setTimeout(() => {
                if ((!this.connected) && (this.stompClient !== null) && (userId)) {
                    console.warn(`try to connect user ${userId} ...`);
                    this.connect(userId, token);
                }
            }, 5000);
        };
    }

    connectionSuccess(userId) {
        this.topic = '/topic/'+userId;
        if ((this.stompClient) && (userId)) {
            try {
                this.connected = true;
                this.unsubscribe();
                this.subscription = this.stompClient.subscribe(this.topic, (data) => this.onMessageReceived(data));
                this.addUserSession(userId);
            } catch (e) {
                console.error("Subscribe error: "+e);
                this.connected = false;
            }
        }
    }

    onMessageReceived(message) {
       // console.log('onMessageReceived -- '+ JSON.stringify(message.body));
    }
    
    disconnect() {
       if ((this.connected)) {
           if (this.stompClient) {
               this.unsubscribe();
               this.stompClient?.disconnect(() => {
                   this.stompClient = null;
               });
           }

           if (this.client) {
               this.client.close();
               this.connected = false;
           }
       }
    }

    unsubscribe() {
        if (this.subscription) {
            this.subscription?.unsubscribe({});
        }
    }

    connectionError(err) {
        console.warn('ws error: '+err);
        this.connected = false;
    }


    sendChatMessage(type, sender, routerKey, content, fromUser, callback) {
        if ((!content?.userId) || (!content?.fromUserId)) return;

        this.send(new ChatMessage(type, sender, content, routerKey, fromUser), "/app/chat", callback);
    }

    /**
     * Удалить сообщения чата
     * @param {ChatItem[]} chatItems
     * @param {string} fromUserId
     * @param {string} toUserId
     * @param callback
     */
    deleteChatMessages(chatItems, fromUserId, toUserId, callback) {
        this.send(new EditMessage(MessageTypes.delete, chatItems, fromUserId, toUserId), "/app/edit", callback, MSG_CANT_REMOVE_CHAT_MESSAGE);
    }

    /**
     * Изменить сообщения чата
     * @param {ChatItem[]} chatItems
     * @param {string} fromUserId
     * @param {string} toUserId
     * @param callback
     */
    editChatMessages(chatItems, fromUserId, toUserId, callback) {
        this.send(new EditMessage(MessageTypes.edit, chatItems, fromUserId, toUserId), "/app/edit", callback, MSG_CANT_REMOVE_CHAT_MESSAGE);
    }

    /**
     * Удалить историю переписка пользоватлея
     * @param {string} ownerUserId
     * @param {string[]} chatUserIds
     */
    removeChats(ownerUserId, chatUserIds, callback) {
        this.send(new ChatHistory(MessageTypes.rmChats, chatUserIds, ownerUserId), "/app/chat/remove", callback, MSG_CANT_REMOVE_CHAT_ITEM);
    }

    /**
     * Уведомить сервер о подключении нового пользователя
     * @param {string} userId
     * @param {boolean} online
     */
    addUserSession(userId, online = true) {
        this.send({userId: userId, online: online}, "/app/user")
    }

    /**
     *
     * @param payload
     * @param routePath
     * @param callback
     * @param customText
     */
    send(payload, routePath, callback = null, customText = null) {
        if (!this.connected) {
            let err = !customText ? ERR_CHAT_SERVICE : customText;
            if (callback) {
                callback(err);
            }
            return;
        }

        if (this.stompClient) {
            this.stompClient.send(
                routePath,
                {},
                JSON.stringify(payload)
            );

            if (callback) {
                callback(null);
            }
        }
    }

    /**
     * Попытка соединиться к серверу WebSocket
     * @param {WebSocketClient} ws
     * @param {string} currentUserId
     * @param function(error: any) callback
     */
    async tryStompConnect(token, ws, currentUserId, callback) {
        if (!this.connected) {
            ws?.connect(currentUserId, token);

            ws.connectionError = (error) => {
                callback(error?.toString());
            }
        }
    }

}

