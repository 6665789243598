import React, {useMemo, useState} from "react";
import {
    AppBar,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
} from "@mui/material";


import {IMG_LOGO} from "../../assets";
import RoundSubstrate from "../Svg/Sunstrate/RoundSubstrate";
import {useNavigate} from "react-router-dom";
import {ROUTE_COMPLAINS, ROUTE_OPTIONS, ROUTE_PROFILE, ROUTE_SIGNUP} from "../Constants/Routes";
import {getEnvOfStorage, KEY_INIT_SEARCH, KEY_THUMB_URL, storeValue} from "../Stores/Env";
import {
    CAPTION_LOG_OUT,
    SUBTITLE_MY_PROFILE,
    SUBTITLE_SPEC_OPTIONS,
    SUBTITLE_USER_QUESTION, supportForms
} from "../Constants/TextMessagesRu";
import {useDispatch, useSelector} from "react-redux";
import {dropChatHistoryStates} from "../Stores/slices/ChatListSlice";
import {dropChatMessageList} from "../Stores/slices/ChatMessagesSlice";
import {dropCommonStates} from "../Stores/slices/CommonSlice";
import {fullDropGuests} from "../Stores/slices/GuestSlice";
import {fullDropSearchedProfiles} from "../Stores/slices/SearchProfilesSlice";
import {dropUserProfileStates} from "../Stores/slices/UserProfileSlices";
import SupportFormModal from "../Components/Forms/SupportFormModal";
import CaretDownSvg from "../Svg/CarretDownSvg";
import {PersonSvg} from "../Svg";
import GearSvg from "../Svg/GearSvg";
import EnvelopeSvg from "../Svg/EnvelopeSvg";
import BoxArrowLeftSvg from "../Svg/BoxArrowLeftSvg";
import {EMAIL_KEY, TOKEN_KEY, USER_ID_KEY} from "../api/ApiCommon";
import AvatarItemV2 from "../Components/Avatars/AvatarItemV2";
import BellFillSvg from "../Svg/BellFillSvg";
import {selectComplains} from "../Stores/slices/ComplainsSlice";


const menuItems = [
    {img: <PersonSvg w={22} h={22} />, title: SUBTITLE_MY_PROFILE},
    {img: <GearSvg w={22} h={22}/>, title: SUBTITLE_SPEC_OPTIONS},
    {img: <EnvelopeSvg w={22} h={22} />, title: SUBTITLE_USER_QUESTION},
    {img: <BoxArrowLeftSvg w={22} h={22} />, title: CAPTION_LOG_OUT},
];

const ResponsiveAppBar = ({user}) => {
    const [support, setSupport] = useState(false);
    const navigate = useNavigate();
    const [,setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const dispatch = useDispatch();
    const {complains} = useSelector(selectComplains)

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleNavigate = (index) => {
        switch (index) {
            case 0: //Настройки профиля
                navigate(`${ROUTE_PROFILE}?userId=${user?.id}`);
                break;
            case 1: //Спецвозможности
                navigate(`${ROUTE_OPTIONS}`);
                break;
            case 2: //Спецвозможности
                setAnchorElUser(null);
                setSupport(true);
                break;
            case 3: //Выход
                storeValue(EMAIL_KEY, '')
                storeValue(TOKEN_KEY, '')
                storeValue(USER_ID_KEY, '')
                storeValue(KEY_THUMB_URL, '');
                storeValue(KEY_INIT_SEARCH, 0)

                navigate(`${ROUTE_SIGNUP}`);
                dispatch(dropChatHistoryStates());
                dispatch(dropChatMessageList());
                dispatch(dropCommonStates());
                dispatch(fullDropGuests());
                dispatch(fullDropSearchedProfiles());
                dispatch(dropUserProfileStates());
                break;
        }

    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    function closeSupport() {
        setSupport(false);
    }

    function onComplainsNavigate() {
        navigate(`${ROUTE_COMPLAINS}?userId=${user?.id}`);
    }

    return (
        <AppBar position="static" elevation={1} sx={{backgroundColor: 'orange'}}>
            <Container maxWidth="xl" >
                <Toolbar variant="dense" disableGutters className="d-flex justify-content-between align-items-center p-0">
                    <div className="d-flex flex-row justify-content-center align-items-center p-0">
                        <a rel={"nofollow"} href={getEnvOfStorage()?.homeSiteUrl} alt="Чат Стрелка"><img alt="logo" src={IMG_LOGO} style={{height: 35}}/></a>
                    </div>

                    <div className="d-flex flex-row justify-content-center align-items-center flex-wrap">
                        <span className="mx-1 flex-wrap text-black fs-5_">{user?.firstName.toUpperCase()}</span>
                        <span className="mx-1 flex-wrap text-black fs-5_">{user?.lastName.toUpperCase()}</span>
                    </div>

                    <div className="d-flex flex-row justify-content-center align-items-center">
                        <IconButton onClick={onComplainsNavigate}>
                            <div className="p-0 m-0 position-relative">
                                <BellFillSvg w={22} h={22} color={"#445566"}/>
                                <span
                                    className="translate-middle badge rounded-pill badge-count-red">
                                    {complains?.filter(item => !item?.addresseeRead )?.length > 0 ? complains?.filter(item => !item?.addresseeRead )?.length : ''}
                                </span>
                            </div>
                        </IconButton>

                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <div className="d-flex align-items-center">
                                    <div className="mx-1"><CaretDownSvg w={12} h={12}/></div>
                                    {useMemo(() =>  <AvatarItemV2 profile={user} hasBorder={{border: 1}}/>, [user])}
                                </div>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '30px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {menuItems.map((item, index) => (
                                <MenuItem className="py-1 px-2" key={index} onClick={() => {handleCloseNavMenu(); handleNavigate(index);}}>
                                    <div className="mr-2"><RoundSubstrate color="orange" children={item?.img} /></div>
                                    <span className="fs-5_" >{item?.title}</span>
                                </MenuItem>
                            ))}
                        </Menu>
                    </div>
                </Toolbar>
            </Container>
            <SupportFormModal open={support}
                              handleClose={closeSupport}
                              data={supportForms.support}
                              userExchanges={{fromUserId: user?.id, onUserId: ""}}/>
        </AppBar>
    );
};
export default ResponsiveAppBar;