import React, {useEffect, useState} from "react";
import {Button, Divider, FormControlLabel, Grid, Radio, RadioGroup, Typography} from "@mui/material";
import {
    ADS_INIT_SEARCH,
    CAPTION_FIND,
    CAPTION_FIRST_NAME, CAPTION_FIRST_NAME_HINT,
    CAPTION_ONLINE,
    CAPTION_SEARCH_PARAMS, CAPTION_SECOND_NAME, CAPTION_SECOND_NAME_HINT,
    FAMILY_STATUS_DATA,
    KIDS_DATA, LABEL_ADS, LABEL_SEARCH_FOR_LOCATION, LABEL_YOUR_LOCATION, LOCATION_TYPES, ONLINE_DATA,
    SEX_DATA, SEX_ORIENTATION_DATA,
    SUBTITLE_CHILDS, SUBTITLE_FAMILY_STATUS_SHORT, SUBTITLE_HEIGHT,
    SUBTITLE_SEX,
    SUBTITLE_SEX_ORIENTATION, SUBTITLE_WEIGHT, SUBTITLE_YEARS_OLD
} from "../../Constants/TextMessagesRu";
import {ListField, RangeField} from "../../Components/Controls";
import {useDispatch, useSelector} from "react-redux";
import {
    selectSearchBox,
    setAgeCheck, setDefLocationType, setFamilyCheck, setHeightCheck,
    setKidsCheck, setOnlineCheck, setSearchParams,
    setSexCheck,
    setSexOrientationCheck, setWeightCheck
} from "../../Stores/slices/SearchBoxSlice";
import AutocompleteCityList from "../../Components/Autocompletes/AutocompleteCityList";
import {selectPaidOptions} from "../../Stores/slices/PaidStatusSlice";
import {disableLock, unpaidStamp} from "../../api/Payments";
import PaymentTagAvatars from "../../Components/Avatars/PaymentTagAvatars";
import RangeNumField from "../../Components/Controls/RangeNumField";
import CloudCheckSvg from "../../Svg/CloudCheckSvg";
import ArrowUpSvg from "../../Svg/ArrowUpSvg";
import WeightSvg from "../../Svg/WeightSvg";
import CalendarDateSvg from "../../Svg/CalendarDateSvg";
import ChildCareSvg from "../../Svg/ChildCareSvg";
import FaceSvg from "../../Svg/FaceSvg";
import GenderSvg from "../../Svg/GenderSvg";
import {SearchSvg} from "../../Svg";
import FamilySvg from "../../Svg/FamilySvg";
import XSvg from "../../Svg/XSvg";
import {appendYaAds} from "../../services/Yandex";
//import {appendYaAds} from "../../services/Yandex";

/**
 * Контейнер с параметрами поиска профилей по SearchBox
 * @param onClose
 * @param onCancel
 * @param defLocationType
 * @param myProfile
 * @returns {JSX.Element}
 * @constructor
 */
const SearchBox = ({onClose, onCancel, defLocationType, myProfile}) => {
    const checkDispatch = useDispatch();
    const {searchParams, ageCheck, kidsCheck, sexOrientationCheck, sexCheck, familyCheck, heightCheck, weightCheck, onlineCheck} = useSelector(selectSearchBox);
    const {payTags} = useSelector(selectPaidOptions);

    function onSearch() {
        onClose(searchParams);
    }

    useEffect(() => {
        appendYaAds("yandex_rtb_R-A-2450444-10", "R-A-2450444-10")
    }, []);


    function onLocationSelected(location) {
        checkDispatch(setDefLocationType(location?.locationType));
        if (location?.locationType === LOCATION_TYPES[1]?.value) {
            checkDispatch(setSearchParams({locality: null, region: location?.region, country: location?.country}));
            return;
        }
        checkDispatch(setSearchParams({locality: location?.locality, region: location?.region, country: location?.country}));
    }

    function locationTypeChanged(e) {
        checkDispatch(setDefLocationType(e?.target.value));
    }

    return(
        <div className="d-flex flex-column container scroll-none py-2">
            <div className="d-flex flex-column py-3 scroll-y">
                <div className="d-flex flex-row justify-content-end align-content-center px-2 d-none d-md-flex">
                    <PaymentTagAvatars />
                    <Button className="px-4 py-0 py-0" startIcon={<SearchSvg w={18} h={18} color={"#fff"}/>} variant={"contained"} onClick={onSearch}>
                        <span className="fs-5_ p-0">{CAPTION_FIND}</span>
                    </Button>
                </div>
                <span className="fs-2_">{CAPTION_SEARCH_PARAMS}</span>
                <Divider className="mt-2"/>
                <div className="d-flex align-content-center justify-content-center mt-4">
                    <div className="ads-320-90" id={`yandex_rtb_R-A-2450444-${10}`}>{LABEL_ADS}</div>
                </div>
                <div className="d-flex flex-row align-content-center my-2">

                <AutocompleteCityList onLocationSelected={onLocationSelected}
                                          showCaption={true}
                                          width={250}
                                          showIcon={true}
                                          showSelector={true}
                                          defLocationType={defLocationType}
                                          defaultLocation={{
                                              locality: searchParams?.locality || "",
                                              region: searchParams?.region || "",
                                              country: searchParams?.country || ""
                                          }}/>
                </div>

                <Divider className="mt-2"/>
                <div className={`extended-options position-relative px-1 ${unpaidStamp(payTags?.extendedSearch)}`} >
                    <Grid id={disableLock(payTags?.extendedSearch)} container>
                        <Grid item sm={12} xs={12} md={6} lg={6} xl={6} className="my-2">
                            {/*Статус связи*/}
                            <ListField iconTitle={CAPTION_ONLINE}
                                       icon={<CloudCheckSvg />}
                                       onSelectedItem={(value) => {checkDispatch(setSearchParams({online: value}))}}
                                       defaultValue={searchParams?.online || true}
                                       data={ONLINE_DATA}
                                       onChecked={(value) => {checkDispatch(setOnlineCheck(value))}}
                                       isChecked={onlineCheck}
                                       locked={!payTags?.extendedSearch}
                            />
                        </Grid>
                        <Grid item sm={12} xs={12} md={6} lg={6} xl={6} className="my-2 mx-1 d-flex flex-row justify-content-between flex-wrap align-items-end">
                            <div className="d-flex flex-column">
                                <label className="input-label">{CAPTION_FIRST_NAME}</label>
                                <input placeholder={CAPTION_FIRST_NAME_HINT}
                                       className="input_field"
                                       value={searchParams.firstName}
                                       onChange={(e) =>  {if (payTags?.extendedSearch) checkDispatch(setSearchParams({ firstName: e?.target?.value}))}}/>
                            </div>
                            <div className="d-flex flex-column">
                                <label className="input-label">{CAPTION_SECOND_NAME}</label>
                                <input placeholder={CAPTION_SECOND_NAME_HINT}
                                       className="input_field"
                                       value={searchParams.lastName}
                                       onChange={(e) => {if (payTags?.extendedSearch) checkDispatch(setSearchParams({ lastName: e?.target?.value}))}}/>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <Grid id={disableLock(payTags?.extendedSearch)} container>
                    <Grid item sm={12} xs={12} md={8} lg={6} xl={6} className="my-2">
                        {/*Шкала роста*/}
                        <RangeField iconTitle={SUBTITLE_HEIGHT}
                                    icon={<ArrowUpSvg />}
                                    onChangeRange={(value) => {
                                        checkDispatch(setSearchParams({ heightFrom: value[0]}));
                                        checkDispatch(setSearchParams({ heightTo: value[1]}));
                                    }}
                                    defaultValue={[searchParams?.heightFrom || 130, searchParams?.heightTo || 220]}
                                    onChecked={(value) => {checkDispatch(setHeightCheck(value))}}
                                    isChecked={heightCheck}
                                    minValue={130}
                                    maxValue={250}
                                    locked={!payTags?.extendedSearch}
                        />
                        <RangeNumField iconTitle={SUBTITLE_HEIGHT}
                                    icon={<ArrowUpSvg />}
                                    onChangeRange={(value) => {
                                        checkDispatch(setSearchParams({ heightFrom: value[0]}));
                                        checkDispatch(setSearchParams({ heightTo: value[1]}));
                                    }}
                                    defaultValue={[searchParams?.heightFrom || 130, searchParams?.heightTo || 220]}
                                    onChecked={(value) => {checkDispatch(setHeightCheck(value))}}
                                    isChecked={heightCheck}
                                    minValue={130}
                                    maxValue={250}
                                    locked={!payTags?.extendedSearch}
                        />
                    </Grid>
                    <Grid item sm={12} xs={12} md={8} lg={6} xl={6} className="my-2">
                        {/*Шкала массы тела*/}
                        <RangeField iconTitle={SUBTITLE_WEIGHT}
                                    icon={<WeightSvg />}
                                    onChangeRange={(value) => {
                                        checkDispatch(setSearchParams({ weightFrom: value[0]}));
                                        checkDispatch(setSearchParams({ weightTo: value[1]}));
                                    }}
                                    defaultValue={[searchParams?.weightFrom || 45, searchParams?.weightTo || 120]}
                                    onChecked={(value) => {checkDispatch(setWeightCheck(value))}}
                                    isChecked={weightCheck}
                                    minValue={45}
                                    maxValue={150}
                                    locked={!payTags?.extendedSearch} />

                        <RangeNumField iconTitle={SUBTITLE_WEIGHT}
                                    icon={<WeightSvg />}
                                    onChangeRange={(value) => {
                                        checkDispatch(setSearchParams({ weightFrom: value[0]}));
                                        checkDispatch(setSearchParams({ weightTo: value[1]}));
                                    }}
                                    defaultValue={[searchParams?.weightFrom || 45, searchParams?.weightTo || 120]}
                                    onChecked={(value) => {checkDispatch(setWeightCheck(value))}}
                                    isChecked={weightCheck}
                                    minValue={45}
                                    maxValue={150}
                                    locked={!payTags?.extendedSearch}
                        />
                    </Grid>
                </Grid>
                <Divider className="mt-2"/>
                <Grid container className="mb-3">
                    <Grid item sm={12} xs={12} md={12} lg={12} xl={12} className="my-2">
                        {/*Шкала возраста*/}

                            <RangeField iconTitle={SUBTITLE_YEARS_OLD}
                                        icon={<div className="p-1"><CalendarDateSvg w={22} h={22}/></div>}
                                        onChangeRange={(value) => {
                                            checkDispatch(setSearchParams({ ageFrom: value[0]}));
                                            checkDispatch(setSearchParams({ ageTo: value[1]}));
                                        }}
                                        defaultValue={[searchParams?.ageFrom || 16, searchParams?.ageTo || 70]}
                                        onChecked={(value) => {checkDispatch(setAgeCheck(value))}}
                                        isChecked={ageCheck}
                                        locked={false}
                            />


                            <RangeNumField iconTitle={SUBTITLE_YEARS_OLD}
                                        icon={<div className="p-1"><CalendarDateSvg w={22} h={22}/></div>}
                                        onChangeRange={(value) => {
                                            checkDispatch(setSearchParams({ ageFrom: value[0]}));
                                            checkDispatch(setSearchParams({ ageTo: value[1]}));
                                        }}
                                        minValue={16}
                                        maxValue={100}
                                        defaultValue={[searchParams?.ageFrom || 16, searchParams?.ageTo || 70]}
                                        onChecked={(value) => {checkDispatch(setAgeCheck(value))}}
                                        isChecked={ageCheck}
                                        locked={false}
                            />

                        <Divider className="mt-2"/>
                    </Grid>
                    <Grid item sm={12} xs={12} md={6} lg={6} xl={6} className="my-2">

                        <ListField iconTitle={SUBTITLE_CHILDS}
                                   icon={<ChildCareSvg />}
                                   onSelectedItem={(value) => {checkDispatch(setSearchParams({ kids: value}))}}
                                   defaultValue={searchParams?.kids || 0}
                                   data={KIDS_DATA}
                                   onChecked={(value) => {checkDispatch(setKidsCheck(value))}}
                                   isChecked={kidsCheck}
                                   locked={false}
                        />
                    </Grid>
                    <Grid item sm={12} xs={12} md={6} lg={6} xl={6} className="my-2">
                        <ListField iconTitle={SUBTITLE_SEX}
                                   icon={<FaceSvg />}
                                   onSelectedItem={(value) => {checkDispatch(setSearchParams({ sex: value}));}}
                                   defaultValue={searchParams?.sex || 'MAN'}
                                   data={SEX_DATA}
                                   onChecked={(value) => {checkDispatch(setSexCheck(value))}}
                                   isChecked={sexCheck}
                                   locked={false}
                        />
                    </Grid>
                    <Grid item sm={12} xs={12} md={6} lg={6} xl={6} className="my-2">
                        <ListField iconTitle={SUBTITLE_SEX_ORIENTATION}
                                   icon={<GenderSvg />}
                                   onSelectedItem={(value) => {checkDispatch(setSearchParams({ sexOrientation: value}));}}
                                   defaultValue={searchParams?.sexOrientation || 'HETERO'}
                                   data={SEX_ORIENTATION_DATA}
                                   onChecked={(value) => {checkDispatch(setSexOrientationCheck(value))}}
                                   isChecked={sexOrientationCheck}
                                   locked={false}
                        />
                    </Grid>
                    <Grid item sm={12} xs={12} md={6} lg={6} xl={6} className="my-2">
                        <ListField iconTitle={SUBTITLE_FAMILY_STATUS_SHORT}
                                   icon={<FamilySvg />}
                                   onSelectedItem={(value) => {checkDispatch(setSearchParams({ familyStatus: value}));}}
                                   defaultValue={searchParams?.familyStatus || 'MARRIED'}
                                   data={FAMILY_STATUS_DATA.man}
                                   onChecked={(value) => {checkDispatch(setFamilyCheck(value))}}
                                   isChecked={familyCheck}
                                   locked={false}
                        />
                    </Grid>
                </Grid>
            </div>
            <div className="d-flex flex-column p-3 d-md-none">
               <div className="d-flex justify-content-center">
                   <Button className="mb-3 p-0 w-100"
                           startIcon={<SearchSvg w={18} h={18} color={"#fff"}/>}
                           variant={"contained"}
                           onClick={onSearch}>
                       <span className="fs-5_ p-0">{CAPTION_FIND}</span>
                   </Button>
                   <div className="p-0 mx-2 mt-1" onClick={onCancel}><XSvg color="#f00" w={28} h={28}/></div>
               </div>
            </div>
        </div>
    );
}

export default SearchBox;