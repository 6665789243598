import React from 'react';
import {Button} from "@mui/material";
import {CAPTION_SIGN, SEX_DATA, SUBTITLE_SELECT_SEX} from "../../../Constants/TextMessagesRu";
import RoundSubstrate from "../../../Svg/Sunstrate/RoundSubstrate";
import {Link} from "react-router-dom";
import {ROUTE_SIGNUP} from "../../../Constants/Routes";
import {useDispatch} from "react-redux";
import {setRegPageIndex, setRegProfile} from "../../../Stores/slices/UserProfileSlices";
import {IMG_LOGO} from "../../../../assets";
import PeopleFillSvg from "../../../Svg/PeopleFillSvg";
import {getEnvOfStorage} from "../../../Stores/Env";
import LockFillSvg from "../../../Svg/LockFillSvg";

/**
 * Форма выбора пола
 * @param {*} props 
 * @returns 
 */
function SelectSexForm() {
  const dispatch = useDispatch();

  function onClick(data) {
      dispatch(setRegProfile({sex: data?.target?.value}));
      dispatch(setRegPageIndex(2));
  }

  return(
    <div className="container p-3 d-flex justify-content-center">
      <div className="d-flex flex-column primary-form-rel">
        <a rel={"nofollow"} href={getEnvOfStorage()?.homeSiteUrl} className="w-100 d-flex justify-content-center"><img src={IMG_LOGO} style={{height: 50}}/></a>
        <div className="d-flex flex-row justify-content-center align-items-center">
           <RoundSubstrate color="orange" children={<PeopleFillSvg w={25} h={25}/>} />
           <h4 className="mt-2 mx-1 fs-3_">{SUBTITLE_SELECT_SEX}</h4>
        </div>
        {SEX_DATA.map((item, index) => (
           <Button key={index} variant={'contained'} value={item?.tag} onClick={onClick} className='mt-3'>{item?.value}</Button>
        ))}
        <div className="d-flex justify-content-center align-items-center mt-2">
              <Link to={ROUTE_SIGNUP}>
                  <span className="d-inline-block mt-1 link fs-5_">
                    {CAPTION_SIGN.toUpperCase()}
                 </span>
             </Link>
        </div>
      </div>
  </div> 
  );
}

export default SelectSexForm;
