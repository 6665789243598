import React, {useEffect, useState} from "react";
import StandardHeader from "../../Components/Header/StandardHeader";
import {useDispatch, useSelector} from "react-redux";
import {setChatSelectedUser, setPageIndex, setTabIndex} from "../../Stores/slices/CommonSlice";
import ProfileEditableForm from "../Forms/Profile/ProfileEditableForm";
import {Box, Button} from "@mui/material";
import useWindowDimensions, {D_LG, D_MD, D_SM, D_XL, D_XS} from "../../Hooks/useWindowDimension";
import {addChatMessageAsync} from "../../api/ChatApi";
import {
    CAPTION_COMPLAIN,
    CAPTION_WRITE,
    supportForms
} from "../../Constants/TextMessagesRu";
import SupportFormModal from "../../Components/Forms/SupportFormModal";
import Send from "../../Svg/Send";
import SadEmojiSvg from "../../Svg/SadEmojiSvg";
import {setAlertToast} from "../../Stores/slices/ToastSlice";
import {TO_USER_ID_KEY, USER_ID_KEY} from "../../api/ApiCommon";
import {useLocation, useNavigate} from "react-router-dom";
import {userProfileView} from "../../api/ChatDataApi";
import {ROUTE_HOME, ROUTE_SIGNUP} from "../../Constants/Routes";
import Loader from "../../Components/Loader/Loader";
import {updateUserVisitor} from "../../api/VisitorApi";
import {selectPaidOptions} from "../../Stores/slices/PaidStatusSlice";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


const ProfileDetailView = () => {
    const {dimType} = useWindowDimensions();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [complain, setComplain] = useState(false);
    const query = useQuery();
    const [selectedUser, setSelectedUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const {payTags} = useSelector(selectPaidOptions);

    let currentUserId = query.get(USER_ID_KEY);
    let selectedUserId =  query.get(TO_USER_ID_KEY);

    function onBack() {
        navigate(-1)
    }

    function getUserProfile(userId) {
        setLoading(true);
        //Запросить данные профиля пользователя по userId
        userProfileView(userId)
            .then((res) => {
                setSelectedUser(res?.data);
            })
            .catch((err) => {
                console.error(err)
                navigate(ROUTE_SIGNUP);
            }).finally(() => { setLoading(false)});
    }

    useEffect(() => {
        if ((selectedUserId?.length === 0) || (selectedUserId === 'undefined') || (currentUserId?.length === 0) || (currentUserId === 'undefined')) {
            console.log("ok")
            navigate(ROUTE_SIGNUP);
        }
        if (selectedUserId === currentUserId) {
            navigate(ROUTE_SIGNUP);
        }
        console.log("currentUserId: "+currentUserId)
        console.log("selectedUserId: "+selectedUserId)
        getUserProfile(selectedUserId);
    }, [])


    useEffect(() => {
        if (payTags?.invisibleGuest) return;

        let tm = null;
        if (selectedUserId && currentUserId) {
            tm = setTimeout(() => {
                updateUserVisitor(selectedUserId, currentUserId, (data, err) => {})
            }, 3000);
        }

        return () => {
            if (tm) {
                clearTimeout(tm);
            }
        }
    }, [])

    function onCreateChat() {
        //Отправить пустое сообщение, чтобы добавить новую историю чата для текущего пользователя
        addChatMessageAsync({
            userId: selectedUserId,
            fromUserId: currentUserId,
            message: "",
        }).then(res => {
            dispatch(setChatSelectedUser(selectedUser));
            dispatch(setTabIndex(0));
            dispatch(setPageIndex(0));
            navigate(ROUTE_HOME);
        }).catch(err => console.error(err));
    }

    function onComplain() {
        setComplain(true)
    }
    function closeComplain(data) {
        setComplain(false);
        if (!data?.error) {
            dispatch(setAlertToast({open: true, severity: 'success', text: data?.message}));
        }
        dispatch(setAlertToast({open: true, severity: 'warning', text: data?.error}));
    }

    return (
      <div className="main-background">
         <div className="d-flex flex-column container h-100 p-2">
             {loading &&
             <div className="d-flex flex-grow-1 flex-row justify-content-center align-items-center">
                 <Loader height={40}/>
             </div>}
             {!loading &&
             <div className="d-flex flex-column h-100 my-profile p-2">
                 <StandardHeader onClick={onBack} hideOptions={false}/>
                 <div className="d-flex flex-grow-3 scroll-y my-2">
                     <ProfileEditableForm profile={selectedUser} currentUserId={currentUserId} isEdit={false}/>
                 </div>
                 {((dimType === D_LG) || (dimType === D_XL) || (dimType === D_MD)) &&
                 <Box className="p-2 d-flex flex-row justify-content-end align-items-center">
                     <Button onClick={onCreateChat} className="mx-1 px-3 py-0" variant={"contained"} endIcon={<Send color={"#fff"} w={18} h={18}/>}>{CAPTION_WRITE}</Button>
                     <Button onClick={onComplain} className="mx-1 px-3 py-0" variant={"contained"} endIcon={<SadEmojiSvg color={"#fff"} w={18} h={18}/>} color="error">{CAPTION_COMPLAIN}</Button>
                 </Box>}
                 {((dimType === D_SM) || (dimType === D_XS)) &&
                 <Box className="p-2 d-flex flex-column justify-content-center"  >
                     <Button onClick={onCreateChat} className="flex-grow-1 my-1 p-0" variant={"contained"} endIcon={<Send color={"#fff"} w={18} h={18}/>}>{CAPTION_WRITE}</Button>
                     <Button onClick={onComplain} className="flex-grow-1 my-1 py-0" variant={"contained"} endIcon={<SadEmojiSvg color={"#fff"} w={18} h={18}/>} color="error">{CAPTION_COMPLAIN}</Button>
                 </Box>}
                 <SupportFormModal open={complain}
                                   handleClose={closeComplain}
                                   data={supportForms.complain}
                                   userExchanges={{fromUserId: currentUserId, onUserId: selectedUser?.id}}/>
             </div>}
         </div>
      </div>
    );
}

export default ProfileDetailView;