import {
    CAPTION_CODE_FROM_EMAIL, PASS_PHRASE,
    SUBTITLE_CONFIRM_CODE, SUBTITLE_REQUEST_CONFIRM_CODE, SUBTITLE_REQUEST_NEW_CODE
} from "../../Constants/TextMessagesRu";
import React, {useEffect, useState} from "react";
import {generateCaptcha} from "../../services/captcha/captchav1";
import {requestCode} from "../../api/AuthApi";
import {useDispatch} from "react-redux";
import {setAlertToast} from "../../Stores/slices/ToastSlice";
import {encrypt} from "../../services/captcha/crypter";
import Timer from "../Timer";


export default function ConfirmCodeByEmail({onEqual, email}) {
    const dispatcher = useDispatch();
    const [code, setCode] = useState({
        expected: '',
        real: '',
        key: ''
    });
    const [loading, setLoading] = useState(false)
    const [isEqual, setEqual] = useState(false);
    const [isRequestCode, setRequestCode] = useState(false)

    function onCodeChanged(e) {
        setCode(prevState => ({...prevState, real: e?.target?.value}));
    }

    useEffect(() => {
        let captchaText = generateCaptcha();
        setCode(prevState => ({...prevState, key: encrypt(captchaText, PASS_PHRASE)}));
        setCode(prevState => ({...prevState, expected: captchaText}));
    }, []);

    useEffect(() => {
        setEqual(code.expected === code.real);
    }, [code]);

    useEffect(() => {
        if (code.real) {

        }
    }, [code?.real])

    useEffect(() => {
        if (onEqual) {
            onEqual(isEqual);
        }
    }, [isEqual]);

    function onRequestCode(key) {
        console.log("key: "+key)
        setLoading(true)
        requestCode(key, email)
            .then(res => {
                let status = res?.data?.status;
                let message = res?.data?.message;
                dispatcher(setAlertToast({open: true, severity: 'success', text: message}));
                if (status) {
                    setRequestCode(true);
                }
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false));
    }

    function onTimerFinished() {
        setRequestCode(false)
    }

    return (
        <div className="mt-1 d-flex flex-column">
            <label className="input-label mt-1">{SUBTITLE_CONFIRM_CODE}</label>
            <input value={code?.real}
                   style={{color: isEqual ? 'green': 'red'}}
                   className="mt-1 text-center"
                   onChange={onCodeChanged}
                   type="text"
                   required
                   id="entered-captcha"
                   placeholder={CAPTION_CODE_FROM_EMAIL}/>
           {!isRequestCode ?
               <div className="d-flex align-items-center justify-content-center">
                   <div className="d-flex align-items-center justify-content-center">{loading && <div className="spinner-grow spinner-grow-sm text-danger mt-1 mx-1" role="status">{}</div>}</div>
                   <label className="mt-1 input-cursor text-center" onClick={() => onRequestCode(code.key, email)}>{SUBTITLE_REQUEST_CONFIRM_CODE}</label>
               </div> :
               <span className="d-flex align-items-center"><span className="mx-1">{SUBTITLE_REQUEST_NEW_CODE}</span><Timer initialMinute={0} initialSeconds={30} onFinished={onTimerFinished}/></span>}
        </div>
    );
}