import React, {useEffect, useState} from "react";
import {
    AD_RULES,
    ADS_RULES_HEADER,
    ADS_RULES_WISH, CAPTION_ADS,
    CAPTION_YOUR_ADS, LABEL_ADS, LABEL_ALL_MESSAGES, MSG_REMOVE_ALL_USER_ADS,
} from "../../Constants/TextMessagesRu";
import {Pagination} from "@mui/lab";
import {Helmet} from "react-helmet";
import IconSubTitle from "../../Components/Header/IconSubTitle";
import CardTextSvg from "../../Svg/CardTextSvg";
import PlusSvg from "../../Svg/PlusSvg";
import IconFab from "../../Components/Fabs/IconFab";
import {getEnvOfStorage} from "../../Stores/Env";
import ExclamationCircleSvg from "../../Svg/ExclamationCircleSvg";
import UserAdItem from "../../Components/UserAds/UserAdItem";
import UserAdAdminItem from "../../Components/UserAds/UserAdAdminItem";
import {useDispatch, useSelector} from "react-redux";
import {selectUserAds, setCounter, setPageAds, setUserAds} from "../../Stores/slices/UserAdSlice";
import {createUserAd, deleteAllUserAds, updateUserAd, userAds} from "../../api/UserAds";
import UserAdItemSkeleton from "../../Components/Skeletons/UserAdItemSkeleton";
import CreateEditUserAdModal from "../../Components/Forms/CreateEditUserAdModal";
import {setAlertToast} from "../../Stores/slices/ToastSlice";
import {clearErrorMessage} from "../../api/ApiErrorHandler";
import TrashSvg from "../../Svg/TrashSvg";
import {Chip, IconButton} from "@mui/material";
import ContentDialog from "../../Components/Forms/ContentDialog";
import {selectPaidOptions} from "../../Stores/slices/PaidStatusSlice";
import {appendYaAds} from "../../services/Yandex";

const UserAds = ({profile}) => {
    const {ads, page, counter} = useSelector(selectUserAds);
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [openRulesDialog, setRulesDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const {payTags} = useSelector(selectPaidOptions);
    let skeletons = [1,2,3,4,5,6,7,8,9];
    const [userAd, setUserAd] = useState({id: null, body: "", locality: "", region: "", country: "", userId: profile?.id})

    function changePage(e, value) {
        dispatch(setPageAds(value-1));
    }

    function closeRulesDialog() {
        setRulesDialog(false);
    }

    function showRulesDialog() {
        setRulesDialog(true);
    }

    function closeDialog(userAd, isEdit) {
        if (!isEdit) {
            if (userAd) {
                createUserAd(userAd)
                    .then(res => {
                        dispatch(setCounter())
                        setOpenCreateDialog(false)
                    })
                    .catch(err => {
                        let message = clearErrorMessage(err);
                        if (message) {
                            dispatch(setAlertToast({open: true, severity: 'error', text: message}));
                        }
                    })
            }
        } else {
            if (userAd) {
                updateUserAd(userAd, profile?.id)
                    .then(res => {
                        dispatch(setCounter())
                        setOpenEditDialog(false)
                    })
                    .catch(err => {
                        let message = clearErrorMessage(err);
                        if (message) {
                            dispatch(setAlertToast({open: true, severity: 'error', text: message}));
                        }
                    })
            }
        }

        if (userAd === null) {
            setOpenCreateDialog(false)
            setOpenEditDialog(false);
        }
    }

    function createAd() {
        setOpenCreateDialog(true);
    }

    function deleteAll() {
        if (window.confirm(MSG_REMOVE_ALL_USER_ADS)) {
            setLoading(true);
            deleteAllUserAds(profile?.id)
                .then(res => {
                    dispatch(setCounter())
                })
                .catch(err => {
                    let message = clearErrorMessage(err) ?? err;
                    console.error(message)
                })
                .finally(() => {setLoading(false);})

        }
    }

    function editAd(userAd) {
        setOpenEditDialog(true);
        setUserAd(userAd);
    }

    function getUserAdsByPage(userId, page) {
        setLoading(true);
        userAds(page, userId).then(res => {
            let data = res?.data;
            if (data) {
                dispatch(setUserAds({ads: data?.data, total: data?.total, pageSize: data?.size}));
            }
        }).catch(err => {
            console.error(err);
        }).finally(() => setLoading(false));
    }

    function toPayOptions() {
        window.location.href  = `${getEnvOfStorage()?.payUrl}/pay/options`;
    }

    function landingAds() {
        window.location.href = `${getEnvOfStorage()?.homeSiteUrl}/objiavlenia`;
    }

    useEffect(() => {
        getUserAdsByPage(profile?.id, page);
    }, [page, counter]);

    useEffect(() => {
        appendYaAds("yandex_rtb_R-A-2450444-11", "R-A-2450444-11")
    }, [])

    return (
        <>
            <div className="d-flex flex-row justify-content-start h-100 mx-0 opacity-90">
                <Helmet>
                    <title>Объявления о знакомстве. Ночной чат</title>
                </Helmet>
                <div className="scroll-y d-none d-sm-none d-md-none d-lg-flex d-xl-flex d-xxl-flex opacity-90 bg-white w-30 border-2 border p-3 flex-column">
                    <div className="d-flex align-content-center justify-content-center mt-4">
                        <div className="ads-320-90" id={`yandex_rtb_R-A-2450444-${11}`}>{LABEL_ADS}</div>
                    </div>
                    <div className="d-flex align-items-center my-3">
                        <ExclamationCircleSvg color={'#f00'}/>
                        <h4 className="p-0 my-0 mx-2">{ADS_RULES_HEADER}</h4>
                    </div>
                    <ul className="list-unstyled p-1">
                        {AD_RULES.map((item, key) => {
                            return (<li key={key} className="border-2 border-bottom border-danger mb-3 p-1">{`${item.num}.  ${item.text}`}</li>)
                        })}
                    </ul>
                    <h3 className="text-center text-primary">{ADS_RULES_WISH}</h3>
                </div>
                <div className="w-60 d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex flex-column bg-white flex-grow-1 border-2 border p-3 ">
                    <IconSubTitle text={CAPTION_ADS} icon={<CardTextSvg />}/>
                    <hr/>
                    <div className="d-flex flex-column h-92">
                        <div className="d-flex flex-column h-90">
                            <div className="d-flex align-items-center">
                                <IconFab
                                    fabStyle={{position: 'relative'}}
                                    icon={<PlusSvg color={"#fff"}/>}
                                    bgColor={"secondary"}
                                    iconColor={'#FFF'}
                                    disable={!payTags?.deleteChats}
                                    size={"small"}
                                    onClick={createAd}
                                />
                                <div className="mx-2">
                                    <IconFab
                                        fabStyle={{position: 'relative'}}
                                        icon={<TrashSvg color={"#fff"}/>}
                                        bgColor={"primary"}
                                        iconColor={'#FFF'}
                                        disable={ads?.ads?.length === 0}
                                        size={"small"}
                                        onClick={deleteAll}
                                    />
                                </div>
                                <div onClick={toPayOptions} className={`${!payTags?.deleteChats ? 'paid-stamp chat-stamp': ''} mx-2`}>{}</div>
                            </div>
                            <div className=" mt-3 mx-1 border-danger border-2 d-sm-flex d-md-none d-lg-none d-xl-none d-xxl-none">
                                <IconButton onClick={showRulesDialog} className="p-0 m-0">
                                    <Chip icon={<ExclamationCircleSvg color={"#F00"}/>} label={ADS_RULES_HEADER.toUpperCase()} sx={{color: "red", padding: 1}} className="fw-bold border-danger" variant={"outlined"}/>
                                </IconButton>
                            </div>
                            <div className="border my-2 border-2 rounded d-flex flex-column flex-grow-1 inherit-height p-1 ">
                                {loading ? <>{skeletons.map((item, i) => <div key={i}><UserAdItemSkeleton /></div>)}</> :
                                    <>
                                        {ads?.ads?.length > 0 ?
                                            ads?.ads.map((item, key) => {
                                                return <div key={key}>
                                                    <UserAdItem userAd={item?.userAd} profile={item?.userProfile} editHandler={editAd}/>
                                                </div>
                                            }) : <UserAdAdminItem/>}
                                    </>}
                            </div>
                        </div>
                        <div className="d-flex justify-content-center align-content-center">
                            <Pagination onChange={changePage} page={page} count={Math.floor(ads?.total/ads?.pageSize)+1} color="primary" />
                        </div>
                        <span className="d-flex justify-content-end align-items-center">
                           <span onClick={landingAds} className="btn btn-warning">{LABEL_ALL_MESSAGES.toUpperCase()}</span>
                        </span>
                    </div>
                </div>
            </div>
            <CreateEditUserAdModal handleClose={closeDialog} open={openCreateDialog} profile={profile} isEdit={false}/>
            <CreateEditUserAdModal handleClose={closeDialog} open={openEditDialog} profile={profile} isEdit={true} userAd_={userAd}/>
            <ContentDialog
                content={<ul className="list-unstyled p-1">
                {AD_RULES.map((item, key) => {
                    return (<li key={key} className="border-2 border-bottom border-danger mb-3 p-1">{`${item.num}.  ${item.text}`}</li>)
                })}
            </ul>} handleClose={closeRulesDialog} open={openRulesDialog} title={ADS_RULES_HEADER}/>
        </>
    );
}

export default React.memo(UserAds);