import imageCompression from "browser-image-compression";


export async function compressImageFile(file) {
    let fileName = file?.name;

    const options = {
        maxSizeMB: 0.2,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
        alwaysKeepResolution: true,
    }

    let compressedFile = null;
    try {
        compressedFile = await imageCompression(file, options);
        compressedFile = new File([compressedFile], fileName)
    } catch (error) {
        console.error(error);
    }

    if (!compressedFile) {
        compressedFile = file;
    }

    return compressedFile;
}