import {setAlertToast} from "../Stores/slices/ToastSlice";

export function clearErrorMessage(err) {
    let json = err.response?.data;
    if (json) {
        let message = json?.errorMessage ?? '';
        message = message.replace("400 BAD_REQUEST", "");
        message = message.replace("401 UNAUTHORIZED", "");
        //message = Array.from(message).filter(chr => (chr !== '"')).concat(item => item);
        console.error(JSON.stringify(message));
        return message;
    }
    return '';
}