import {createSlice} from "@reduxjs/toolkit";
import {getStoredValueByKey, storeValue} from "../Env";


export const loadFilesSlice = createSlice({
    name: 'filesChanged',
    initialState: {
        fileListChanged: 0,
        favoriteImageFileName: getStoredValueByKey('FAVORITE_IMAGE'),
    },
    reducers: {
        setFilesChanged: (state, action) => {
            state.fileListChanged += 1;
        },
        setFavoriteImgFileName: (state, action) => {
            state.favoriteImageFileName = action.payload;
            storeValue('FAVORITE_IMAGE', action.payload);
        }
    }
});

export const selectFilesChange = (state) => state.filesChanged;
export const {setFilesChanged, setFavoriteImgFileName} = loadFilesSlice.actions;
export default loadFilesSlice.reducer;