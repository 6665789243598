import axios from "axios";
import {getEnvOfStorage} from "../Stores/Env";
import {getHeaderBearerConfigs, getHeaderBearerWithDataConfigs, TOKEN_KEY} from "./ApiCommon";

/**
 * Получить список объявлений пользователя
 * @param page
 * @param userId
 * @param token
 * @returns {Promise<AxiosResponse<any>>}
 */
export function userAds(page, userId) {
    let query = `?page=${page}&user_id=${userId}`;
    return axios.get(`${getEnvOfStorage()?.dataUrl}/api/user/ads${query}`);
}

/**
 * Создать объявление
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function createUserAd(data) {
    let token = localStorage.getItem(TOKEN_KEY);
    return axios.post(`${getEnvOfStorage()?.dataUrl}/api/user/ad`, data, getHeaderBearerConfigs("application/json", token));
}

/**
 * Редактировать объявление
 * @param data
 * @param userId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function updateUserAd(data, userId) {
    let token = localStorage.getItem(TOKEN_KEY);
    return axios.put(`${getEnvOfStorage()?.dataUrl}/api/user/ad?user_id=${userId}`, data, getHeaderBearerConfigs("application/json", token));
}

/**
 * Удалить одно или группу объявлений
 * @param data
 * @param userId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function deleteUserAd(data, userId) {
    let token = localStorage.getItem(TOKEN_KEY);
    return axios.delete(`${getEnvOfStorage()?.dataUrl}/api/user/ad?user_id=${userId}`, getHeaderBearerWithDataConfigs("application/json", token, data));
}

/**
 * Удалить все объявления пользователя
 * @param userId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function deleteAllUserAds(userId) {
    let token = localStorage.getItem(TOKEN_KEY);
    return axios.delete(`${getEnvOfStorage()?.dataUrl}/api/user/ad/all?user_id=${userId}`, getHeaderBearerConfigs("application/json", token));
}