import React, {useEffect, useRef, useState} from "react";
import {Alert, Snackbar} from "@mui/material";
import AvatarItem from "../Avatars/AvatarItem";
import {getEnvOfStorage} from "../../Stores/Env";


/**
 *
 * @param {boolean} open
 * @param {ChatMessage} messageBody
 * @param onClose
 * @return {JSX.Element}
 * @constructor
 */
const ChatToast = ({open, messageBody, onClose}) => {
    const [isOpen, setOpen] = useState(false);

    const fromUser = messageBody?.fromUser;
    const content = messageBody?.content;
    const imgSrc = fromUser?.thumbUrl?.src ? `${getEnvOfStorage()?.staticUrl}${fromUser?.thumbUrl?.src}` : '';

    useEffect(() => {
        setOpen(open);
    }, [open]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        onClose();
        setOpen(false);
    }

    return (
        <div>
            {messageBody &&
            <Snackbar open={isOpen} anchorOrigin={{vertical: 'top', horizontal: 'center'}} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose}
                       icon={<AvatarItem src={imgSrc}
                                         alt={fromUser?.firstName}/>}
                       sx={{ width: '100%' }}>
                    <div className="d-flex flex-column justify-content-start">
                        <span><b>{`${fromUser?.firstName} ${fromUser?.lastName}, ${fromUser?.age}`}</b></span>
                        <span className="text-constraint crop">{content?.message}</span>
                    </div>
                </Alert>
            </Snackbar>}
        </div>
    );
}

/*export default React.memo(ChatToast, (prevProps, nextProps) => {
    return arrayEquals(prevProps?.messageBody?.content, nextProps?.messageBody?.content)
});*/
export default ChatToast;