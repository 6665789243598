import React, {useEffect, useState} from "react";
import {CAPTION_EMPTY_PROFILES, kidsVal, LOCATION_TYPES} from "../../Constants/TextMessagesRu";
import BottomDrawer from "../../Components/Drawers/BottomDrawer";
import SearchBox from "./SearchBox";
import IconFab from "../../Components/Fabs/IconFab";

import {searchUserProfiles, userProfile} from "../../api/ChatDataApi";
import {useDispatch, useSelector} from "react-redux";
import {
    fullDropSearchedProfiles, incPageSearchedProfiles,
    selectSearchedProfiles,
    setSearchedProfiles,
    stopLoadSearchedProfiles
} from "../../Stores/slices/SearchProfilesSlice";

import VirtualGridScrollLoader from "../../Components/VirtualLists/ListGrids/VirtualGridScrollLoader";
import {Chip} from "@mui/material";
import UserProfilesSkeletons from "../../Components/Skeletons/UserProfilesSkeletons";
import {selectSearchBox, setSearchParams} from "../../Stores/slices/SearchBoxSlice";
import {PROFILES_PAGE_SIZE, SHARE_BANNER} from "../../api/ApiCommon";
import {getStoredValueByKey, KEY_INIT_SEARCH, SEARCH_PROFILE_OFFSET, storeValue} from "../../Stores/Env";
import {SearchSvg} from "../../Svg";
import ShareModal from "../../Components/Forms/ShareModal";
import {selectCommon} from "../../Stores/slices/CommonSlice";
import {selectPaidOptions} from "../../Stores/slices/PaidStatusSlice";


const fabStyle = {
    position: 'absolute',
    top: 25,
    right: 36,
    zIndex: 999,
    opacity: 0.9
};

let defSearchParams = null;

let stopLoading = false;

/**
 * Компанент, показывающий список профилей для поиска пользователя по критерию
 * @param profiles
 * @returns {JSX.Element}
 * @constructor
 */
const SearchProfiles = ({userId}) => {
    const [openSearch, setOpenSearch] = useState(false);
    const [openShareModal, setOpenShareModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const searchDispatcher = useDispatch();
    const {page, profiles, isEmptyData} = useSelector(selectSearchedProfiles);
    const {searchParams, defLocationType} = useSelector(selectSearchBox);
    const {myProfile} = useSelector(selectCommon);
    const {payTags} = useSelector(selectPaidOptions);

    function dropParams() {
        searchDispatcher(fullDropSearchedProfiles());
    }

    useEffect(() => {
       stopLoading = isEmptyData;
    }, [isEmptyData]);

    useEffect(() => {
        if (!isEmptyData) {
            loadNextPage(0);
        }
    }, []);

    useEffect(() => {
        defSearchParams = searchParams;
    }, [searchParams])

    function onSearch(params) {
        stopLoading = false;
        defSearchParams = params;
        dropParams();

        let searchBody = {};
        Object.assign(searchBody, params);
        searchBody.kids = kidsVal(params.kids);

        loadNextPage(0);
        setOpenSearch(false);
    }

    /**
     * Запросить у api искомых пользователей постранично
     * @param {number} page
     */
    function loadNextPage(page) {
           if (!stopLoading) {
               setLoading(true);
               searchUserProfiles(userId, page, PROFILES_PAGE_SIZE, searchParams, ((data, err) => {
                   if (!err) {
                       if ((data?.data) && (data?.data?.length > 0)) {
                           searchDispatcher(setSearchedProfiles(data?.data?.filter(item => item?.id !== userId)));
                       } else {
                           searchDispatcher(stopLoadSearchedProfiles());
                       }
                       showShareBannerOnce(data?.data, page)
                   } else {
                       searchDispatcher(stopLoadSearchedProfiles());
                   }
                   setLoading(false);
               }));
           }
    }

    function showShareBannerOnce(data, page) {
        if ((page === 0) && (!getStoredValueByKey(SHARE_BANNER)) && (data?.length < PROFILES_PAGE_SIZE)) {
            setOpenShareModal(true);
        }
    }

    function incPage() {
        searchDispatcher(incPageSearchedProfiles());
    }

    function onCancelSearch() {
        setOpenSearch(false);
    }

    function closeShareModal() {
        setOpenShareModal(false)
        storeValue(SHARE_BANNER, true);
    }

    //TODO -- временно отключить платный режим поиска по городам (вызывал колизии)
    /*useEffect(() => {
        if (payTags?.changeLocation) {
            searchDispatcher(setSearchParams({
                locality: (defLocationType === LOCATION_TYPES[1].value ? null : searchParams?.locality),
                region: searchParams?.region,
                country: searchParams?.country}));
        } else {
            searchDispatcher(setSearchParams({
                locality: (defLocationType === LOCATION_TYPES[1].value ? null : myProfile?.locality),
                region: myProfile?.region,
                country: myProfile?.country}));
        }

    }, [defLocationType])*/


    return (
        <div className="d-block h-100 position-relative p-sm-0 p-md-1 p-lg-1">
            {((loading) && (profiles?.length < PROFILES_PAGE_SIZE)) && <UserProfilesSkeletons count={PROFILES_PAGE_SIZE} />}
            {profiles?.length !== 0 ?
                <VirtualGridScrollLoader
                    onNextPage={loadNextPage}
                    content={profiles}
                    isEmptyData={isEmptyData}
                    page={page}
                    incPage={incPage}
                    loading={loading}
                    keyScrollOffset={SEARCH_PROFILE_OFFSET}
                /> :
                <div className="d-flex justify-content-center flex-row align-items-center h-100">
                    <Chip label={CAPTION_EMPTY_PROFILES.toUpperCase()} sx={{color: "black", bgcolor: 'white'}} className="fw-bold" variant={"outlined"}/>
                </div>}
            <BottomDrawer isOpen={openSearch} onClosed={() => {setOpenSearch(false)}}>
                <SearchBox onClose={onSearch} onCancel={onCancelSearch} defLocationType={defLocationType} myProfile={myProfile}/>
            </BottomDrawer>
            <IconFab
                icon={<SearchSvg color={"#fff"} w={18} h={18}/>}
                bgColor={"primary"}
                ariaLabel={'add'}
                iconColor={'primary.contrastText'}
                fabStyle={fabStyle}
                onClick={() => {setOpenSearch(!openSearch)}}
            />
            <ShareModal open={openShareModal && (!getStoredValueByKey(SHARE_BANNER))} handleClose={closeShareModal}/>
        </div>
    );
};

export default React.memo(SearchProfiles);