import React, {useEffect, useState} from "react";
import RoundSubstrate from "../../Svg/Sunstrate/RoundSubstrate";
import {
    CAPTION_SEND,
    CAPTION_SIGN,
    CAPTION_YOUR_EMAIL,
    MSG_CHECK_YOUR_EMAIL,
    MSG_INVALID_EMAIL_FORMAT,
    MSG_USER_NOT_FOUND,
    SUBTITLE_RESET_PASSWORD,
    SUBTITLE_YOUR_EMAIL
} from "../../Constants/TextMessagesRu";
import {ROUTE_SIGNUP} from "../../Constants/Routes";
import {Button} from "@mui/material";
import {validateEmailFormat} from "../../services/Validation";
import {resetPassword} from "../../api/AuthApi";
import {AlertToast} from "../../Components/Toasts/AlertToast";
import {useNavigate} from "react-router-dom";
import SimpleCaptcha from "../../Components/Recaptchas/SimpleCaptcha";
import KeyFillSvg from "../../Svg/KeyFillSvg";


const ResetPassword = () => {
    const navigate = useNavigate();
    const [isValidCaptcha, setValidCaptcha] = useState(false);
    const [reqLoading, setReqLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [toast, setToast] = useState({
        success: true,
        open: false,
    });
    const [isValidEmail, setValidEmail] = useState({
        valid: false,
        email: '',
    });

    const onEmailChanged = (e) => {
         setValidEmail(prevState => ({...prevState, email: e?.target?.value, valid: validateEmailFormat(e?.target?.value)}));
    }

    const onSend = () => {
        setToast(prevState => ({...prevState, success: false, open: false}));
        setReqLoading(true)
        resetPassword(isValidEmail.email).then(res => {
            setToast(prevState => ({...prevState, success: true, open: true}));
            setTimeout(() => {
                navigate(ROUTE_SIGNUP);
            }, 2000);
        }).catch(e => {
            setValidEmail(prevState => ({...prevState, email: "", valid: false}));
            setToast(prevState => ({...prevState, success: false, open: true}));
        }).finally(() => setReqLoading(false));
    }

    useEffect(() => {
        setDisabled((!isValidCaptcha) || (!isValidEmail.valid));
    }, [isValidCaptcha, isValidEmail.valid]);


    function onRightCaptcha(flag) {
        setValidCaptcha(flag);
    }

    return (
        <div className="sign-background">
            <div className="container p-3 d-flex justify-content-center">
                <div className="d-flex flex-column primary-form">
                    <div className="d-flex flex-row justify-content-center align-items-center">
                        <RoundSubstrate color="orange" children={<KeyFillSvg/>}/>
                        <h4 className="mt-2 mx-1 fs-3_">{SUBTITLE_RESET_PASSWORD}</h4>
                    </div>
                    <label hidden={isValidEmail.valid}
                           className="input-label mt-1 text-danger fs-5_">{MSG_INVALID_EMAIL_FORMAT}</label>
                    <label className="input-label mt-1 fs-5_">{SUBTITLE_YOUR_EMAIL}</label>
                    <input value={isValidEmail.email} className="input_field fs-5_" placeholder={CAPTION_YOUR_EMAIL}
                           type="email" required onChange={onEmailChanged}/>

                    <SimpleCaptcha onEqual={onRightCaptcha}/>

                    <Button disabled={disabled || reqLoading} variant={'contained'} className="mt-3"
                            onClick={onSend}>{CAPTION_SEND.toUpperCase()}</Button>
                    <div className="mt-3 d-flex justify-content-center">
                        <a className="link fs-5_" href={ROUTE_SIGNUP}>{CAPTION_SIGN.toUpperCase()}</a>
                    </div>
                </div>
            </div>
            <AlertToast open={toast.open} success={toast.success} text={toast.success ? MSG_CHECK_YOUR_EMAIL: MSG_USER_NOT_FOUND}/>
        </div>
    );
}

export default ResetPassword;