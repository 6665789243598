import React, {useEffect, useRef, useState} from 'react';
import {
    Chip,
    Divider,
    FormControl,
    IconButton,
    Input,
    InputLabel,
    LinearProgress,
    Stack,
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {
    CAPTION_CHATS,
    CAPTION_EMPTY_CHAT,
    LABEL_SEARCH_FOR_NAME,
    MSG_WISH_DELETE_SELECTED_CHATS
} from "../../../Constants/TextMessagesRu";
import {PROFILE_CHATS_PAGE_SIZE} from "../../../api/ApiCommon";
import {selectCommon, setChatSelectedUser} from "../../../Stores/slices/CommonSlice";
import VirtualList from "../../../Components/VirtualLists/VirtualList";
import {getUserProfileChatsPageable} from "../../../api/ChatApi";
import {
    dropPageUsersChats, dropRemovingChats, dropUpChats,
    incPageUsersChats,
    selectUsersChats, setRemoveChatsEnabled, setSearch,
    setUsersChats, startLoadUsersChats,
    stopLoadUsersChats
} from "../../../Stores/slices/ChatListSlice";
import {setAlertToast} from "../../../Stores/slices/ToastSlice";
import {useNavigate} from "react-router";
import {ROUTE_SIGNUP} from "../../../Constants/Routes";
import useWindowDimensions from "../../../Hooks/useWindowDimension";
import DeleteControlTool from "../../../Components/ActionButtons/DeleteControlTool";
import PaymentTagAvatars from "../../../Components/Avatars/PaymentTagAvatars";
import {selectPaidOptions} from "../../../Stores/slices/PaidStatusSlice";
import {ChatFillSvg, SearchSvg} from "../../../Svg";
import UserChatSkeleton from "../../../Components/Skeletons/UserChatSkeleton";

let _chatSelectedUser = null;
let searchMask = '';


/**
 * Компонент историй переписки для данного пользователя
 * @param {string} currentUserId
 * @param onSelected
 * @param stomp
 * @returns {JSX.Element}
 * @constructor
 */
function UserList({currentUserId, onSelected, stomp}) {
    const {chatSelectedUser} = useSelector(selectCommon);
    const [loading, setLoading] = useState(false);
    const {page, chats, isEmptyData, search, selectedUserChats} = useSelector(selectUsersChats);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {width} = useWindowDimensions();
    const {payTags} = useSelector(selectPaidOptions);
    const mockChats = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16];

    useEffect(() => {
        if (chats.length === 0) {
            getChats();
        }
        /**Сбросить флаг разрешения удаления историй чатов*/
        dispatch(setRemoveChatsEnabled(false));
    }, []);

    useEffect(() => {
        if ((chatSelectedUser === null) && (chats?.length > 0) && (width >= 992)) {
            dispatch(setChatSelectedUser(chats[0]?.userProfile));
        }
    }, [chatSelectedUser, chats, width]);

    useEffect(() => {
        let timeOutId = null;
        if (searchMask !== search) {
            timeOutId = setTimeout(() => {
                dispatch(startLoadUsersChats())
                searchMask = search;
                onSearch()
            }, 1000);
        }
        return () => clearTimeout(timeOutId);
    }, [search])

    /**
     * Запросить историю переписки для текущего пользователя постранично
     * @param {number} page
     * @returns {Promise<void>}
     */
    const loadChatsHistoryNextPage = (page) => {
       setLoading(true);
       getUserProfileChatsPageable(page, PROFILE_CHATS_PAGE_SIZE, currentUserId, searchMask)
       .then(response => {
           dispatch(setUsersChats(response?.data));
           if (response?.data?.length === 0) {
               dispatch(stopLoadUsersChats());
           }
       }).catch(err => {
           if (err.toString()?.indexOf("403") >= 0) {
               navigate(ROUTE_SIGNUP);
           }
       }).finally(() => {
           setLoading(false);
       })
    }


    useEffect(() => {
        if ((chatSelectedUser) && (_chatSelectedUser !== chatSelectedUser)) {
            getUserChat(chatSelectedUser);
            _chatSelectedUser = chatSelectedUser;
        }
    }, [chatSelectedUser]);


    function getUserChat(user) {
        if (user?.id) {
            //Переключиться на область переписки
            onSelected(user?.id);
        }
    }

    /**
     * Запросить переписку для текущего и выбранного пользователей при выборе чата из списка
     * @param item
     */
    function clickItem(item) {
        getUserChat(item?.itemData?.userProfile);
        if (item?.itemData?.userProfile) {
            dispatch(setChatSelectedUser(item?.itemData?.userProfile));
        }
    }

    function incPage() {
        dispatch(incPageUsersChats());
    }

    function onSearch() {
        dispatch(dropPageUsersChats(0));
        loadChatsHistoryNextPage(0);
    }

    function getChats() {
        loadChatsHistoryNextPage(0);
    }

    const removeError = (err) => {
        dispatch(setAlertToast({open: true, text: err, severity: 'warning'}));
    }

    function onDeleteChatHistory() {
        if (!payTags?.deleteChats) return;

        if (stomp) {
            if (window.confirm(MSG_WISH_DELETE_SELECTED_CHATS)) {
                stomp.removeChats(currentUserId, selectedUserChats, removeError);
            }
        }
    }

    function cancelRemovingChats() {
        dispatch(dropRemovingChats())
    }

    function onMenuSelectItem(item) {
        if (!payTags?.deleteChats) return;
        if (item?.value === 'select') {
            dispatch(setRemoveChatsEnabled(true));
        }
    }

    return (
      <div className="p-sm-1 p-md-2 p-lg-2 d-flex flex-column h-100">
          <div className="d-flex flex-column">
              <div className="d-flex flex-row justify-content-between align-items-center ">
                  <span style={{color: '#e7641d'}} className="flex-grow-1 fs-3 mx-2 mt-2">{CAPTION_CHATS}</span>

                  <PaymentTagAvatars />
                  {/*Удаление элементов списка истории чатов*/}
                  {payTags?.deleteChats &&
                  <DeleteControlTool onDelete={onDeleteChatHistory} onMenuSelectItem={onMenuSelectItem} disabledDel={selectedUserChats?.length === 0}/>}
              </div>
              <div onClick={cancelRemovingChats} className="my-0 mx-1 p-0 d-flex flex-column ">
                  <div className="d-flex p-0 justify-content-between position-relative border rounded-pill border-success my-2">
                      <input className="d-flex flex-grow-1 fs-4_  border-0 mx-3 my-0 py-1" placeholder={LABEL_SEARCH_FOR_NAME} value={search} onChange={(e) => {dispatch(setSearch(e.target.value))}}/>
                  </div>
                  <Divider/>
              </div>
          </div>


          <div className="h-100 overflow-hidden">
              {loading && (page === 0) ?
              <>
                  {mockChats?.map((item, i) => {
                     return <div key={i}>
                         <UserChatSkeleton />
                      </div>
                  })}
              </> :
                  <>
                      {chats?.length > 0 ?
                          <VirtualList onSelected={clickItem}
                                       content={chats}
                                       onNextPage={loadChatsHistoryNextPage}
                                       isEmptyData={isEmptyData}
                                       page={page}
                                       incPage={incPage}
                          /> :
                          <div className="d-flex justify-content-center align-items-center h-100 flex-row mt-2">
                              <div className="d-flex justify-content-center align-items-center">
                                  <ChatFillSvg/>
                                  <span className="mx-1">{CAPTION_EMPTY_CHAT.toUpperCase()}</span>
                              </div>
                          </div>}
                  </>}
          </div>
          {loading ?
              <Stack sx={{ width: '100%', color: 'grey.500', height: 4}} spacing={2}>
                  <LinearProgress color="success" />
              </Stack> : <div className="w-100" style={{height: 4}}>{}</div>}
      </div>
    );
}

export default React.memo(UserList);