import {
    Box,
    FormControl,
    FormControlLabel,
    IconButton,
    Modal,
    Radio,
    RadioGroup,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {
    CAPTION_CHARS_OF,
    CAPTION_DESCRIBE_PROBLEM,
    CAPTION_SEND,
    LABEL_ERROR,
} from "../../Constants/TextMessagesRu";
import SimpleCaptcha from "../Recaptchas/SimpleCaptcha";
import {createSupportMsg} from "../../api/SupportApi";
import {LoadingButton} from "@mui/lab";
import XSvg from "../../Svg/XSvg";


const SupportFormModal = ({open, handleClose, data, userExchanges}) => {
    const [text, setText] = useState("");
    const [messageType, setMessageType] = useState(data?.radioData[0]?.value)
    const [error, setError] = useState(false);
    const [validCaptcha, setValidCaptcha] = useState(false)
    const [lock, setLock] = useState(true)
    const [loading, setLoading] = useState(false);
    const maxLen = 800;
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 450,
        minWidth: 340,
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 2,
        borderRadius: 2,
        p: 1
    };

    useEffect(() => {
        setError(false);
        setLock(!((text?.length > 0) && (validCaptcha)))
    }, [text, validCaptcha])

    function onTextAreaChange(e) {
        setText(e?.target?.value);
    }

    function onSelectRadio(e) {
       setMessageType(e?.target?.value);
    }

    function createSupportMessage(message, msgType) {
        setLoading(true);
        let body = {
            id: "",
            fromUserId: userExchanges?.fromUserId,
            onUserId: userExchanges.onUserId,
            messageType: msgType,
            messageBody: message
        }
        createSupportMsg(body)
            .then(res => {
                handleClose(res?.data);
            })
            .catch(err => {console.warn(err); setError(true)})
            .finally(() => setLoading(false));
    }

    function onEqualCaptcha(valid) {
        setValidCaptcha(valid);
    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description">
                <Box sx={{ ...style }}>
                    <div className="d-flex flex-row flex-grow-1 p-0 justify-content-end align-items-center">
                        <IconButton onClick={handleClose}>
                            <XSvg color={'#F00'}/>
                        </IconButton>
                    </div>
                    <div className="px-3">
                        <h4 className="fs-4_">{data?.title}</h4>

                        <FormControl className="mt-2">
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={data?.radioData[0]?.value}
                                name="radio-buttons-group" onChange={onSelectRadio}>
                                {data?.radioData?.map((item) => <span key={item?.value} className="fs-5_">
                                    <FormControlLabel value={item?.value} control={<Radio className="p-1"/>} label={item?.caption} />
                                </span>)}
                            </RadioGroup>
                        </FormControl>
                        <div className="d-flex flex-column align-items-start mt-1">
                            <textarea rows={6}
                                      cols={9}
                                      onChange={onTextAreaChange}
                                      maxLength={maxLen}
                                      placeholder={CAPTION_DESCRIBE_PROBLEM}
                                      className=" px-2 py-1 w-100 fs-5_"/>
                            <label className="fs-5_ fw-bold">{`${CAPTION_CHARS_OF} ${text?.length}/${maxLen}` }</label>
                        </div>
                        <div className="d-flex justify-content-start"><SimpleCaptcha onEqual={onEqualCaptcha}/></div>
                        <div className="d-flex justify-content-between align-items-center my-4">
                            <span className="fs-5_ text-danger" style={{visibility: error ? 'visible': 'hidden'}}>{LABEL_ERROR}</span>
                            <LoadingButton disabled={lock}
                                           variant={"contained"}
                                           loading={loading}
                                           onClick={() => createSupportMessage(text, messageType)}>
                                {CAPTION_SEND}
                            </LoadingButton>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}

export default SupportFormModal;