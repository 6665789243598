import Picker from "@emoji-mart/react";
import React from "react";
import XSvg from "../../Svg/XSvg";
import {LABEL_CLOSE} from "../../Constants/TextMessagesRu";

export default function EmojiPicker({onSelectItem, disabled, onClose}) {
    let emojis = async () => {
        const response = await fetch(
            'https://cdn.jsdelivr.net/npm/@emoji-mart/data',
        )
        return response.json()
    }

    return (
        <div className={`${disabled ? 'disabled-tag-lock': ''} emoji-picker-position`}>
            <a href="#" className="d-flex justify-content-end align-items-center text-decoration-none" onClick={onClose}><span className="text-danger fs-6_">{LABEL_CLOSE}</span><XSvg color="#f00" w={28} h={28}/></a>
            {emojis && <Picker data={emojis} onEmojiSelect={onSelectItem} />}
        </div>
    );
}