import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {increment, selectCommon, setEmojiMenu} from "../../Stores/slices/CommonSlice";

import {setAlertToast} from "../../Stores/slices/ToastSlice";
import {IconButton} from "@mui/material";
import EmojiPicker from "../../Components/Emoji/EmojiPicker";
import Send from "../../Svg/Send";
import EmojiSmileSvg from "../../Svg/EmojiSmileSvg";


let chatSelectedUser_ = null;
let chatItem = {};

function MessageSendView ({stomp, currentUserId}) {
  const [rows, setRows] = useState(1);
  const [sendLoading, setSendLoading] = useState(false);
  const [content, setContent] = useState({
      id: null,
      userId: null,
      fromUserId: currentUserId,
      message: "",
      group: 0,
      isEmailed: false,
      isRead: false});
  const dispatch = useDispatch();
  const {chatSelectedUser} = useSelector(selectCommon);
  const {myProfile, emojiMenu} = useSelector(selectCommon);

  useEffect(() => {
      if (content?.message) {
          if (content?.message?.split("\n")?.length === 1) {
              setRows(1);
          }
          chatItem = content;
      }
  }, [content?.message]);


  useEffect(() => {
      setContent(prevState => ({...prevState, userId: chatSelectedUser?.id }));
      chatSelectedUser_ = chatSelectedUser;
  }, [chatSelectedUser])

  function updateInputValue(e) {
      setContent(prevState => ({...prevState, message: e?.target?.value }));
  }

  function dropTextArea() {
      setRows(1);
  }

  function stompError(err) {
      if (err) {
          console.warn(err);
          dispatch(setAlertToast({open: true, text: err, severity: 'warning'}));
      }
      setSendLoading(false);
  }

  function onSend() {
    if (chatItem?.message === "") return;

    if (stomp) {
        setSendLoading(true);
        stomp.sendChatMessage('chat', chatSelectedUser_?.firstName, chatSelectedUser_?.id, chatItem, myProfile, stompError);
        dispatch(increment());
        setContent(prevState => ({...prevState, message: ""}));
        dropTextArea();
    }
  }

  function onKey(e) {
      dispatch(setEmojiMenu(false));
      if  ((e.keyCode === 10 || e.keyCode === 13) && e.ctrlKey) {
          if (stomp) {
              onSend();
          }

          e.preventDefault();
          dropTextArea();
      } else if (e.keyCode === 13) {
          if (rows < 4) {
              setRows(rows+1);
          }
      }
  }

  function onEmojiClick(val) {
      setContent(prevState => ({...prevState, message: prevState.message.concat(val?.native) }));
      setTimeout(() => {dispatch(setEmojiMenu(false))}, 200)
  }


  function onEmojiMenu() {
      dispatch(setEmojiMenu(true));
  }

  function onCloseEmojiPicker() {
      dispatch(setEmojiMenu(false));
  }

  return (
    <div className="chat-msg-sender d-flex flex-row align-items-center justify-content-between position-relative">
          {emojiMenu &&
              <EmojiPicker disabled={chatSelectedUser === null} onSelectItem={onEmojiClick} onClose={onCloseEmojiPicker}/>
          }
          <IconButton onClick={onEmojiMenu} className="d-none d-sm-none d-md-flex">
              <EmojiSmileSvg w={24} h={24} color={'#f55f0d'}/>
          </IconButton>
          <div className="d-flex flex-column position-relative flex-grow-1">
              <textarea disabled={chatSelectedUser === null}
                        value={content?.message}
                        rows={rows}
                        maxLength={500}
                        onClick={onCloseEmojiPicker}
                        onTouchEnd={onCloseEmojiPicker}
                        className="chat-msg-input text-dark form-control flex-grow-1 m-0"
                        onKeyDown={onKey}
                        onChange={updateInputValue}
                        placeholder="Здесь введите сообщение" />
          </div>
          <IconButton onClick={onSend}
                      disabled={(chatSelectedUser === null) || (!content.message) || (sendLoading)}
                      color={"secondary"}
                      size="large"
                      className="mx-2 p-0">
                <Send w={24} h={24} color={"#01923b"}/>
          </IconButton>
          {sendLoading && <div className="spinner-grow spinner-grow-sm text-danger position-absolute spinner-top" role="status">{}</div>}
    </div>
  )
};


export default MessageSendView;
