import {createSlice} from "@reduxjs/toolkit";
import {
    findAndReplaceChats,
    findAndUpdateReadChatMessages,
    findProfileChatAndAddChatItem,
    findProfileChatAndSubChatItem
} from "../../services/DataService";



export const chatListSlice = createSlice({
    name: 'usersChats',
    initialState: {
        upChats: 0,
        page: 0,
        chats: [],
        isEmptyData: false,
        search: '',
        selectedUserChats: [],
        chatSelect: false,
    },
    reducers: {
        setUpChats: state => {
            state.upChats += 1;
        },
        dropUpChats: state => {
            state.upChats = 0;
        },
        updateUnreadMessagesCount: (state, action) => {
            state.chats = findProfileChatAndAddChatItem(state.chats, action.payload);
        },
        updateReadMessagesCount: (state, action) => {
            state.chats = findProfileChatAndSubChatItem(state.chats, action.payload);
        },
        setUsersChats: (state, action) => {
            if (!action.payload) {
                return;
            }
            state.chats = findAndReplaceChats(state, action)
        },
        updateUsersChats: (state, action) => {
            if (!action.payload) {
                return;
            }

            state.chats = findAndUpdateReadChatMessages(action.payload, state.chats);
        },
        fullDropUsersChats: (state) => {
            state.chats = [];
            state.page = 0;
            state.isEmptyData = false;
            state.search = '';
        },
        stopLoadUsersChats: state => {
            state.isEmptyData = true;
        },
        startLoadUsersChats: state => {
            state.isEmptyData = false;
        },
        incPageUsersChats: state => {
            state.page += 1;
        },
        dropPageUsersChats: (state, action) => {
            state.page = action.payload;
            state.chats = [];
        },
        setSearch: (state, action) => {
            state.search = action.payload;
        },
        setSelectedUserChats: (state, action) => {
            if (action.payload?.checked) {
                state.selectedUserChats.push(action.payload?.userId);
            } else {
                let itemIndex = state.selectedUserChats.indexOf(action.payload?.userId);
                if (itemIndex >= 0) {
                    state.selectedUserChats.splice(itemIndex, 1);
                }
            }
        },
        dropSelectedChats: state => {
            state.selectedUserChats = [];
        },
        setRemoveChatsEnabled: (state, action) => {
            state.chatSelect = ((state?.chats?.length > 0) && (action.payload));
        },
        dropRemovingChats: state => {
            state.chatSelect = false;
            state.selectedUserChats = [];
        },
        deleteUsersChats: (state, action) => {
            let removableChatIds = action.payload;
            if ((!removableChatIds) || (removableChatIds?.length === 0)) return;
            state.chats = state.chats.filter(chat => (removableChatIds?.indexOf(chat?.userProfile?.id) < 0));
            state.selectedUserChats = [];
        },
        dropChatHistoryStates: state => {
            state.page = 0;
            state.chats = [];
            state.selectedUserChats = [];
            state.isEmptyData = false;
            state.search = "";
            state.chatSelect = false;
        }
    },
});

export const selectUsersChats = (state) => state.usersChats;
export const {
    dropChatHistoryStates,
    setUsersChats,
    fullDropUsersChats,
    stopLoadUsersChats,
    dropPageUsersChats,
    incPageUsersChats,
    updateUsersChats,
    setSearch,
    setSelectedUserChats,
    setSelectedChats,
    startLoadUsersChats,
    setRemoveChatsEnabled,
    deleteUsersChats,
    updateUnreadMessagesCount,
    updateReadMessagesCount,
    dropRemovingChats,
    setUpChats,
    dropSelectedChats,
    dropUpChats,
} = chatListSlice.actions;
export default chatListSlice.reducer;