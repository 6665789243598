import axios from "axios";
import {getHeaderBearerConfigs, TOKEN_KEY, USER_ID_KEY} from "./ApiCommon";
import {getEnvOfStorage, setCookies} from "../Stores/Env";

/**
 * Получить JWT токен в обмен на логин и пароль
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function authenticateUser(data) {
    return axios.post(`${getEnvOfStorage()?.authUrl}/login`, data, {headers: { contentType: "application/json" } });
}

/**
 * Зарегистрировать нового пользователя
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function registrateUser(data) {
    return axios.post(`${getEnvOfStorage()?.authUrl}/api/user`, data, {headers: { contentType: "application/json"}});
}

/**
 * Запросить код по почте
 * @param {string} key
 * @param {string} email
 * @returns {Promise<AxiosResponse<any>>}
 */
export function requestCode(key, email) {
    return axios.post(`${getEnvOfStorage()?.authUrl}/api/email/confirm?token=${key}&email=${email}`, {}, {headers: { contentType: "application/json"}});
}

/**
 * POST http://localhost:8081/api/user/profile
 * Зарегистрировать нового пользователя одним запросом
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function registerUserProfile(data) {
    return axios.post(`${getEnvOfStorage()?.authUrl}/api/user/profile`, data, {headers: { contentType: "application/json"}});
}

/**
 * Удалить аккаунт пользователя из сервиса авторизации и сам профиль пользователя, если он есть
 * @param userId
 * @param isAccountOnly
 * @returns {Promise<AxiosResponse<any>>}
 */
export function removeFullUserAccountData({userId, isAccountOnly}) {
    let token = localStorage.getItem(TOKEN_KEY);
    return axios.delete(`${getEnvOfStorage()?.authUrl}/api/user?user_id=${userId}&account_only=${isAccountOnly}`, getHeaderBearerConfigs("application/json", token))
}

/**
 * Запрос на изменение пароля
 * @param {string} login
 * @returns {Promise<AxiosResponse<any>>}
 */
export function resetPassword(login) {
    return axios.get(`${getEnvOfStorage()?.authUrl}/api/user/reset/pass?email=${login}`, {headers: { contentType: "application/json" } })
}

/**
 * Получить список городов по имени
 * @param {string} name
 * @param {number} locationType
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getCities(name, locationType) {
    if ((!name) || (name?.length < 1)) return new Promise(resolve => []);
    return axios.get(`${getEnvOfStorage()?.authUrl}/api/location?locality=${name}&location_type=${locationType}`, {headers: { contentType: "application/json" } })
}

/**
 * Регистрация пользователя на сервере авторизации и создание профиля на сервисе данных пользователей
 * @param credentials
 * @param profile
 * @param {function(data: object, err: any)} callback
 * @returns {Promise<AxiosResponse<*>>}
 */
export function fullRegistrationV2(credentials, profile, callback) {

    registerUserProfile({
        userCreateRequest: credentials,
        userProfile: profile
    }).then((res) => {
            let userProfile =  res?.data?.userProfile;
            let token = res?.data?.token;
            localStorage.setItem(USER_ID_KEY, userProfile?.id);
            localStorage.setItem(TOKEN_KEY, token);
            setCookies(userProfile?.id, token);
            callback(userProfile, null);
        })
        .catch(err => callback(null, err));
}

