import {IconButton} from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, {useMemo} from "react";
import {CAPTION_SELECT} from "../../Constants/TextMessagesRu";
import TrashSvg from "../../Svg/TrashSvg";
import CardCheckListSvg from "../../Svg/CardCheckListSvg";
import CheckSvg from "../../Svg/CheckSvg";

const menuItems = [{value: 'select', text: CAPTION_SELECT, icon: <CheckSvg color={'primary.main'}/>}];

const DeleteControlTool = ({disabled, disabledDel, onDelete, onMenuSelectItem}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    function handleCloseMenu(item) {
        setAnchorEl(null);
        if (onMenuSelectItem) {
            onMenuSelectItem(item);
        }
    }

    function onOpenMenu(e) {
        setAnchorEl(e.currentTarget);
    }

    return (
        <div>
            <div className=" p-0 d-flex flex-row align-items-center">
                <IconButton disabled={disabledDel || disabled} sx={{color: "#f00"}} aria-label="delete" onClick={onDelete}>
                    <TrashSvg disabled={disabledDel || disabled} w={18} h={18} color={"#f00"}/>
                </IconButton>
                <IconButton id="menu-button"
                            disabled={disabled}
                            onClick={onOpenMenu} sx={{color: "secondary.main"}}
                            aria-controls={open ? 'delete-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}>
                    <CardCheckListSvg  color={"#01923b"}/>
                </IconButton>
                <Menu
                    id="delete-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseMenu}
                    className="p-0 m-0"
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}>
                    {useMemo(() => menuItems.map((item, index) => (
                        <MenuItem className="px-1 m-0" key={index} onClick={() => handleCloseMenu(item)}>
                            <div className="d-flex align-items-center p-0 m-0">
                                {item.icon}
                                <span className="flex-grow-1 mx-1" style={{color: 'secondary.contrastText'}}>{item?.text}</span>
                            </div>
                        </MenuItem>)
                    ), [menuItems])}
                </Menu>
            </div>
        </div>
    );
}

export default DeleteControlTool;