import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {ROUTE_HOME} from "../../Constants/Routes";

const RedirectPage = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(ROUTE_HOME);
    }, []);

    return (
        <div>
        </div>
    );
}

export default RedirectPage;