
export class ChatHistory {
    /** @type {string} */
    type = "";
    /** @type {string[]} */
    chatUserIds = [];
    /** @type {string} */
    ownerUserId = "";

    /**
     *
     * @param {string} type
     * @param {string[]} chatUserIds
     * @param {string} ownerUserId
     */
    constructor(type, chatUserIds, ownerUserId) {
        this.type = type;
        this.chatUserIds = chatUserIds;
        this.ownerUserId = ownerUserId;
    }
}