

const SOMETHING_WENT_WRONG = 'Что-то пошло не так!';
const SUCH_USER_EXISTS = "Такой пользователь уже есть!";
const MSG_YOU = "Вы";
const LABEL_ON = "Вкл.";
const LABEL_OFF = "Выкл.";
const LABEL_ACTIVE = "Активна";
const LABEL_NOT_ACTIVE = "Не активна";
const LABEL_WAS_MAN = "Был";
const LABEL_WAS_WOMAN = "Была";
const LABEL_ENTER_TEXT = "Введите название города!";
const LABEL_SEARCH_FOR_LOCATION = "Поиск по: ";
const LABEL_YOUR_LOCATION = "Местоположение: ";
const LABEL_RUB = "руб.";
const LABEL_DAYS = "дн."
const LABEL_ONLINE = "На связи";
const LABEL_ERROR = "Ошибка!";
const LABEL_FROM = "от";
const LABEL_TO = "до";
const LABEL_CLOSE = "Закрыть";
const LABEL_ALL_MESSAGES = "Все сообщения";

const ADS_INIT_SEARCH = 'ads_init_search';

export const LABEL_ADS = "Реклама";

const LABEL_SEARCH_FOR_NAME = "Поиск по имени";

const CAPTION_CHATS = "Чаты";
const CAPTION_GUESTS = "Гости";
const CAPTION_EMPTY_CHAT = "Здесь пока нет чатов";
const CAPTION_SEARCH = "Анкеты";
const CAPTION_ADS = "Ночной чат";
const CAPTION_FIND = "Найти";
const CAPTION_EMPTY_PROFILES = "Здесь пока нет людей.";
const CAPTION_EMPTY_GUESTS = "У вас пока нет гостей.";
const CAPTION_EMPTY_OPTIONS = "Платежный сервис пока недоступен";
const CAPTION_SAVE = "Сохранить";
const CAPTION_DELETE = "Удалить";
const CAPTION_BACK = "Назад";
const CAPTION_REGISTRATION = "Регистрация";
const CAPTION_FORGET_PASSWORD = 'Забыл пароль';
const CAPTION_SIGN = "Войти";
const CAPTION_LOG_OUT = "Выйти";
const CAPTION_SIGN_IN = "Вход";
const CAPTION_SEND = "Отправить";
const CAPTION_UP = "Поднять";
const CAPTION_FIRST_NAME = 'Имя';
const CAPTION_SECOND_NAME = 'Фамилия';
const CAPTION_ONLINE = 'Онлайн';
const CAPTION_FIRST_NAME_HINT = "Введите имя для поиска";
const CAPTION_SECOND_NAME_HINT = "Введите фамилию для поиска";
const CAPTION_RAISING = "Поднятие";
const CAPTION_CITY = "Город/населенный пункт";
const CAPTION_CITY_HINT = "Название города";
const CAPTION_NEXT = "Далее";
const CAPTION_SELECT = "Выделить чат для удаления";
const CAPTION_LOADING = "Загрузка...";
const CAPTION_ADD = "Подключить";
const CAPTION_DESCRIBE_PROBLEM = "Здесь опишите проблему или пожелание.";
const CAPTION_USER_AD_TEXT = "Опишите здесь ваше объявление";
const CAPTION_USER_AD_TITLE = "Текст сообщения";
const CAPTION_PLACE_LOCATION = "Где разместить?";

const CAPTION_YOUR_ADS = "Ваши объявления";

const CAPTION_NEVER_MIND = "неважно";

const CAPTION_YOUR_LOGIN = "Введите логин";
const CAPTION_YOUR_PASSWORD = "Введите пароль";
const CAPTION_CONFIRM_YOUR_PASSWORD = "Подтвердите пароль";
const CAPTION_YOUR_EMAIL = "name@example.ru";
const CAPTION_REPEAT_TEXT_CAPTCHA = 'Повторите текст выше';
const CAPTION_CODE_FROM_EMAIL = 'Код из письма';

const MSG_INVALID_EMAIL_FORMAT = "Формат такой: name@domain.com";
const MSG_INVALID_LOGIN_FORMAT = "Формат логина такой: name@domain.com";
const MSG_USER_NOT_FOUND = "Такой пользователь не найден!";
const MSG_CHECK_YOUR_EMAIL = "Вам отправлено письмо для сброса пароля. Проверьте ваш почтовый ящик.";
const MSG_EMPTY_PASSWORD = "Пароль не должен быть пустым.";
const MSG_NOT_EQUALS_PASSWORD = "Пароли не совпадают!";
const MSG_INVALID_CREDENTIALS = "Неверный логин или пароль.";
const MSG_USER_ALREADY_EXISTS = "Такой пользователь уже есть: ";
const MSG_WISH_DELETE_SELECTED_CHATS = "Удалить выделенные чаты безвозвратно?";

const MSG_DESCRIBE_AD = 'Опишите здесь ваше объявление в свободной форме. Ограничение 800 символов, включая пробелы. Ответить на ваше объявление могут зарегистрированные пользователи, а увидеть любой посетитель сайта.';

const MSG_UPLOAD_FILES_NO_MORE = "Допустимый размер изображения не более ";
const MSG_FILE_TOO_BIG = "Ваш файл превысил допустимый размер";

const MSG_CANT_REMOVE_CHAT_ITEM = "Не могу удалить данный чат/чаты!";
const MSG_CANT_REMOVE_CHAT_MESSAGE = "Не могу удалить данное сообщение!";
const MSG_CANT_EDIT_CHAT_MESSAGE = "Не могу изменить сообщение!";
const MSG_PARAMS_SAVED = "Настройки сохранены.";
const MSG_DELETE_ACCOUNT_CONFIRM = "Удалить ваш аккаунт полностью?";
const MSG_MESSAGE_SEND = "Сообщение отправлено!";

const MSG_REMOVE_ALL_USER_ADS = "Удалить все объявления?";
const MSG_REMOVE_USER_AD_FROM = "Удалить объявление от ";
const MSG_USER_AD_REMOVED = "Объявление удалено!";
const MSG_CANT_REMOVE_USER_AD = "Не могу удалить объявление";
const MSG_CANT_CREATE_USER_AD = "Не могу создать объявление!";

const ERR_CHAT_SERVICE = "Ошибка отправки!";

const LABEL_USER_NOTIFICATIONS_OFF = "Уведомления чата выключены";
const LABEL_USER_NOTIFICATIONS_ON = "Уведомления чата включены";

const SUBTITLE_MY_PHOTOS = "Мои фото";
const SUBTITLE_ABOUT_ME = "О себе";
const SUBTITLE_SEX_ORIENTATION = "Ориентация";
const SUBTITLE_HOBBIES = "Интересы";
const SUBTITLE_WHOM_LOOKING_FOR = "Кого ищу";
const SUBTITLE_SEX = "Пол";
const SUBTITLE_CHILDS = "Дети";
const SUBTITLE_FAMILY_STATUS = "Семейное положение";
const SUBTITLE_FAMILY_STATUS_SHORT = "Семья";
const SUBTITLE_YEARS_OLD = "Возраст";
const SUBTITLE_SELECT_SEX = "Укажите ваш пол";
const SUBTITLE_YOUR_LOGIN = "Укажите ваш email";
const SUBTITLE_YOUR_PASSWORD = "Укажите ваш пароль";
const SUBTITLE_YOUR_NAME = "Как вас зовут?";
const SUBTITLE_YOUR_BIRTHDAY = "Ваш день рождения";
const SUBTITLE_MEETING_FOR = "Кого ищите?";
const SUBTITLE_RESET_PASSWORD = "Сменить пароль";
const SUBTITLE_YOUR_EMAIL = "Ваш почтовый ящик при регистрации";
const SUBTITLE_MY_PROFILE = "Мой профиль";
const SUBTITLE_SPEC_OPTIONS = "Дополнительный фунционал";
const SUBTITLE_DELETE_PROFILE = "Удаление профиля";
const SUBTITLE_CAPTCHA = "Проверочная строка";
const SUBTITLE_CONFIRM_CODE = "Проверочный код";
const SUBTITLE_REQUEST_CONFIRM_CODE = "Запросить проверочный код";
const SUBTITLE_REQUEST_NEW_CODE = "Следующая попытка (сек): ";
const SUBTITLE_HEIGHT = "Рост";
const SUBTITLE_WEIGHT = "Вес";
const SUBTITLE_BODY = "Телосложение";
const SUBTITLE_BRIEFLY_ABOUT_YOURSELF = "Коротко о себе";
const SUBTITLE_HOBBIES_AND_OTHER = "Интересы и увлечения";
const SUBTITLE_PAID_OPTIONS = "Дополнительные опции";
const SUBTITLE_COMPLAINS_ON = "Жалобы на вас";
const SUBTITLE_COMPLAIN = "Жалоба";
const SUBTITLE_COMPLAIN_TEXT = "Что случилось?";
const SUBTITLE_USER_QUESTION = "Вопрос техподдержке";
const SUBTITLE_USER_QUESTION_TEXT = "Опишите вашу проблему или пожелание";
const SUBTITLE_EMAIL_MSG_NOTIFY = "Продублировать на почту";
const SUBTITLE_CREATE_USER_AD = "Новое сообщение";
const SUBTITLE_EDIT_USER_AD = "Редактировать сообщение";

const DESC_DELETE_PROFILE = "Удалить ваш аккаунт полностью. Все ваши настройки и личные данные будут потеряны.";
const DESC_SWITCH_EMAIL_MSG_NOTIFY = "Отключить или включить уведомления по email о новых сообщениях, если вас нет на сайте.";

const CAPTION_SEARCH_PARAMS = "Параметры поиска";

const EMPTY_TEXT_PROFILE_FIELD = "Не указанно";

const CAPTION_WRITE = "Написать";
const CAPTION_COMPLAIN = "Пожаловаться";
const CAPTION_CHARS_OF = "Символов: ";

const PASS_PHRASE = "48jdDnsme3Qldkrrtiin";

const SEX_DATA = [
    {tag: 'MAN', value: 'Мужчина'},
    {tag: 'WOMAN', value: 'Женщина'},
];

const META_TITLE_SIGN = "Вход в Чат знакомств Strelka (Стрелка)";
const META_DESCRIPTIONS_SIGN = "Чат знакомств с мужчинами и девушками в режиме онлайн без ботов и фейковых страниц. Вам открыты все анкеты и ваши гости. ";
const META_KEYWORDS_SIGN = "strelka вход,стрелка вход на сайт,знакомства strelka,вход на сайт стрелка";

const META_TITLE_REG = "Регистрация в чате знакомств Strelka (Стрелка)";
const META_DESCRIPTIONS_REG = "Регистрация в чате знакомств Strelka для мужчин и женщин бесплатно.";
const META_KEYWORDS_REG = "strelka регистрация,стрелка регистрация,зарегистрироваться в стрелке,знакомства в чате";

export const SEO_SITE_URL = "https://strelkachat.ru/";
export const SEO_URL_DESCRIPTION = "Знакомства в вашем городе";

export const SHARE_MESSAGE = "Вероятно в этом городе о нас ещё не знают! Поделитесь со знакомыми нашим ресурсом. Спасибо!";

const FAMILY_STATUS_DATA = {
    man: [
        {tag: 'SINGLE', value: 'Холост'},
        {tag: 'MARRIED', value: 'Женат'},
        {tag: 'SKIPED', value: 'В разводе'},
    ],
    woman: [
        {tag: 'SINGLE', value: 'Не замужем'},
        {tag: 'MARRIED', value: 'Замужем'},
        {tag: 'SKIPED', value: 'В разводе'},
    ]
};

const ADS_RULES_HEADER = "Правила пользования";
const ADS_RULES_WISH = "Удачных вам знакомств!";

const AD_RULES = [
    {num: 1, text: "Данные объявления предназначены для знакомств. Опубликовать здесь объявление может только зарегистрированный пользователь, но видеть будут все посетители главной страницы сайта."},
    {num: 2, text: "Старайтесь не употреблять бранных или грубых слов, а также рекламы товаров или услуг, в противном случае ваше объявление будет удалено, а при частом нарушении будет удалена ваша учетная запись."},
    {num: 3, text: "Размещать можно не более 21 объявлений в неделю."},
    {num: 4, text: "Объявления для быстрых знакомств будет видно на главной странице сайта в разделе объявлений о знакомстве. Здесь вы будете видеть только свои созданные объявления."},
    {num: 5, text: "Размещение объявлений доступно только после активации опции управления чатами. Пока действует опция, вы можете публиковаться. После окончания срока действия платной опции объявления остаются, их можно будет удалять или редактировать."},
    {num: 6, text: "Опубликованное объявление удалится автоматически через 30 дней после публикации."},
];

const KIDS_DATA = [
    {tag: 0, value: 'Детей нет'},
    {tag: 1, value: 'Один ребенок'},
    {tag: 2, value: 'Двое'},
    {tag: 3, value: 'Трое'},
    {tag: 4, value: 'Больше трех'},
];

const LOCATION_TYPES = [
    {label: 'Город/Поселок', value: 'locality'},
    {label: 'Регион', value: 'region'},
]

const COMPLAINS = [
    {value: "coarseness".toUpperCase(), caption: "Грубость"},
    {value: "ageism".toUpperCase(), caption: "Оскорбления за возраст"},
    {value: "ADVERTISING", caption: "Реклама услуг или товаров"},
    {value: "DRUGS", caption: "Реклама наркотиков"},
    {value: "other".toUpperCase(), caption: "Другое"},
];

const SUPPORTS = [
    {value: "support".toUpperCase(), caption: "Проблемы в использовании ресурса"},
    {value: "wishes".toUpperCase(), caption: "Пожелания и предложения"},
];

const supportForms = {
    complain: {radioData: COMPLAINS, title: SUBTITLE_COMPLAIN, subTitle: SUBTITLE_COMPLAIN_TEXT, url: ''},
    support: {radioData: SUPPORTS, title: SUBTITLE_USER_QUESTION, subTitle: SUBTITLE_USER_QUESTION_TEXT, url: ''}
}

/**
 *
 * @param {string} value
 * @returns {number}
 */
function kidsVal(value) {
    if (value === KIDS_DATA[0].tag) {
        return 1;
    } else if (value === KIDS_DATA[1].tag) {
        return 0;
    }
    return -1;
}

/**
 *
 * @param {number} value
 * @returns {string}
 */
function kidsValReverse(value) {
    return value === 1 ? KIDS_DATA[0].tag : KIDS_DATA[1].tag;
}

const ONLINE_DATA = [
    {tag: true, value: 'Онлайн'},
    {tag: false, value: 'Не на связи'},
];

//  HETERO, HOMO, BI
const SEX_ORIENTATION_DATA = [
    {tag: 'HETERO', value: 'Гетеро'},
    {tag: 'HOMO', value: 'Гомо'},
    {tag: 'BI', value: 'Би'},
];

const MEET_PREFERENCES_DATA = [
    {tag: 'WOMAN', value: 'Женщин/девушек'},
    {tag: 'MAN', value: 'Мужчин/парней'},
    {tag: 'ALL', value: 'Ищу друзей'},
];


/**
 * Уведомление при не 200 статусе
 * @param stat Number
 * @returns {string}
 */
export function getNotificationMsg(stat) {
    if (+stat === 403) {
        return `Неверный логин или пароль. Код ${+stat}`;
    } else if (+stat !== 200) {
        return `Что-то пошло не так!`;
    }
}

export {
    SOMETHING_WENT_WRONG,
    SUCH_USER_EXISTS,
    MSG_YOU,
    CAPTION_CHATS,
    CAPTION_GUESTS,
    CAPTION_EMPTY_CHAT,
    CAPTION_SEARCH,
    CAPTION_EMPTY_PROFILES,
    CAPTION_EMPTY_GUESTS,
    SUBTITLE_ABOUT_ME,
    SUBTITLE_MY_PHOTOS,
    SUBTITLE_SEX_ORIENTATION,
    SUBTITLE_WHOM_LOOKING_FOR,
    SUBTITLE_SEX,
    SUBTITLE_HOBBIES,
    CAPTION_WRITE,
    CAPTION_COMPLAIN,
    SUBTITLE_CHILDS,
    SUBTITLE_FAMILY_STATUS,
    CAPTION_SAVE,
    FAMILY_STATUS_DATA,
    MEET_PREFERENCES_DATA,
    SEX_DATA,
    SEX_ORIENTATION_DATA,
    KIDS_DATA,
    EMPTY_TEXT_PROFILE_FIELD,
    CAPTION_SEARCH_PARAMS,
    SUBTITLE_FAMILY_STATUS_SHORT,
    SUBTITLE_YEARS_OLD,
    CAPTION_BACK,
    kidsVal,
    kidsValReverse,
    CAPTION_FORGET_PASSWORD,
    CAPTION_REGISTRATION,
    SUBTITLE_SELECT_SEX,
    CAPTION_SIGN,
    CAPTION_SIGN_IN,
    CAPTION_YOUR_LOGIN,
    CAPTION_YOUR_PASSWORD,
    CAPTION_CONFIRM_YOUR_PASSWORD,
    SUBTITLE_YOUR_LOGIN,
    SUBTITLE_YOUR_PASSWORD,
    SUBTITLE_MEETING_FOR,
    SUBTITLE_YOUR_BIRTHDAY,
    SUBTITLE_YOUR_NAME,
    SUBTITLE_RESET_PASSWORD,
    SUBTITLE_YOUR_EMAIL,
    CAPTION_YOUR_EMAIL,
    CAPTION_SEND,
    SUBTITLE_MY_PROFILE,
    CAPTION_LOG_OUT,
    SUBTITLE_DELETE_PROFILE,
    CAPTION_DELETE,
    DESC_DELETE_PROFILE,
    SUBTITLE_SPEC_OPTIONS,
    CAPTION_UP,
    LABEL_OFF,
    LABEL_ON,
    LABEL_ACTIVE,
    LABEL_NOT_ACTIVE,
    CAPTION_REPEAT_TEXT_CAPTCHA,
    SUBTITLE_CAPTCHA,
    MSG_INVALID_EMAIL_FORMAT,
    MSG_USER_NOT_FOUND,
    MSG_CHECK_YOUR_EMAIL,
    MSG_EMPTY_PASSWORD,
    MSG_INVALID_LOGIN_FORMAT,
    MSG_NOT_EQUALS_PASSWORD,
    MSG_INVALID_CREDENTIALS,
    MSG_USER_ALREADY_EXISTS,
    CAPTION_FIRST_NAME,
    CAPTION_ONLINE,
    CAPTION_SECOND_NAME,
    CAPTION_FIRST_NAME_HINT,
    CAPTION_SECOND_NAME_HINT,
    LABEL_WAS_MAN,
    LABEL_WAS_WOMAN,
    SUBTITLE_HEIGHT,
    SUBTITLE_WEIGHT,
    ONLINE_DATA,
    CAPTION_FIND,
    SUBTITLE_BODY,
    SUBTITLE_BRIEFLY_ABOUT_YOURSELF,
    SUBTITLE_HOBBIES_AND_OTHER,
    CAPTION_RAISING,
    MSG_CANT_REMOVE_CHAT_ITEM,
    MSG_CANT_REMOVE_CHAT_MESSAGE,
    MSG_CANT_EDIT_CHAT_MESSAGE,
    CAPTION_CITY,
    CAPTION_CITY_HINT,
    CAPTION_NEXT,
    LABEL_ENTER_TEXT,
    MSG_PARAMS_SAVED,
    MSG_DELETE_ACCOUNT_CONFIRM,
    LABEL_YOUR_LOCATION,
    CAPTION_NEVER_MIND,
    CAPTION_SELECT,
    MSG_WISH_DELETE_SELECTED_CHATS,
    CAPTION_LOADING,
    SUBTITLE_PAID_OPTIONS,
    CAPTION_EMPTY_OPTIONS,
    CAPTION_ADD,
    LABEL_RUB,
    LABEL_DAYS,
    LABEL_ONLINE,
    CAPTION_CHARS_OF,
    SUBTITLE_COMPLAIN,
    COMPLAINS,
    SUPPORTS,
    SUBTITLE_USER_QUESTION,
    supportForms,
    CAPTION_DESCRIBE_PROBLEM,
    LABEL_ERROR,
    MSG_FILE_TOO_BIG,
    MSG_UPLOAD_FILES_NO_MORE,
    SUBTITLE_USER_QUESTION_TEXT,
    SUBTITLE_COMPLAIN_TEXT,
    DESC_SWITCH_EMAIL_MSG_NOTIFY,
    SUBTITLE_EMAIL_MSG_NOTIFY,
    LABEL_FROM,
    LABEL_TO,
    MSG_MESSAGE_SEND,
    LABEL_CLOSE,
    META_DESCRIPTIONS_REG,
    META_DESCRIPTIONS_SIGN,
    META_KEYWORDS_REG,
    META_KEYWORDS_SIGN,
    META_TITLE_REG,
    META_TITLE_SIGN,
    ERR_CHAT_SERVICE,
    SUBTITLE_COMPLAINS_ON,
    SUBTITLE_CONFIRM_CODE,
    CAPTION_CODE_FROM_EMAIL,
    SUBTITLE_REQUEST_CONFIRM_CODE,
    SUBTITLE_REQUEST_NEW_CODE,
    PASS_PHRASE,
    LOCATION_TYPES,
    LABEL_SEARCH_FOR_LOCATION,
    LABEL_USER_NOTIFICATIONS_OFF,
    LABEL_USER_NOTIFICATIONS_ON,
    LABEL_SEARCH_FOR_NAME,
    ADS_INIT_SEARCH,
    CAPTION_ADS,
    CAPTION_YOUR_ADS,
    AD_RULES,
    ADS_RULES_HEADER,
    ADS_RULES_WISH,
    MSG_DESCRIBE_AD,
    MSG_REMOVE_USER_AD_FROM,
    MSG_CANT_REMOVE_USER_AD,
    MSG_USER_AD_REMOVED,
    SUBTITLE_CREATE_USER_AD,
    SUBTITLE_EDIT_USER_AD,
    CAPTION_USER_AD_TEXT,
    CAPTION_USER_AD_TITLE,
    CAPTION_PLACE_LOCATION,
    MSG_REMOVE_ALL_USER_ADS,
    LABEL_ALL_MESSAGES,
}