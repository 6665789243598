import {faker} from "@faker-js/faker";
import {ChatItem} from "../api/Websocker/dto/ChatItem";
import uniqueBy from "@popperjs/core/lib/utils/uniqueBy";
import {PROFILE_CHATS_PAGE_SIZE} from "../api/ApiCommon";

export const maxImgFileSize = 1.5; //Мб

/**
 * Сформировать массив тестовых данных
 * @param {number} size
 */
export function getFakeContent(size) {
    return Array(size || 1000).fill(1).map(() => (
        {
            name: faker.name.firstName(),
            phone: faker.phone.number(),
            avatar: faker.internet.avatar(),
        }
    ));
}

/**
 *
 * @param {any[]} arr1
 * @param {any[]} arr2
 * @returns {boolean}
 */
export function arrayEquals(arr1, arr2) {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
}

/**
 * Конвертировать массив с данными в Map, с группировкой данных по
 * строкам и столбцам, при наличии числа столбцов cols
 * @param {object[]} content
 * @param {number} cols
 * @returns {Map<number, Object>}
 */
export function convertMap(content, cols) {
    let map = new Map();
    let row = 0;
    let index = 0;
    while (index < (content?.length || 0)) {
        let payload = [];
        for (let col = 0; col < cols; col++) {
            payload.push({row: row, col: col, value: content[index]});
            index++;
        }
        map.set(row, payload);
        row++;
    }

    return map;
}


/**
 * Сложение двух массивов и получвения финального массива с неповторяющимися элементами
 * @param {any[]} a1
 * @param {any[]} a2
 * @returns {*}
 */
export function concatUnique(a1, a2) {
    let c = a1?.concat(a2);
    return c?.filter((item, pos) => c.indexOf(item) === pos);
}


//TODO - алгоритм требует доработки (пошагово удаляет последний эелемент списка при каждом обновлении)
export function findAndReplaceChats(state, action) {
    let userChats = uniqueBy(state.chats.concat(action.payload), (item) => item?.userProfile?.id);
    let payloadIndex = (state.page-1)*PROFILE_CHATS_PAGE_SIZE;
    let replaceElementsMap = new Map();

    for (let userChat of action.payload) {
        userChats = userChats.map((item, index) => {
            if (item?.userProfile?.id === userChat?.userProfile?.id) {

                if (index !== payloadIndex) {
                    replaceElementsMap.set(index, {sourceIndex: index, payloadIndex: payloadIndex, element: userChat})
                }

                if (userChat?.chatItems?.length !== item?.chatItems?.length) {
                    return item = userChat;
                }

                return item;
            }
            return item;
        })

        payloadIndex++;
    }

    replaceElementsMap.forEach((v, k, map) => {
        userChats.splice(k, 1);
    });

    let replacedElements = Array.from(replaceElementsMap.values()).map(item => (item?.element));

    return uniqueBy(replacedElements.concat(userChats), (item) => item?.userProfile?.id);
}


export const companionUnReadMessages = (currentUserId, allMessages) => {
    return allMessages.filter(item => ((item?.fromUserId !== currentUserId) && (!item?.read)));
}

export const updateMessageReadStatus = (currentUserId, selectedUserId, allMessages, wsClient) => {
    //Непрочитанные сообщения собеседника
    let companionMessages = companionUnReadMessages(currentUserId, allMessages);

    if ((!companionMessages) || (companionMessages?.length === 0)) return;

    //Изменить статус сообщений собеседника на прочитанный
    companionMessages = companionMessages.map(chatItem => {
        return new ChatItem(
            chatItem?.id,
            chatItem?.userId,
            chatItem?.fromUserId,
            chatItem?.message,
            chatItem?.group,
            chatItem?.emailed,
            true,
            chatItem?.timestamp);
    });

    if (wsClient) {
       // console.log("companionMessages: "+JSON.stringify(companionMessages))
        //Отправить измененные сообщения
        wsClient?.editChatMessages(companionMessages, currentUserId, selectedUserId);
    }
}


/**
 *
 * @param {Object[]} messages
 * @param {Object[]} chatList
 */
export function findAndUpdateReadChatMessages(messages, chatList) {
    if ((messages?.length === 0) || (chatList?.length === 0)) {
        return chatList;
    }
    //Пользователь, чьи сообщения были прочитанны
    let searchUserId = messages[0]?.fromUserId;
    //Чат пользователя, чьи сообщения были прочитаны
    let chatProfile = chatList?.filter(item => item?.userProfile?.id === searchUserId);

    if (chatProfile?.length === 0) {
        return chatList;
    }

    chatProfile = chatProfile[0];

    /*Найти и удалить прочитанные сообщения из chatItems истории*/
    messages?.forEach(msgItem => {
        let pos = chatProfile?.chatItems?.findIndex((elem) => {
            return elem?.id === msgItem?.id;
        });
        if (pos >= 0) {
             chatProfile?.chatItems.splice(pos, 1);
        }
    });

    return chatList?.map(item => {
        if (item?.userProfile?.id === chatProfile?.userProfile?.id) {
            return chatProfile;
        }
        return item;
    });
}




/**
 * Добавить непрочитанное сообщение в элемент списка историй чатов или историю с самим сообщением.
 * @param chats
 * @param {ChatItem} body
 * @returns {*}
 */
export const findProfileChatAndAddChatItem = (chats, body) => {
    let currentUserId = body?.currentUserId;
    let content = body?.chatMessage?.content;
    let fromUserProfile = body?.chatMessage?.fromUser;

    if (!content) return chats;
    let added = false;
    let newChats = chats.map(chatItem => {
        if ((chatItem?.userProfile?.id === content?.fromUserId) && (!content?.read)) {
            if (content?.message) {
                chatItem?.chatItems.push(content);
            }
            added = true;
            return chatItem;
        }
        return chatItem;
    });

    if (added) return newChats;
    if ((!added) && (currentUserId !== fromUserProfile?.id)) {
        if (fromUserProfile) {
            newChats = [{chatItems: content?.message ? [content] : [], userProfile: fromUserProfile}].concat(...newChats);
        }
    }
    return newChats;
}

/**
 * Удалить сообщение со статусом прочтения из элемента истории чата
 * @param state
 * @param {ChatItem[]} chatMessages
 * @returns {*}
 */
export const findProfileChatAndSubChatItem = (state, chatMessages) => {
    if ((!chatMessages) || (chatMessages?.length === 0)) return state;
    let firstMessage = chatMessages[0];

        return state.map(chatItem => {
            /**Если поле сообщений пустое, то пропускается элемент*/
            if (chatItem?.chatItems?.length === 0) return chatItem;

            if (chatItem?.userProfile?.id === firstMessage?.fromUserId) {
                chatMessages.forEach(chatMessage => {
                    chatItem?.chatItems?.splice(chatItem?.chatItems.map(item => item?.id).indexOf(chatMessage?.id), 1);
                });
                return chatItem;
            }

            return chatItem;
        });
}

export const findAndReplaceMessage = (state, action) => {
    let upMessages = action.payload;
    if ((!upMessages) || (upMessages?.length === 0)) return;

    let newState = state;
    upMessages.forEach(upMsg => {
        newState = newState?.map(item => {
            if (upMsg?.id === item?.id) {
                return upMsg;
            }
            return item;
        });
    });
    return newState;
}

export function notInArray(source, sub) {
    let result = [];

    if (source?.length === 0) {
        return sub;
    }

    sub.forEach(item => {
        let pos = source?.findIndex((elem) => (elem?.id === item?.id));
        if (pos === -1) {
            result.push();
        }
    });
    return result;
}

/**
 * Вернуть опцию, у которой установлен платежный статус
 * @param {any[]} options
 */
export function extractPaymentStatusOption(options) {
    if (!options) return null;
    let option = options?.filter(item => ((item?.payStatus !== undefined) && (item?.payStatus?.length > 0)));
    return option ? option[0] : null;
}

export function locationValue(location) {
    if ((!location?.locality) && (location.region) && (location.country)) {
        return `${location.region} (${location.country})`;
    }
    if ((!location?.locality) && (!location.region) && (location.country)) {
        return `${location.country}`;
    }
    if ((!location?.locality) && (!location.region) && (!location.country)) {
        return "";
    }
    return `${location.locality}, ${location.region} (${location.country})`;
}

export function getFakeMessageBody(fromProfile, currentUserId) {
    let chatItem = {
        id: null,
        userId: currentUserId,
        fromUserId: fromProfile?.id,
        message: '',
        read: false,
        emailed: false,
    };

    return  {
        type: 'chat',
        content: chatItem,
        sender: fromProfile?.firstName,
        routerKey: "",
        dateTime: new Date().toISOString(),
        group: 0,
        fromUser: fromProfile
    }
}

export function getRandomDigitString() {
    return (Math.random() + 1).toString(36).substring(7);
}

/**
 * Сортировка сообщений по дате выхода
 * @param messages
 * @returns {*}
 */
export function sortChatMessagesByDateTime(messages) {
    if ((!messages) || (messages.length === 0)) return messages;
    return messages.sort((a, b) => ( new Date(a?.timestamp).getTime() - new Date(b?.timestamp).getTime() ) )
}

export const sortBy = (field, reverse, primer) => {

    const key = primer ?
        function(x) {
            return primer(x[field])
        } :
        function(x) {
            return x[field]
        };

    reverse = !reverse ? 1 : -1;

    return function(a, b) {
        return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
    }
}

export function capitalizeFirstLetter(val) {
    return val.charAt(0).toUpperCase() + val.slice(1);
}