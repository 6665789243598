import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {Badge, Button, IconButton} from "@mui/material";

import {getEnvOfStorage} from "../../Stores/Env";
import {selectCommon} from "../../Stores/slices/CommonSlice";
import {CAPTION_CHATS} from "../../Constants/TextMessagesRu";
import AvatarItem from "../../Components/Avatars/AvatarItem";
import {LeftChevronSvg} from "../../Svg";

let imgThumb = null;

function TopHeaderView ({onBack, navButtonSx})  {
    const {chatSelectedUser} = useSelector(selectCommon);
    const badgeColor = chatSelectedUser?.online ? 'success': 'default';


    useEffect(() => {
        imgThumb = chatSelectedUser?.thumbUrl?.src ? `${getEnvOfStorage()?.staticUrl}${chatSelectedUser?.thumbUrl?.src}` : "";
    }, [chatSelectedUser])

    return (
        <div>
            <div className="d-flex flex-row justify-content-between align-items-center bg-white rounded rounded border-1 card border-dark shadow">
                <div className="d-flex flex-row align-items-center p-0">
                    <IconButton onClick={onBack}
                            variant={"none"}
                            sx={{color: 'secondary.contrastText', display: navButtonSx}}
                            className="mx-1" >
                        <LeftChevronSvg w={18} h={18}/>{}
                    </IconButton>
                </div>
                <div className="d-flex flex-row align-content-center mx-1">
                    {chatSelectedUser?.firstName ?

                        <div className="d-flex flex-column justify-content-center">
                            <span className="m-0 p-0 fs-5_ fw-bold" >
                                {chatSelectedUser?.firstName} {chatSelectedUser?.lastName}, {chatSelectedUser?.age}
                            </span>
                        </div> :
                        <div>{}</div>}

                        <div className="border-dark d-flex p-1">
                            <div className="p-0 m-0 position-relative">
                                <AvatarItem alt={chatSelectedUser?.firstName}
                                            src={imgThumb}
                                            hasBorder={{border: 1}}/>
                                {chatSelectedUser?.online &&  <div className="rounded-circle badge-online card">{}</div>}
                            </div>
                       </div>

                </div>
            </div>
        </div>
    );
}


export default TopHeaderView;
