import {Container} from "@mui/material";
import StandardHeader from "../Components/Header/StandardHeader";
import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {ROUTE_HOME} from "../Constants/Routes";
import ComplainsContent from "../Components/Complains/ComplainsContent";
import Loader from "../Components/Loader/Loader";
import {useDispatch, useSelector} from "react-redux";
import {selectComplains, setComplainLoading, setComplains} from "../Stores/slices/ComplainsSlice";
import {getComplains} from "../api/ChatDataApi";
import {USER_ID_KEY} from "../api/ApiCommon";
import {getStoredValueByKey} from "../Stores/Env";
import {LABEL_ADS} from "../Constants/TextMessagesRu";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


const ComplainsPage = () => {
    const navigate = useNavigate();
    const query = useQuery();
    const dispatcher = useDispatch();
    const {complains, complainLoading} = useSelector(selectComplains)
    const currentUserId = !(query.get(USER_ID_KEY)) ? getStoredValueByKey(USER_ID_KEY) : query.get(USER_ID_KEY);

    function onBack() {
        navigate(ROUTE_HOME);
    }

    useEffect(() => {
        if (complains?.length === 0) {
            dispatcher(setComplainLoading(true))
            getComplains(currentUserId, 0, 10)
                .then(res => {
                    dispatcher(setComplains(res?.data))
                })
                .catch(e => {
                    console.error(e);
                })
                .finally(() => dispatcher(setComplainLoading(false)));
        }
    }, [])

    return (
        <div className="d-block flex-column m-2 scroll-none main-background">
            <Container className="my-profile my-profile-shadow d-flex flex-column">
                <StandardHeader onClick={onBack} hideOptions={true}/>
                {complainLoading &&
                <div className="d-flex flex-grow-1 flex-row justify-content-center align-items-center">
                    <Loader height={40}/>
                </div>}
                {!complainLoading && <ComplainsContent complains={complains} userId={currentUserId}/>}
            </Container>
        </div>
    );
}

export default ComplainsPage;