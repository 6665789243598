import {createSlice} from "@reduxjs/toolkit";


export const toastSlice = createSlice({
    name: 'alertSnack',
    initialState: {
       alert: {
           open: false,
           severity: 'success',
           text: '',
       },
    },
    reducers: {
        dropAlertToast: (state) => {
            state.alert = {
                open: false,
                severity: 'success',
                text: '',
            };
        },
        setAlertToast: (state, action) => {
            if (action.payload?.text) {
                state.alert = action.payload;
            }
        }
    },
})

export const selectToast = (state) => state.alertSnack;
export const {dropAlertToast, setAlertToast} = toastSlice.actions;
export default toastSlice.reducer;