import {
    CAPTION_CITY,
    CAPTION_CITY_HINT, CAPTION_LOADING,
    CAPTION_NEVER_MIND,
    LABEL_ENTER_TEXT, LABEL_SEARCH_FOR_LOCATION,
    LABEL_YOUR_LOCATION, LOCATION_TYPES
} from "../../Constants/TextMessagesRu";
import {
    Autocomplete,
    CircularProgress,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {getCities} from "../../api/AuthApi";
import IconSubTitle from "../Header/IconSubTitle";
import {capitalizeFirstLetter, locationValue} from "../../services/DataService";
import GeoSvg from "../../Svg/GeoSvg";

const AutocompleteCityList = ({onLocationSelected, defaultLocation, width, showCaption, showIcon, showSelector, defLocationType}) => {
    const [location, setLocation] = useState("");
    const [fullLocation, setFullLocation] = useState(null)
    const [options, setOptions] = useState([]);
    const [selected,] = useState(null);
    const [loading, setLoading] = useState(false);
    const [locationType, setLocationType] = useState(defLocationType);

    function searchCity(name, locType) {
        getCities(name, locType)
            .then(res => {
                let items = res?.data.map(item => {
                    return {locality: item?.name, region: item?.region?.name, country: item?.region?.country?.name}
                });
                setOptions(items)
            }).catch(err => console.warn(err))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        let timeOutId = null;
        timeOutId = setTimeout(() => {
            searchCity(location, locationType);
        }, 1000);

        return () => clearTimeout(timeOutId);
    }, [location]);

    function onChange(value, locationType) {
        setFullLocation({locality: value?.locality, region: value?.region, country: value?.country, locationType: locationType});
        setLoading(false);
    }

    useEffect(() => {
        let loc = fullLocation ?? defaultLocation;
        onLocationSelected({locality: loc?.locality, region: loc?.region, country: loc?.country, locationType: locationType});
    }, [fullLocation, locationType]);

    function onKeyDown(e) {
        if (e.target.value) {
            setLoading(true);
            setLocation(capitalizeFirstLetter(e?.target?.value));
        }
        if (e.target?.value?.length === 0) {
            setLoading(false);
        }
     }

    function yourLocation(defaultValue) {
        let result = "";
        if (!selected) {
            result = locationValue(defaultValue);
            return result ? result : CAPTION_NEVER_MIND;
        }
        result = locationValue(selected);
        return  result ? result : CAPTION_NEVER_MIND;
    }

    function locationTypeChanged(e) {
        setLocationType(e.target.value)
        setOptions([]);
    }

    return (
        <div className="mx-1 d-flex flex-column justify-content-center">
            <div className="d-flex flex-row align-content-center my-1">
                <div className="d-flex flex-column justify-content-center">
                    {showCaption &&
                    <div className="d-flex flex-row align-items-center mt-2 h5 flex-wrap">
                        <span className="text-black">{LABEL_YOUR_LOCATION}</span>
                        <span className="mx-1 flex-wrap text-success flex-wrap">{yourLocation(defaultLocation)}</span>
                    </div>}
                    <div className="d-flex flex-row align-content-center">
                        {showIcon &&
                        <div className="d-flex flex-column justify-content-end"><IconSubTitle text={""} icon={<GeoSvg/>}/></div>}
                        <div>
                            {showSelector &&
                            <div className="d-flex flex-row align-items-center mt-2 h5 flex-wrap">
                                <span className="text-black mr-2">{LABEL_SEARCH_FOR_LOCATION}</span>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={defLocationType}
                                    name="radio-buttons-group"
                                    row
                                    onChange={locationTypeChanged}
                                >
                                    {LOCATION_TYPES.map((item, index) => <div key={index}><FormControlLabel value={item?.value} control={<Radio />} label={item?.label} /></div>)}
                                </RadioGroup>
                            </div>}
                            <span className="d-flex align-items-center mt-1">
                                <label className="input-label mr-1">{CAPTION_CITY}</label>
                                {loading && <CircularProgress style={{height: 20, width: 20}}/>}
                            </span>
                            <Autocomplete
                                loading={loading}
                                loadingText={CAPTION_LOADING}
                                className="bg-white input_field"
                                options={options}
                                sx={{ width: width || 300 }}
                                blurOnSelect={false}
                                value={defaultLocation}
                                noOptionsText={LABEL_ENTER_TEXT}
                                isOptionEqualToValue={(option, value) => option.locality !== value.locality}
                                onChange={(event, value) => onChange(value, locationType)}
                                placeholder={CAPTION_CITY_HINT}
                                getOptionLabel={(option) => locationValue(option)}
                                onKeyDown={onKeyDown}
                                renderInput={(params) => (
                                    <TextField placeholder={LABEL_ENTER_TEXT} {...params} variant="standard" />
                                )}/>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default AutocompleteCityList;