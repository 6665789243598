import {createSlice} from "@reduxjs/toolkit";


export const complainsSlice = createSlice({
    name: 'complains',
    initialState: {
        complains: [],
        complainLoading: false,
    },
    reducers: {
        setComplains: (state, action) => {
            state.complains = action.payload;
        },
        dropComplains: state => {
            state.complains = [];
        },
        setComplainLoading: (state, action) => {
            state.complainLoading = action.payload;
        }
    },
});

export const selectComplains = (state) => state.complains;
export const {setComplains, dropComplains, setComplainLoading} = complainsSlice.actions;
export default complainsSlice.reducer;