import React from "react";
import AvatarItem from "../Avatars/AvatarItem";
import {ADMIN} from "../../../assets";
import {MSG_DESCRIBE_AD} from "../../Constants/TextMessagesRu";

const UserAdAdminItem = () => {
    return (
        <>
            <div className="d-none d-sm-none flex-column d-md-flex d-lg-flex d-xl-flex d-xxl-flex mx-0 px-1 py-0 card shadow">
                <div className="d-flex mt-1">
                    <div className="mx-2"><AvatarItem w={95} h={95} src={ADMIN}/></div>
                    <div className="flex-grow-1 d-flex flex-column">
                        <div className="d-flex flex-grow-1 card rounded p-1 border-3 text-wrap-div text-success">{MSG_DESCRIBE_AD}</div>
                    </div>
                </div>
                <div className="d-flex justify-content-start align-items-center mx-1 my-1">
                    <div className="text-wrap d-flex fw-bold">{`Администратор`}</div>
                </div>
            </div>
            <div className="d-sm-flex d-md-none d-lg-none d-xl-none d-xxl-none flex-column align-content-center  mx-0 px-1 py-0 card shadow">
                <div className="d-flex justify-content-between align-items-center flex-grow-0">
                    <div className="d-flex align-items-center justify-content-center my-1">
                        <div className="mx-2"><AvatarItem w={40} h={40} src={ADMIN}/></div>
                        <div className="text-wrap d-flex fw-bold">{`Администратор`}</div>
                    </div>
                </div>
                <div className="d-flex flex-grow-1 card rounded p-1 border-3 m-1 text-wrap-div text-success">{MSG_DESCRIBE_AD}</div>
            </div>
        </>
    );
}

export default UserAdAdminItem;