import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {
    LABEL_CLOSE,
} from "../../Constants/TextMessagesRu";

const ContentDialog = ({content, open, title, handleClose}) => {
    return (
        <>
            <Dialog
                maxWidth={"sm"}
                className="overflow-hidden"
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}>
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    <span className="fs-3 fw-bold">{title}</span>
                </DialogTitle>
                <DialogContent dividers  className="scroll-y">
                    {content}
                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"} className="text-danger border-danger m-1" onClick={handleClose}>{LABEL_CLOSE}</Button>
                </DialogActions>
            </Dialog>
        </>
    );

}

export default ContentDialog;