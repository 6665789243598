import {createSlice} from "@reduxjs/toolkit";
import { findAndReplaceMessage, sortChatMessagesByDateTime} from "../../services/DataService";


export const chatMessagesSlice = createSlice({
    name: 'chatMsgStatus',
    initialState: {
        messageBody: null,
        messageList: [],
        messageListBefore: [],
        allMessageList: [],
    },
    reducers: {
        setMessageBody: (state, action) => {
            state.messageBody = action.payload;
        },
        setBeforeMessageList: (state, action) => {
            state.messageList = sortChatMessagesByDateTime([...action.payload, ...state.messageList])
            state.messageListBefore = [...action.payload, ...state.messageList]
        },
        updateBeforeMessageList: (state, action) => {
            state.messageList = findAndReplaceMessage(state.messageList, action);
        },
        setMessageList: (state, action) => {
            state.messageList = sortChatMessagesByDateTime([...state.messageList, ...action.payload])
        },
        updateMessageList: (state, action) => {
            state.messageList = findAndReplaceMessage(state.messageList, action);
        },
        deleteMessageListItems: (state, action) => {
            let removableMessages = action.payload;
            if (!removableMessages) return;
            let filteredMsg = state.messageList;
            removableMessages?.forEach(rmItem => {
                filteredMsg = state.messageList.filter(item => rmItem?.id !== item?.id);
            });
            state.messageList = filteredMsg;
        },
        setAllMessageList: (state) => {
            state.allMessageList = state.messageList;
        },
        dropChatMessageList: state => {
            state.messageList = [];
            state.allMessageList = [];
            state.messageListBefore = [];
            state.messageBody = null;
        }
    },
});

export const selectChatMessages= (state) => state.chatMsgStatus;
export const {
    setMessageBody,
    setMessageList,
    setBeforeMessageList,
    setAllMessageList,
    dropChatMessageList,
    deleteMessageListItems,
    updateBeforeMessageList,
    updateMessageList,
} = chatMessagesSlice.actions;
export default chatMessagesSlice.reducer;